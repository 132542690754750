import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";

const CKEditorComponent = ({ editorData, handleEditorChange }) => {
  const onCKEditorChange = (e, editor) => {
    const newData = editor?.getData();
    handleEditorChange(newData);
  };

  return (
    <CKEditor
      editor={Editor}
      config={{
        toolbar: {
          items: [
            "lineheight", //!
            "heading",
            "|",
            "fontSize", //!
            "|",
            "indent",
            "|",
            "fontColor", //!
            "fontBackgroundColor", //!
            "imageInsert",
            "|",
            "bold",
            "italic",
            "underLine", //!
            "strikethrough", //!
            "subscript", //!
            "superscript", //!
            "|",
            "alignment", //!
            "|",
            "numberedList",
            "bulletedList",
            "|",
            "link",
            "insertTable",
            "|",
            "undo",
            "redo",
          ],
        },
      }}
      data={editorData}
      onChange={onCKEditorChange}
    />
  );
};

export default CKEditorComponent;
