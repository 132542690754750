import { createSlice } from "@reduxjs/toolkit";

export const CreateFormSlice = createSlice({
    name: "createform",
    initialState: {
        records: {

        },
        response: {

        },
        data: {

        },
    },
    reducers: {
        setRecords(state, data) {
            state.records = data.payload
        },
        setResponse(state, data) {
            state.response = data.payload
        },
        setData(state, data) {
            state.data = data.payload
        }
    }
})

export const { setRecords, setResponse, setData } = CreateFormSlice.actions

export default CreateFormSlice.reducer