import { Button, FormControl } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import { useSelector } from "react-redux";

function GenoADRA2A() {
    const AuthStateData = useSelector((state) => state.Signin);

    //local states
    const [penotype, setPenoType] = useState("N/A");
    const [showLoading, setShowLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [formData, setformData] = React.useState({});
    const [open1, setOpen1] = React.useState(true);
    let token = localStorage.getItem("code");

    let titles = ["0", "1", "2"];
    let values = ["rs11195419"];

    //events
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    //datasubmit event
    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setShowLoading(true);
            setOpen(true);
            let option = {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.access_token}`,
                }),
                body: JSON.stringify({
                    gene: "ADRA2A",
                    mutations: formData,
                }),
            };
            if (Object.keys(formData).length === 0) {
                setPenoType("Please enter all mutations");
            } else {
                let response = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/gene-mutations/phenotype-result`,
                    option
                );
                let resJson = await response.json();
                if (resJson.success === true) {
                    setPenoType(resJson.data.genotype);
                    setOpen1(true);
                    if (!resJson.data) {
                        alert("please enter valid values");
                        setPenoType("N/A");
                    }
                } else if (resJson.statusCode === 400) {
                    alert("Please enter all details");
                    setPenoType("N/A");
                } else {
                    alert("please give correct values");
                    setPenoType("N/A");
                }
            }
        } catch (err) {
            setPenoType("N/A");
        } finally {
            setShowLoading(false);
            setOpen(false);
        }
    };

    //render UI
    return (
        <>
            <form>
                <h5>ADRA2A</h5>
                <Grid
                    className="block-inner"
                    container
                    spacing={2}
                    sx={{ marginLeft: "7%" }}
                >
                    {values &&
                        values.map((title, index) => (
                            <Grid className="form-block" item xs={4} key={index}>
                                <FormControl className="form-inner">
                                    <span style={{ fontSize: "15px", marginBottom: 10 }}>
                                        {title}
                                    </span>

                                    <Grid item xs={4}>
                                        <Autocomplete
                                            id={index}
                                            size="small"
                                            options={titles}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="select"
                                                    sx={{ width: 120 }}
                                                />
                                            )}
                                            onChange={(e) => {
                                                if (e.target.innerText !== undefined) {
                                                    formData[title] = e.target.innerText;
                                                    setformData(formData);
                                                }
                                            }}
                                        />
                                    </Grid>
                                </FormControl>
                            </Grid>
                        ))}
                </Grid>
                <Box sx={{ width: "40%", margin: "auto", marginTop: "15px" }}>
                    <Collapse in={open1}>
                        <Alert
                            severity="info"
                            sx={{ mb: 6, backgroundColor: "aliceblue", textAlign: "center" }}
                        >
                            {"Phenotype: " + penotype}
                        </Alert>
                    </Collapse>
                    <Button
                        variant="contained"
                        type="button"
                        onClick={handleSubmit}
                        sx={{ marginLeft: "44%" }}
                    >
                        Submit
                    </Button>
                </Box>
            </form>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
export default GenoADRA2A;
