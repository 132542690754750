import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

const SummarySelectAutoComplete = ({ defaultValue, mainRowIndex, classesIndex, medicationIndex, onChangeSummaryValue }) => {

    const [summaryValue, setSummaryValue] = useState();

    const onSummaryValueChange = (_, newValue) => {
        setSummaryValue(newValue);
        if (newValue) {
            onChangeSummaryValue(newValue, mainRowIndex, classesIndex, medicationIndex);
        } else {
            onChangeSummaryValue("", mainRowIndex, classesIndex, medicationIndex);
        }
    };

    let options = [
        { title: "Standard", label: "Standard" },
        { title: "Toxicity", label: "Toxicity" },
        { title: "Lack of efficacy", label: "Lack of efficacy" },
        { title: "Reduced", label: "Reduced" },
        { title: "Increased", label: "Increased" }
    ]

    return (
        <Autocomplete
            className="defaultAutoComplete"
            sx={{
                width: "100%",
            }}
            value={defaultValue ? options?.find((item) => item.title == defaultValue) : null}
            disablePortal
            options={options?.length ? options : []}
            getOptionLabel={(option) => option.title}
            onChange={onSummaryValueChange}
            renderInput={(params) => (
                <TextField {...params} placeholder="Select" size="small" />
            )}
        />
    );
};
export default SummarySelectAutoComplete;
