import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../Core/LoadingComponent";
import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";
import EditPhysicalEndurance from "./EditPhysicalEndurance";

const PrivewOfPhysicalEndurance = () => {
    const AuthStateData = useSelector((state) => state.Signin);
    const userType = useSelector((state) => state.Signin.user_details?.user_type);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { report_id, result_file_id } = useParams();

    const [loading, setLoading] = useState("");
    const [physicalValue, setPhysicalValue] = useState("");
    const [physicalNote, setPhysicalNote] = useState(0);
    const [resultFile, setResultFile] = useState({});
    const [processSuccess, setProcessSuccess] = useState("");
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [preview, setPreview] = useState("");
    const [isOpenEditOrNot, setIsOpenEditOrNot] = useState(false);
    const [message, setMessage] = useState("");

    const handleLogout = async () => {
        dispatch({
            type: "SIGN_OUT",
            payload: {},
        });
        navigate("/");
    };

    const getPrimaryReportById = async () => {
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
            let options = {
                method: "GET",
                headers: new Headers({
                    authorization: `${AuthStateData?.access_token}`,
                }),
            };
            let response = await fetch(url, options);
            let resJson = await response.json();
            if (response.status == 200 || response.status == 201) {
                setResultFile(resJson.data ? resJson.data : {});
                setPhysicalNote(resJson.data?.physical_endurance_notes);
                setPhysicalValue(resJson.data?.physical_endurance_value)
            } else if (response.status == 422) {
                setMessage(resJson.data["message"]);
            } else if (response.status === 401) {
                handleLogout();
            } else if (response.status === 403) {
                handleLogout();
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const onContinue = async () => {
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/update-report-template/${report_id}`;
            const payload = {
                physical_endurance_notes: physicalNote,
                physical_endurance_value: +physicalValue,
            };
            let options = {
                method: "PATCH",
                body: JSON.stringify(payload),
                headers: new Headers({
                    authorization: `${AuthStateData?.access_token}`,
                    "content-type": "application/json",
                }),
            };
            let response = await fetch(url, options);
            let resJson = await response.json();
            if (response.status == 200 || response.status == 201) {
                setProcessSuccess(resJson?.message);
                getPreview();
                getPrimaryReportById();
                setShowSuccessAlert(true);
                setIsOpenEditOrNot(false);
                setTimeout(() => {
                    setProcessSuccess("");
                    setShowSuccessAlert(false);
                }, 1500);
                await getPreview();
            } else if (response.status == 422) {
                setMessage(resJson.data["message"]);
            } else if (response.status === 401) {
                handleLogout();
            } else if (response.status === 403) {
                handleLogout();
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const getPreview = async () => {
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/preview-report/${report_id}?category=PHYSICAL_ENDURANCE`;
            let options = {
                method: "GET",
                headers: new Headers({
                    authorization: `${AuthStateData?.access_token}`,
                }),
            };
            let response = await fetch(url, options);
            let resText = await response.text();
            if (response.status == 200 || response.status == 201) {
                setPreview(resText);
            } else if (response.status === 401) {
                handleLogout();
            } else if (response.status === 403) {
                handleLogout();
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const onEditClick = () => {
        setIsOpenEditOrNot(true);
    };

    useEffect(() => {
        if (!AuthStateData?.access_token) {
            navigate("/");
        }
        getPrimaryReportById();
        getPreview();
    }, []);

    return (
        <div id="organSpecificPage">
            <div
                className="editBtnGrp"
                style={{
                    marginBottom: "20px",
                }}
            >
                <h6 className="headingText">Physical Endurance</h6>
                {!isOpenEditOrNot ? (
                    <div>
                        {!resultFile?.is_finalized === true || userType == "DOCTOR" ? (
                            <Button
                                disableElevation={true}
                                className="editBtn"
                                variant="outlined"
                                startIcon={
                                    <img
                                        src="/edit-pink-icon.svg"
                                        alt=""
                                        width={12}
                                        height={12}
                                    />
                                }
                                onClick={onEditClick}
                            >
                                Edit
                            </Button>
                        ) : (
                            ""
                        )}
                    </div>
                ) : (
                    ""
                )}
            </div>
            {isOpenEditOrNot ? (
                <div className="processSummaryBlock">
                    <EditPhysicalEndurance
                        physicalNote={physicalNote}
                        setPhysicalNote={setPhysicalNote}
                        physicalValue={physicalValue}
                        setPhysicalValue={setPhysicalValue}
                    />
                    <div className="summaryEditBtnGrp">
                        <Button
                            className="cancelBtn"
                            onClick={() => setIsOpenEditOrNot(false)}
                            variant="text"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            className="saveBtn"
                            onClick={() => onContinue(false)}
                        >
                            Update
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="previewBlock">
                    <div
                        className="htmlPreview"
                        style={{ width: "95%", margin: "0 auto" }}
                        dangerouslySetInnerHTML={{ __html: preview }}
                    ></div>
                </div>
            )}
            <LoadingComponent loading={loading} />
            <Snackbar
                open={showSuccessAlert}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    {processSuccess}
                </Alert>
            </Snackbar>
        </div>
    );
}
export default PrivewOfPhysicalEndurance; 