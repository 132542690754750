import { Edit } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../../Core/LoadingComponent";
import styles from "./../index.module.css";
import PeriodicTestsTable from "./PeriodicTestsTable";
import { Toaster, toast } from "sonner";


const PeriodicTests = () => {
  const AuthStateData = useSelector((state) => state.Signin);
  const userType = useSelector((state) => state.Signin.user_details?.user_type);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { report_id, result_file_id } = useParams();

  const [isEditOpenOrNot, setIsEditOpenOrNot] = useState(false);
  const [dataLength, setDataLength] = useState(1);
  const [loading, setLoading] = useState("");
  const [resultFile, setResultFile] = useState({});
  const [message, setMessage] = useState("");
  const [processSuccess, setProcessSuccess] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [preview, setPreview] = useState("");
  const [open, setOpen] = useState(false);
  const [periodicData, setPeriodicData] = useState([]);
  const [indicationData, setIndicationData] = useState([]);
  const [frequencyData, setFrequencyData] = useState([]);
  const [recommendedData, setRecommendedData] = useState([]);
  const [numericalPart, setNumericalPart] = useState([]);
  const [unitPart, setUnitPart] = useState([]);



  const createPayload = (indications, recommendedTests, frequencies) => {
    console.log(frequencies);
    let updatedfrequencyData = frequencies.map(item => {
      const parts = item.split(" ");

      const num = parts[0]?.trim();
      const unit = parts[1]?.trim() || "months";

      if (parts[0] == '') {
        return `0 ${unit}`;
      } else {
        return `${num} ${unit}`;
      }
    });
    return indications.map((indication, index) => ({
      indication: indication,
      recommended_test: recommendedTests[index] || '',
      frequency: updatedfrequencyData[index] ? updatedfrequencyData[index] : '0 months',
    }));
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const setDataForDietaryGuidelines = (data) => {
    const { periodic_tests } = data;
    const periodictests = periodic_tests ? periodic_tests : [];
    const indication = periodictests.map(item => item.indication) || [];
    const recommendedtest = periodictests.map(item => item.recommended_test) || [];
    const frequency = periodictests.map(item => item.frequency) || [];
    setPeriodicData(periodictests);
    setIndicationData(indication);
    setRecommendedData(recommendedtest);
    setFrequencyData(frequency);

    let datalen = Math.max(indication.length, recommendedtest.length, frequency.length);
    setDataLength(datalen ? datalen : 1);
  };
  const getPrimaryReportById = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      console.log(resJson.data);
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        setResultFile(resJson.data ? resJson.data : {});
        setDataForDietaryGuidelines(resJson.data ? resJson.data : {});
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  const onContinue = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/update-report-template/${report_id}`;
      const payload = { periodic_tests: createPayload(indicationData, recommendedData, frequencyData) };
      let options = {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
          "content-type": "application/json",
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setProcessSuccess(resJson?.message);
        setShowSuccessAlert(true);
        setIsEditOpenOrNot(false);
        setTimeout(() => {
          setProcessSuccess("");
          setShowSuccessAlert(false);
        }, 1500);
        await getPreview();
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getPreview = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/preview-report/${report_id}?category=PERIODIC_TESTS`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resText = await response.text();
      if (response.status === 200 || response.status === 201) {
        setPreview(resText);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const onEditClick = () => {
    setIsEditOpenOrNot(true);
    getPrimaryReportById();
  };

  useEffect(() => {
    if (!AuthStateData?.access_token) {
      navigate("/");
    }
    getPrimaryReportById();
    getPreview();
  }, []);

  return (
    <div className={styles.detailscontainer} id="dietaryGuidelines">
      <div className="editBtnGrp">
        <h6 className="headingText">Periodic Tests</h6>
        {!isEditOpenOrNot ? (
          <div>
            {!resultFile?.is_finalized === true || userType == "DOCTOR" ? (
              <Button
                disableElevation={true}
                className="editBtn"
                variant="outlined"
                startIcon={
                  <img
                    src="/edit-pink-icon.svg"
                    alt=""
                    width={12}
                    height={12}
                  />
                }
                onClick={onEditClick}
              >
                Edit
              </Button>
            ) : (
              ""
            )}
          </div>
        ) : ""}
      </div>
      {isEditOpenOrNot ? (
        <div className="processSummaryBlock">
          <PeriodicTestsTable
            handleClose={handleClose}
            open={open}
            resultFile={resultFile}
            dataLength={dataLength}
            setDataLength={setDataLength}
            indicationData={indicationData}
            setIndicationData={setIndicationData}
            frequencyData={frequencyData}
            setFrequencyData={setFrequencyData}
            recommendedData={recommendedData}
            isEditOpenOrNot={isEditOpenOrNot}
            setRecommendedData={setRecommendedData}
          />
          <div className="summaryEditBtnGrp">
            <Button
              className="cancelBtn"
              variant="text"
              onClick={() => {
                setIsEditOpenOrNot(false)
                setIndicationData([]);
                setRecommendedData([]);
                setFrequencyData([]);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="saveBtn"
              onClick={() => {
                onContinue();
                setIndicationData([]);
                setRecommendedData([]);
                setFrequencyData([]);
              }}
            >
              Update
            </Button>
          </div>
        </div>
      ) : (
        <div className="previewBlock">
          <div
            className="htmlPreview"
            style={{ width: "95%", margin: "0 auto" }}
            dangerouslySetInnerHTML={{ __html: preview }}
          ></div>
        </div>
      )}
      <LoadingComponent loading={loading} />
      <Toaster closeButton richColors position="top-right"></Toaster>
      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {processSuccess}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PeriodicTests;
