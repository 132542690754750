import {
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from "@mui/material";
  import { useEffect, useState } from "react";
  import { DatePicker } from "rsuite";
  import "rsuite/dist/rsuite.css";
  import styles from "./index.module.css";
  
  const EditPatientDetails = ({
    isEditOpen,
    resultFile,
    setResultFile,
    onSaveUserDetails,
    setIsEditOpen,
    message,
    setMessage
  }) => {
  
    const [patientInfo, setPatientInfo] = useState({});
    const [collectionDate, setCollectionDate] = useState("");
    const [recevingDate, setRecevingDateDate] = useState("");
    const [reportingDate, setReportingDateDate] = useState("");
  
    const onChangeTextField = (e) => {
      const { value, name } = e.target;
      setPatientInfo((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const onChangeCollectionDate = (date) => {
      setCollectionDate(date)
    };
    const onChangeRecevingDate = (date) => {
      setRecevingDateDate(date)
    };
    const onChangeReportingDate = (date) => {
      setReportingDateDate(date)
    };
  
    const onSavePatientDetails = () => {
      let collDate = collectionDate ? new Date(collectionDate).toISOString() : "";
      let receDate = recevingDate ? new Date(recevingDate).toISOString() : "";
      let repDate = reportingDate ? new Date(reportingDate).toISOString() : "";
      const patientDetails = {
        age: patientInfo?.age,
        barcode_no: patientInfo?.barcode_no,
        client_add: patientInfo?.client_add,
        client_code: patientInfo?.client_code,
        client_name: patientInfo?.client_name,
        created_at: patientInfo?.created_at,
        date_of_collection: collDate,
        date_of_receiving: receDate,
        date_of_regestration: patientInfo?.date_of_regestration,
        date_of_reporting: repDate,
        email: patientInfo?.email,
        gender: patientInfo?.gender,
        hospital_name: patientInfo?.hospital_name,
        mobile: patientInfo?.mobile,
        name: patientInfo?.name,
        ref_doctor: patientInfo?.ref_doctor,
        sample_type: patientInfo?.sample_type,
        uhid_number: patientInfo?.uhid_number,
        updated_at: patientInfo?.updated_at,
        visit_id: patientInfo?.visit_id,
        _id: patientInfo?._id
      }
      onSaveUserDetails({
        patient_info: patientDetails,
      });
    };
  
    useEffect(() => {
      let obj = {
        ...resultFile["patient_info"],
        test: resultFile["patient_info"]?.test ? resultFile["patient_info"]?.test : "DNA Decoder"
      }
      setPatientInfo(obj);
      setCollectionDate(resultFile["patient_info"]?.date_of_collection)
      setRecevingDateDate(resultFile["patient_info"]?.date_of_receiving)
      setReportingDateDate(resultFile["patient_info"]?.date_of_reporting)
    }, [isEditOpen]);
  
    return (
      <div className={styles.patientinformation1} id="editPatientDetailsBlock">
        <header className={styles.patientheader}>
          <h2 className={styles.heading}>Patient Information</h2>
          <div className="patientEditBtnGtp">
            <Button
              disableElevation={true}
              variant="outlined"
              onClick={() => {
                setIsEditOpen(false);
                setMessage("");
              }}
              className="cancelBtn"
            >
              Cancel
            </Button>
            <Button
              disableElevation={true}
              variant="contained"
              onClick={onSavePatientDetails}
              className="saveBtn"
            >
              Save Changes
            </Button>
          </div>
        </header>
        <div className={styles.wrap}>
          <div className={styles.name}>
            <label className={styles.lable}>Name</label>
            <TextField
              fullWidth
              className="inputField"
              variant="outlined"
              type="text"
              name={"name"}
              value={patientInfo?.name ? patientInfo?.name : ""}
              onChange={onChangeTextField}
            />
            <p className="editErrorMessage">{message?.["patient_info.name"]}</p>
          </div>
          <div className={styles.name}>
            <label className={styles.lable}>Age</label>
            <TextField
              fullWidth
              className="inputField"
              variant="outlined"
              name={"age"}
              value={patientInfo?.age ? patientInfo?.age : ""}
              onChange={onChangeTextField}
            />
            <p className="editErrorMessage">{message?.["patient_info.age"]}</p>
          </div>
          <div className={styles.name}>
            <label className={styles.lable}>Gender</label>
            <FormControl fullWidth className="selectField" variant="outlined">
              <InputLabel color="primary" />
              <Select
                name={"gender"}
                value={patientInfo?.gender ? patientInfo?.gender : ""}
                onChange={onChangeTextField}
              >
                <MenuItem value="Male" className="menuItem">
                  Male
                </MenuItem>
                <MenuItem value="Female" className="menuItem">
                  Female
                </MenuItem>
                <MenuItem value="Others" className="menuItem">
                  Others
                </MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>
            <p className="editErrorMessage">{message?.["patient_info.gender"]}</p>
          </div>
          <div className={styles.name}>
            <label className={styles.lable}>{`Ref Doctor `}</label>
            <TextField
              fullWidth
              className="inputField"
              variant="outlined"
              name={"ref_doctor"}
              value={patientInfo?.ref_doctor ? patientInfo?.ref_doctor : ""}
              onChange={onChangeTextField}
            />
            <p className="editErrorMessage">
              {message?.["patient_info.ref_doctor"]}
            </p>
          </div>
          <div className={styles.name}>
            <label className={styles.lable}>Test</label>
            <TextField
              fullWidth
              className="inputField"
              variant="outlined"
              disabled
              name={"test"}
              value={patientInfo?.test ? patientInfo?.test : ""}
              onChange={onChangeTextField}
            />
            <p className="editErrorMessage">{message?.["patient_info.test"]}</p>
          </div>
          <div className={styles.name}>
            <label className={styles.lable}>Barcode</label>
            <TextField
              disabled
              fullWidth
              className="inputField"
              variant="outlined"
              type="number"
              name={"barcode_no"}
              value={patientInfo?.barcode_no ? patientInfo?.barcode_no : ""}
              onChange={onChangeTextField}
            />
            <p className="editErrorMessage">
              {message?.["patient_info.barcode_no"]}
            </p>
          </div>
          <div className={styles.name}>
            <label className={styles.lable}>Date of Collection</label>
            <div className={styles.datepicker}>
              <DatePicker
                placeholder="Select Date of Collection"
                value={collectionDate ? new Date(collectionDate) : null}
                onChange={(newValue) => {
                  onChangeCollectionDate(newValue);
                }}
                sx={{}}
                slotProps={{
                  textField: {
                    size: "medium",
                    fullWidth: false,
                    required: false,
                    autoFocus: false,
                    error: false,
                    color: "primary",
                  },
                }}
              />
              <p className="editErrorMessage">
                {message?.["patient_info.date_of_collection"]}
              </p>
            </div>
          </div>
          <div className={styles.name}>
            <label className={styles.lable}>Date of Receiving</label>
            <div className={styles.datepicker}>
              <DatePicker
                placeholder="Select Date of Receiving"
                value={recevingDate ? new Date(recevingDate) : null}
                onChange={(newValue) => {
                  onChangeRecevingDate(newValue);
                }}
                sx={{}}
                slotProps={{
                  textField: {
                    size: "medium",
                    fullWidth: false,
                    required: false,
                    autoFocus: false,
                    error: false,
                    color: "primary",
                  },
                }}
              />
              <p className="editErrorMessage">
                {message?.["patient_info.date_of_receiving"]}
              </p>
            </div>
          </div>
          <div className={styles.name}>
            <label className={styles.lable}>Date of Reporting</label>
            <div className={styles.datepicker}>
              <DatePicker
                placeholder="Select Date of Reporting"
                value={reportingDate ? new Date(reportingDate) : null}
                onChange={(newValue) => {
                  onChangeReportingDate(newValue);
                }}
                sx={{}}
                slotProps={{
                  textField: {
                    size: "medium",
                    fullWidth: false,
                    required: false,
                    autoFocus: false,
                    error: false,
                    color: "primary",
                  },
                }}
              />
              <p className="editErrorMessage">
                {message?.["patient_info.date_of_reporting"]}
              </p>
            </div>
          </div>
          <div className={styles.name}>
            <label className={styles.lable}>Sample Type</label>
            <TextField
              fullWidth
              className="inputField"
              variant="outlined"
              name={"sample_type"}
              value={patientInfo?.sample_type ? patientInfo?.sample_type : ""}
              onChange={onChangeTextField}
            />
            <p className="editErrorMessage">
              {message?.["patient_info.sample_type"]}
            </p>
          </div>
          <div className={styles.name}>
            <label className={styles.lable}>Visit No</label>
            <TextField
              fullWidth
              className="inputField"
              disabled
              variant="outlined"
              type="text"
              name={"visit_id"}
              value={patientInfo?.visit_id ? patientInfo?.visit_id : ""}
              onChange={onChangeTextField}
            />
            <p className="editErrorMessage">
              {message?.["patient_info.visit_id"]}
            </p>
          </div>
        </div>
      </div>
    );
  };
  
  export default EditPatientDetails;
  