import CKEditorComponent from "../../../Core/CKEditorComponent";
import styles from "./../index.module.css";

const SummaryDetailsBlock = ({ editorData, setEditorData }) => {
  const handleEditorChange = (data) => {
    setEditorData(data);
  };
  return (
    <div className={styles.summary1}>
      <div className="editSummaryBlock">
        <h2 className="headingText">Summary</h2>
      </div>
      <CKEditorComponent
        handleEditorChange={handleEditorChange}
        editorData={editorData}
      />
    </div>
  );
};

export default SummaryDetailsBlock;
