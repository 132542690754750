import {
  Chip,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import styles from "./FilterOptions.module.css";
import "../scss/ResultFiles.scss";

const FilterOptions = ({
  setSearchStaring,
  searchString,
  setStatus,
  status,
  getGenerateReport,
  setFromDate,
  fromDate,
  setToDate,
  toDate,
  setSelectCategory,
  selectCategory,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [urlSearchParams, setUrlSearchParams] = useState();

  useEffect(() => {
    let params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    setUrlSearchParams(params);
    handleChangeStatus(
      searchParams.get("report_status")
        ? searchParams.get("report_status")
        : "All"
    );
    handleChangeCategory(
      searchParams.get("category") ? searchParams.get("category") : "All"
    );
  }, []);

  const handleChangeStatus = (updateStatus) => {
    setStatus(updateStatus);
    if (updateStatus === "All") {
      getGenerateReport({ report_status: "", page: 1 });
    } else {
      getGenerateReport({ report_status: updateStatus, page: 1 });
    }
  };

  const handleChangeCategory = (updateCategory) => {
    setSelectCategory(updateCategory);
    if (updateCategory === "All") {
      getGenerateReport({ category: "", page: 1 });
    } else {
      getGenerateReport({ category: updateCategory, page: 1 });
    }
  };

  const formatDate = (date) => {
    if (!date) return null;
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateRangeChange = (range) => {
    if (range) {
      const [start, end] = range;
      setFromDate(formatDate(start));
      setToDate(formatDate(end));
      getGenerateReport({
        from_date: formatDate(start) ? formatDate(start) : "",
        to_date: formatDate(end) ? formatDate(end) : "",
        page: 1,
      });
    } else {
      setFromDate(null);
      setToDate(null);
      getGenerateReport({
        from_date: "",
        to_date: "",
        page: 1,
      });
    }
  };

  return (
    <div className={styles.filteroptions}>
      <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
        <Chip
          className="statusChip"
          label="All"
          variant="outlined"
          onClick={() => handleChangeStatus("All")}
          style={{
            backgroundColor: status === "All" ? "#A3BE3C" : "#fff",
            color: status === "All" ? "#fff" : "#000",
          }}
        />
        <Chip
          className="statusChip"
          label="Completed"
          variant="outlined"
          onClick={() => handleChangeStatus("COMPLETED")}
          style={{
            backgroundColor: status === "COMPLETED" ? "#A3BE3C" : "#fff",
            color: status === "COMPLETED" ? "#fff" : "#000",
          }}
        />
        <Chip
          className="statusChip"
          label="Approved"
          variant="outlined"
          onClick={() => handleChangeStatus("APPROVED")}
          style={{
            backgroundColor: status === "APPROVED" ? "#A3BE3C" : "#fff",
            color: status === "APPROVED" ? "#fff" : "#000",
          }}
        />
      </Stack>

      <div className="searchFieldsGrp">
        <TextField
          className="patientSearch"
          type="search"
          placeholder="Search by barcode,patient name, visit id"
          variant="outlined"
          value={searchString}
          onChange={(e) => {
            setSearchStaring(e.target.value);
          }}
        />
        <FormControl className="selectCategory">
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            size="small"
            value={selectCategory ? selectCategory : "All"}
            onChange={(e) => handleChangeCategory(e.target.value)}
          >
            <MenuItem className="menuItem" value="All">
              All
            </MenuItem>
            <MenuItem className="menuItem" value="PHARMACOGENOMICS">
              PHARMACOGENOMICS
            </MenuItem>
            <MenuItem className="menuItem" value="NUTRIGENOMICS">
              NUTRIGENOMICS
            </MenuItem>
            <MenuItem className="menuItem" value="RISK DISORDERS">
              RISK DISORDERS
            </MenuItem>
            <MenuItem className="menuItem" value="GRAND REPORT">
              GRAND REPORT
            </MenuItem>
          </Select>
        </FormControl>
        <DateRangePicker
          showOneCalendar
          value={
            fromDate && toDate ? [new Date(fromDate), new Date(toDate)] : null
          }
          onChange={handleDateRangeChange}
          placeholder="Select Start Date - End Date"
          style={{ width: 220 }}
          disabledDate={(date) => {
            return date.getTime() >= new Date().getTime();
          }}
        />
      </div>
    </div>
  );
};

export default FilterOptions;
