import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AlertTitle,
  Button,
  Card,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import { useSelector } from "react-redux";
import "./EditRiskOrderDetails.css";
import { Table } from "rsuite";

function EditRiskDisorderDetails() {
  const AuthStateData = useSelector((state) => state.Signin);

  const [patientInfo, setPatientInfo] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMesssage] = useState();
  const [errorMessage, setErrorMesssage] = useState();
  const [loadingResult, setLoadingResult] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [date, setDate] = useState();
  const [date1, setDate1] = useState();
  const [singleReportDetails, setSingleReportDetails] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm();

  const { _id, report_id } = useParams();

  const getDetailsOfSingleReport = async () => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/risk-disorders/result-file/${_id}/report/${report_id}`;

      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resjson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        setPatientInfo(resjson.data["patient_info"]);
        setSingleReportDetails(resjson?.data?.disorder_list);
      }
    } catch (err) {
      setLoadingResult(false);
      console.error(err);
    } finally {
      setLoadingResult(false);
    }
  };

  const handleEdit = async (data) => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/risk-disorders/result-files/${_id}/update-template/${report_id}`;
      let requestBody = {
        patient_info: data,
        disorder_list: singleReportDetails,
      };
      let options = {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
        body: JSON.stringify(requestBody),
      };

      let response = await fetch(url, options);
      let responseData = await response.json();
      setSuccessMesssage(responseData.message);
      setErrorMesssage(responseData.message);
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        setLoading(false);
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate(-1);
        }, 1500);
      } else {
        setShowErrorAlert(true);
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 1500);
      }
    } catch (err) {
      setLoadingResult(false);
      setLoading(false);
      console.error(err);
    } finally {
      setLoadingResult(false);
      setLoading(false);
    }
  };

  const handleRegistration = (data) => {
    if (data) {
      let temp = { ...data };
      temp["visit_id"] = patientInfo["visit_id"];
      handleEdit(temp);
      setLoading(true);
    }
  };

  //handle the edit values and store the same set state
  const editScoreValues = (editedObj, value) => {
    let afterEditedValues = [];
    afterEditedValues = singleReportDetails?.map((item, index) => {
      if (item.disorder == editedObj.disorder) {
        return {
          ...item,
          ["total_score"]: +value,
        };
      }
      return { ...item };
    });
    setSingleReportDetails(afterEditedValues);
  };

  const dateTime = (data) => {
    let result =
      data.slice(8, 10) + "-" + data.slice(5, 7) + "-" + data.slice(0, 4);
    let dt = new Date(data);
    let hours = dt.getHours();
    let AmOrPm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    let minutes = dt.getMinutes();
    result = result + "  " + hours + ":" + minutes + " " + AmOrPm;
    return result;
  };
  const handleInput = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    event.target.value = value.slice(0, 10);
  };

  const handleInputName = (event) => {
    const value = event.target.value.replace(/[^a-zA-Z\s.]/g, "");
    event.target.value = value.slice(0, 10);
  };

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
    getDetailsOfSingleReport();
  }, []);

  return (
    <div style={{ marginTop: "20px" }}>
      {/* <h1></h1> */}
      <Box className="edit-block">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <h3 className="reportEditTitle">Patient Results Edit</h3>
          <Typography varient="h1"> </Typography>

          <Button
            size="small"
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
            Back
          </Button>
        </div>

        <Card className="edit-card">
          <Card
            className="small-editcard"
            style={{ backgroundColor: "#2eaf9f" }}
          >
            <Typography variant="h2">DEPARTMENT OF GENOMICS</Typography>
          </Card>
          {patientInfo ? (
            <form onSubmit={handleSubmit(handleRegistration)}>
              <div className="Patient-info">
                <table className="infoBlock">
                  <tbody>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>Visit ID</Typography>
                      </td>
                      <td className="inputField">
                        <TextField
                          size="small"
                          disabled
                          variant="outlined"
                          sx={{ width: "100%" }}
                          defaultValue={patientInfo["visit_id"]}
                          {...register("visit_id")}
                        />
                      </td>
                    </tr>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>Patient Name</Typography>
                      </td>
                      <td className="inputField">
                        <TextField
                          size="small"
                          type="text"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          defaultValue={patientInfo["name"]}
                          onInput={handleInputName}
                          {...register("name")}
                        />
                      </td>
                    </tr>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>Ref Doctor</Typography>
                      </td>
                      <td className="inputField">
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          defaultValue={patientInfo.ref_doctor}
                          {...register("ref_doctor")}
                        />
                      </td>
                    </tr>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>Client Add</Typography>
                      </td>
                      <td className="inputField">
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          defaultValue={patientInfo.client_add}
                          {...register("client_add")}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="infoBlock">
                  <tbody>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>UHID/MR No</Typography>
                      </td>
                      <td className="inputField">
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          defaultValue={patientInfo.uhid_number}
                          {...register("uhid_number")}
                        />
                      </td>
                    </tr>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>Age/Gender</Typography>
                      </td>
                      <td className="inputField">
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          defaultValue={patientInfo.age}
                          {...register("age")}
                        />
                      </td>
                    </tr>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>Client Name</Typography>
                      </td>
                      <td className="inputField">
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          defaultValue={patientInfo.client_name}
                          {...register("client_name")}
                        />
                      </td>
                    </tr>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>Sample Type</Typography>
                      </td>
                      <td className="inputField">
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          defaultValue={patientInfo.sample_type}
                          {...register("sample_type")}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="infoBlock">
                  <tbody>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>Registration Date</Typography>
                      </td>
                      <td className="inputField">
                        <Controller
                          width="100%"
                          name="date_of_regestration"
                          defaultValue={
                            patientInfo.date_of_regestration
                              ? patientInfo.date_of_regestration
                              : date
                          }
                          control={control}
                          render={({ field: { onChange, ...restField } }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Request Date"
                                style={{ width: "220px", height: "30px" }}
                                onChange={(event) => {
                                  onChange(new Date(event).toISOString());
                                  setDate(event);
                                }}
                                renderInput={(params) => (
                                  <TextField fullWidth {...params} />
                                )}
                                {...restField}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </td>
                    </tr>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>Collected Date</Typography>
                      </td>
                      <td className="inputField">
                        <Controller
                          width="100%"
                          name="date_of_collection"
                          defaultValue={
                            patientInfo.date_of_collection
                              ? patientInfo.date_of_collection
                              : date1
                          }
                          control={control}
                          render={({ field: { onChange, ...restField } }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Request Date"
                                style={{ width: "220px", height: "30px" }}
                                onChange={(event) => {
                                  onChange(new Date(event).toISOString());
                                  setDate(event);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                {...restField}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </td>
                    </tr>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>Received Date</Typography>
                      </td>
                      <td className="inputField">
                        <Controller
                          width="100%"
                          name="date_of_receving"
                          defaultValue={
                            patientInfo.date_of_receiving
                              ? patientInfo.date_of_receiving
                              : date1
                          }
                          control={control}
                          render={({ field: { onChange, ...restField } }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Request Date"
                                style={{ width: "220px", height: "30px" }}
                                onChange={(event) => {
                                  onChange(new Date(event).toISOString());
                                  setDate(event);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    className="inputField"
                                    {...params}
                                  />
                                )}
                                {...restField}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </td>
                    </tr>
                    <tr className="item">
                      <td className="inputname">
                        <Typography>Reported Date</Typography>
                      </td>
                      <td className="inputField">
                        <Controller
                          width="100%"
                          name="date_of_receving"
                          defaultValue={
                            patientInfo.date_of_reporting
                              ? patientInfo.date_of_reporting
                              : date1
                          }
                          control={control}
                          render={({ field: { onChange, ...restField } }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Request Date"
                                style={{ width: "220px", height: "30px" }}
                                onChange={(event) => {
                                  onChange(new Date(event).toISOString());
                                  setDate(event);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    className="inputField"
                                    {...params}
                                  />
                                )}
                                {...restField}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="genes-block">
                <Typography variant="h5" className="gene-heading">
                  RISK FOR GENETIC DISORDERS
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                  {singleReportDetails?.length ? (
                    <table className="styled-table">
                      <thead>
                        <tr>
                          <th className="header"></th>
                          <th className="header">Diabetes Panel</th>

                          <th className="header" width="560px">
                            <img
                              src="/Process.svg"
                              alt="Header Image"
                              style={{ width: "280px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                textAlign: "center",
                                fontSize: "12px",
                                padding: "5px 0",
                                fontWeight: "700",
                              }}
                            >
                              <div
                                style={{ width: "70px", textAlign: "center" }}
                              >
                                Nill
                              </div>
                              <div
                                style={{ width: "70px", textAlign: "center" }}
                              >
                                Mild
                              </div>
                              <div
                                style={{ width: "70px", textAlign: "center" }}
                              >
                                Moderate
                              </div>
                              <div
                                style={{ width: "70px", textAlign: "center" }}
                              >
                                High
                              </div>
                              <div
                                style={{ width: "70px", textAlign: "center" }}
                              >
                                Score
                              </div>
                              <div
                                style={{ width: "70px", textAlign: "center" }}
                              >
                                Normal TH
                              </div>
                              <div
                                style={{ width: "70px", textAlign: "center" }}
                              >
                                Moderate TH
                              </div>
                              <div
                                style={{ width: "70px", textAlign: "center" }}
                              >
                                High TH
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {singleReportDetails?.length ? (
                          singleReportDetails?.map((row, index) => (
                            <tr key={index}>
                              <td></td>

                              <td>{row?.disorder}</td>

                              <td>
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(8,1fr)",
                                    gap: "5px",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <div>
                                    {row?.risk_type == "Moderate_ris" ? (
                                      <img src="/Nill.svg" />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                  <div>
                                    {row?.risk_type == "Normal_risk" ? (
                                      <img src="/Mid.svg" />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                  <div>
                                    {row?.risk_type == "Moderate_risk" ? (
                                      <img src="/Moderate.svg" />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                  <div>
                                    {row?.risk_type == "High_risk" ? (
                                      <img src="/High.svg" />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                  <div>
                                    <TextField
                                      sx={{
                                        "& .MuiInputBase-root": {
                                          height: "26px",
                                          width: "50px",
                                          fontSize: "13px",
                                          margin: "0 auto",
                                        },
                                        "& .MuiOutlinedInput-input": {
                                          textAlign: "center",
                                        },
                                      }}
                                      value={row?.total_score}
                                      onChange={(e) => {
                                        editScoreValues(row, e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <p className="tdvalue">
                                      {"<" + row?.moderate_risk_threshold}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="tdvalue">
                                      {">" +
                                        row?.moderate_risk_threshold +
                                        " " +
                                        "<" +
                                        row?.high_risk_threshold}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="tdvalue">
                                      {">=" + row?.high_risk_threshold}
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}
                </Box>
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <Button
                  className="update-btn"
                  variant="contained"
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </form>
          ) : (
            ""
          )}
        </Card>
      </Box>
      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingResult}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
export default EditRiskDisorderDetails;
