import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addSerialNo } from "../../utils/addSerialNumberHelper";
import { prepareURLEncodedParams } from "../../utils/requestUtils";
import LoadingComponent from "../Core/LoadingComponent";
import SummariesTable from "./SummariesTable";
import SummaryTableFilterHeader from "./SummaryHeader";
import TablePaginationComponent from "./TablePaginationComponent";
import styles from "./summery.module.css";

const AllSummariesComponent = () => {
  const AuthStateData = useSelector((state) => state.Signin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [data, setData] = useState();
  const [search, setSearch] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const [urlSearchParams, setUrlSearchParams] = useState();

  useEffect(() => {
    let params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    setUrlSearchParams(params);
  }, [searchParams]);

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const getAllSummaries = async ({
    page = searchParams.get("page") ? searchParams.get("page") : 1,
    limit = searchParams.get("limit") ? searchParams.get("limit") : 20,
    search_string = searchParams.get("search_string"),
    sortBy = searchParams.get("sort_by"),
    sortType = searchParams.get("sort_type"),
  }) => {
    setLoading(true);
    let queryParams = {
      page: 1,
      limit: 20,
    };
    if (page) {
      queryParams["page"] = page;
    }
    if (limit) {
      queryParams["limit"] = limit;
    }
    if (search_string) {
      queryParams["search_string"] = search_string;
    }
    if (sortBy) {
      queryParams["sort_by"] = sortBy;
    }
    if (sortType) {
      queryParams["sort_type"] = sortType;
    }

    try {
      let { page, limit, ...apiQueryParams } = queryParams;

      setSearchParams(queryParams);
      const url = prepareURLEncodedParams(
        `${process.env.REACT_APP_API_BASE_URL}/summary-reports/${page}/${limit}`,
        apiQueryParams
      );
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      const response = await fetch(url, options);
      let responseData = await response.json();
      if (response?.status >= 200 && response?.status < 300) {
        let { data, ...rest } = responseData;
        data = addSerialNo(data, rest?.page, rest?.limit);
        setData(data);
        setPaginationDetails(rest);
      } else if (response?.status == 403 || response?.status == 401) {
        handleLogout();
      } else {
        throw response;
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let delay = 500;
    let debounce = setTimeout(() => {
      getAllSummaries({
        search_string: search ? search : "",
      });
    }, delay);
    return () => clearTimeout(debounce);
  }, [search]);

  const capturePageNum = (value) => {
    getAllSummaries({
      page: value,
      limit: urlSearchParams?.limit,
    });
  };

  const captureRowPerItems = (value) => {
    getAllSummaries({
      page: 1,
      limit: value,
    });
  };

  return (
    <div className={styles.tableContainer} id="summaryPage">
      <SummaryTableFilterHeader
        getAllSummaries={getAllSummaries}
        search={search}
        setSearch={setSearch}
      />
      <SummariesTable
        data={data}
        getAllSummaries={getAllSummaries}
        Summaryloading={loading}
      />
      {!loading ? (
        <TablePaginationComponent
          paginationDetails={paginationDetails}
          capturePageNum={capturePageNum}
          captureRowPerItems={captureRowPerItems}
          values="Summaries"
        />
      ) : (
        ""
      )}
      <LoadingComponent loading={loading} />
    </div>
  );
};
export default AllSummariesComponent;
