import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Button,
  Typography,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./CreateForm.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  setRecords,
  setResponse,
  setData,
} from "../StoreSlices/CreateFormSlice";
const CreateForm = () => {
  const AuthStateData = useSelector((state) => state.Signin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(null);
  const [datevalue, setDateValue] = React.useState(null);
  const [newvalue, setNewValue] = React.useState(null);
  const [datesvalue, setDatesValue] = React.useState(null);
  const [rows, setRowData] = useState([]);
  const [parameterdata, setParameterdata] = useState([]);
  const [reportid, setReportId] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const [message, setMessage] = useState("");
  const [formData, setformData] = useState({});
  const [patientInfo, setPatientInfo] = useState({});

  const [open, setOpen] = useState(false);
  let token = localStorage.getItem("code");

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  // parameter and value api
  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
    getParameter();
    setOpen(false);
  }, []);
  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const getParameter = async () => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/medicines/parameters`;
    let options = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        authorization: `${AuthStateData.access_token}`,
      }),
    };
    let result = await fetch(url, options)
      .then((result) => result.json())
      .then((getalldata) => {
        setRowData(getalldata.records);
        dispatch(setRecords(getalldata.records));
        if (getalldata.statusCode === 403) {
          handleLogout();
        }
        if (getalldata.status === 401) {
          handleLogout();
        }
      });
  };

  const getData = async () => {
    setShowLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/medicines/${reportid}`;
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      setParameterdata(resJson.data.medications);
      if (response.status === 401) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoading(false);
    }
  };

  // parameter and value submit
  let handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setShowLoading(true);
      setOpen(true);

      if (reportid) {
        let url = `${process.env.REACT_APP_API_BASE_URL}/medicines/data/${reportid}`;
        let options = {
          method: "PATCH",
          headers: new Headers({
            "Content-Type": "application/json",
            authorization: `${AuthStateData.access_token}`,
          }),
          body: JSON.stringify({
            form: formData,
            patient_info: patientInfo,
          }),
        };
        let response = await fetch(url, options);
        let resJson = await response.json();
        if (response.status === 200 || response.status === 201) {
          getData();
          dispatch(setData(resJson.data));
          setParameterdata(resJson.data.medications);
          setReportId(resJson.data._id);
          setMessage("");
        } else if (response.status === 401) {
          handleLogout();
        } else {
          setMessage("Some error occured");
        }
      } else {
        let url = `${process.env.REACT_APP_API_BASE_URL}/medicines/data`;
        let options = {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            // access_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6eyJfaWQiOiI2MzMyYTZmNzU2ZjIzMjM1Y2IxZWRlY2UiLCJmaXJzdF9uYW1lIjoiYWtoaWwiLCJsYXN0X25hbWUiOiJiYWJ1IiwidXNlcl9uYW1lIjoic2hhcmlmIiwiZW1haWwiOiJzaGFyaWZAZ21haWwuY29tIiwicGhvbmUiOiI5ODQ4NTAwMTIwIiwicGFzc3dvcmQiOiIkMmIkMTAkeFpEb2V1b3p2bklsT1JSNVpkUmdpLkoxQnlVVXpqd2NjNkh1ZGlLT0FEWFhtUFE5eGxubnUiLCJ1c2VyX3R5cGUiOiJBRE1JTiIsIl9fdiI6MH0sImlkIjoiNjMzMmE2Zjc1NmYyMzIzNWNiMWVkZWNlIiwiaWF0IjoxNjY0Nzc3MTE3LCJleHAiOjE2NjQ4NjM1MTd9.SNa73EtOeOk2K1LvSNaGACEI8gBAQT0Tv06EyqiJfrA",
            authorization: `${AuthStateData.access_token}`,
          }),
          body: JSON.stringify({
            form: formData,
            patient_info: patientInfo,
          }),
        };
        let res = await fetch(url, options);
        let resJson = await res.json();
        if (res.status === 200 || res.status === 201) {
          dispatch(setResponse(resJson.response));
          setParameterdata(resJson.response.medications);
          setReportId(resJson.response._id);
          setMessage("");
        } else if (res.status === 401) {
          handleLogout();
        } else {
          setMessage("Some error occured");
        }
      }

      setShowLoading(false);
      setOpen(false);
    } catch (err) {
      setShowLoading(false);
      setOpen(false);
      console.error(err);
    }
  };

  // input values and params code
  const updateParamValue = (param, value) => {
    formData[param] = value;
    setformData(formData);
  };

  const updatePatientsValue = (param, value) => {
    patientInfo[param] = value;
    setPatientInfo(patientInfo);
  };

  // clear input fields
  const ref = React.useRef();
  function reset(ev) {
    ev.preventDefault();
    ref.current.reset();
  }

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleDateChange = (newdateValue) => {
    setDateValue(newdateValue);
  };

  const handledateChange = (newDateValue) => {
    setNewValue(newDateValue);
  };

  const handlevalueChange = (value) => {
    setDatesValue(value);
  };

  const handleKeyPress = (event) => {
    const keyPressed = event.key;
    const allowedCharacters = ["0", "1", "2", "3"];

    if (!allowedCharacters.includes(keyPressed)) {
      event.preventDefault();
    }
  };

  return (
    <section id="dashboardPage">
      <div className="container-fluid">
        <h3 className="pageName">PGX - DNA Decoder</h3>

        <form onSubmit={handleSubmit} ref={ref}>
          <div className="patientInfomation">
            <h4 class="blockName">Patient Information</h4>
            <div className="customCard">
              <Grid container>
                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Visit id<strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <TextField
                        variant="outlined"
                        type="string"
                        size="small"
                        onChange={(e) =>
                          updatePatientsValue("visit_id", e.target.value)
                        }
                      />
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Name<strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="string"
                        onChange={(e) =>
                          updatePatientsValue("name", e.target.value)
                        }
                      />
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Age<strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="string"
                        onChange={(e) =>
                          updatePatientsValue("age", e.target.value)
                        }
                      />
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Gender<strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="string"
                        onChange={(e) =>
                          updatePatientsValue("gender", e.target.value)
                        }
                      />
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        UHID number
                        <strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="string"
                        onChange={(e) =>
                          updatePatientsValue("uhid_number", e.target.value)
                        }
                      />
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        TEST<strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="string"
                        onChange={(e) =>
                          updatePatientsValue("test", e.target.value)
                        }
                      />
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Client Name
                        <strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="string"
                        onChange={(e) =>
                          updatePatientsValue("client_name", e.target.value)
                        }
                      />
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Client Add
                        <strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="string"
                        onChange={(e) =>
                          updatePatientsValue("client_add", e.target.value)
                        }
                      />
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Ref Doctor
                        <strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="string"
                        onChange={(e) =>
                          updatePatientsValue("ref_doctor", e.target.value)
                        }
                      />
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Sample Type
                        <strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="string"
                        onChange={(e) =>
                          updatePatientsValue("sample_type", e.target.value)
                        }
                      />
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Hospital Name
                        <strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="string"
                        onChange={(e) =>
                          updatePatientsValue("hospital_name", e.target.value)
                        }
                      />
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Date of Registration
                        <strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack
                          className="text-field"
                          spacing={3}
                          sx={{ width: "26ch" }}
                        >
                          <DesktopDatePicker
                            keyboard
                            size="small"
                            inputFormat="MM/DD/YYYY"
                            value={value}
                            onChange={handleChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Date of Collection
                        <strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack
                          className="text-field"
                          spacing={3}
                          sx={{ width: "26ch" }}
                        >
                          <DesktopDatePicker
                            keyboard
                            inputFormat="MM/DD/YYYY"
                            value={value}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Date of Receving
                        <strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack
                          className="text-field"
                          spacing={3}
                          sx={{ width: "26ch" }}
                        >
                          <DesktopDatePicker
                            keyboard
                            inputFormat="MM/DD/YYYY"
                            value={newvalue}
                            onChange={handledateChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </FormControl>
                </Grid>

                <Grid className="form-block" item xs={12} md={4} lg={3}>
                  <FormControl>
                    <div className="formControl">
                      <Typography className="heading">
                        Date of Reporting
                        <strong style={{ color: "#c70a0d" }}>*</strong>
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack
                          className="text-field"
                          spacing={3}
                          sx={{ width: "26ch" }}
                        >
                          <DesktopDatePicker
                            keyboard
                            inputFormat="MM/DD/YYYY"
                            value={datesvalue}
                            onChange={handlevalueChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="parameterInformation">
            <h4 class="blockName">Parameter</h4>
            <div className="customCard">
              <Grid container>
                {rows?.map((prmtr, index) => (
                  <Grid
                    className="form-block"
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={2}
                    key={index}
                  >
                    <FormControl>
                      <div className="formControl">
                        <p className="parameterName">
                          {" "}
                          {prmtr.parameter} ({prmtr.ref}) :{" "}
                        </p>
                        <TextField
                          size="small"
                          variant="outlined"
                          onKeyPress={handleKeyPress}
                          InputProps={{ inputProps: { min: 0, max: 3 } }}
                          onChange={(e) =>
                            updateParamValue(prmtr.parameter, e.target.value)
                          }
                        />
                      </div>
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>

          <div class="d-block">
            <Button
              variant="contained"
              type="submit"
              disabled={showLoading === true ? true : false}
              style={{ float: "right", marginTop: "30px" }}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={reset}
              style={{ float: "right", marginRight: "5px", marginTop: "30px" }}
            >
              Clear Fields
            </Button>
          </div>
        </form>
        {reportid?.length > 0 && (
          <Link to={"/report-preview/" + reportid} target="_blank">
            <Button
              variant="contained"
              type="submit"
              disabled={showLoading === true ? true : false}
              style={{
                float: "right",
                marginTop: "30px",
                marginRight: "5px",
              }}
            >
              Preview Report
            </Button>
          </Link>
        )}

        <div style={{ marginTop: "90px" }}>
          <Card>
            <Table>
              <TableHead style={{ background: "#584a91", color: "#fff" }}>
                <TableRow>
                  <TableCell style={{ color: "#fff" }}>Speciality</TableCell>
                  <TableCell style={{ color: "#fff" }}>Class</TableCell>
                  <TableCell style={{ color: "#fff" }}>Medicine</TableCell>
                  <TableCell style={{ color: "#fff", width: "200px" }}>
                    Therapeutic Recommendation
                  </TableCell>
                  <TableCell style={{ color: "#fff" }}>Decision</TableCell>
                  <TableCell style={{ color: "#fff" }}>
                    Interpretation
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parameterdata?.map((tbledata) => {
                  let color =
                    tbledata.therapeutic_recommendation === "Standard"
                      ? "green"
                      : tbledata.therapeutic_recommendation === "Alternative"
                      ? "red"
                      : "orange";
                  let colordecision =
                    tbledata.decision === "D"
                      ? "red"
                      : tbledata.decision === "C"
                      ? "green"
                      : "orange";
                  return (
                    <TableRow>
                      <TableCell>{tbledata.speciality}</TableCell>
                      <TableCell>{tbledata.class}</TableCell>
                      <TableCell>{tbledata.medicine}</TableCell>
                      <TableCell style={{ backgroundColor: color }}>
                        {tbledata.therapeutic_recommendation}
                      </TableCell>
                      <TableCell style={{ color: colordecision }}>
                        {tbledata.decision}
                      </TableCell>
                      <TableCell>{tbledata.interpreation}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card>
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </section>
  );
};

export default CreateForm;
