import { Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import styles from "./../index.module.css";
import DateFormate from "../../../Core/DateFormate";
import { useSelector } from "react-redux";

const ViewPatientDetails = ({ setIsEditOpen, resultFile }) => {
  const { patient_info: patientInfo } = resultFile;
  const userType = useSelector((state) => state.Signin.user_details?.user_type);

  return (
    <div className={styles.patientinformation}>
      <header className={styles.patientheader}>
        <h2 className={styles.heading}>Patient Information</h2>
        {!resultFile?.is_finalized === true || userType == "DOCTOR" ? (
          <Button
            disableElevation={true}
            className="editBtn"
            variant="outlined"
            startIcon={
              <img src="/edit-pink-icon.svg" alt="" width={12} height={12} />
            } onClick={() => setIsEditOpen((prev) => !prev)}>
            Edit
          </Button>
        ) : (
          ""
        )}
      </header>
      <div className={styles.wrap}>
        <div className={styles.name}>
          <label className={styles.lable}>Name</label>
          <p className={styles.gender}>
            {patientInfo?.name ? patientInfo?.name : ""}
          </p>
        </div>
        <div className={styles.name}>
          <label className={styles.lable}>Age</label>
          <p className={styles.gender}>
            {patientInfo?.age ? patientInfo?.age : ""}
          </p>
        </div>
        <div className={styles.name}>
          <label className={styles.lable}>Gender</label>
          <p className={styles.gender}>
            {patientInfo?.gender ? patientInfo?.gender : ""}
          </p>
        </div>
        <div className={styles.name}>
          <label className={styles.lable}>{`Ref Doctor `}</label>
          <p className={styles.gender}>
            {patientInfo?.ref_doctor ? patientInfo?.ref_doctor : ""}
          </p>
        </div>
        <div className={styles.name}>
          <label className={styles.lable}>Test</label>
          <p className={styles.gender}>
            {patientInfo?.test ? patientInfo?.test : "DNA Decoder"}
          </p>
        </div>
        <div className={styles.name}>
          <label className={styles.lable}>Barcode</label>
          <p className={styles.gender}>
            {patientInfo?.barcode_no ? patientInfo?.barcode_no : ""}
          </p>
        </div>
        <div className={styles.name}>
          <label className={styles.lable}>Date of Collection</label>
          <p className={styles.gender}>
            {patientInfo?.date_of_collection
              ? DateFormate(patientInfo?.date_of_collection)
              : ""}
          </p>
        </div>
        <div className={styles.name}>
          <label className={styles.lable}>Date of Receiving</label>
          <p className={styles.gender}>
            {patientInfo?.date_of_receiving
              ? DateFormate(patientInfo?.date_of_receiving)
              : ""}
          </p>
        </div>
        <div className={styles.name}>
          <label className={styles.lable}>Date of Reporting</label>
          <p className={styles.gender}>
            {patientInfo?.date_of_reporting
              ? DateFormate(patientInfo?.date_of_reporting)
              : ""}
          </p>
        </div>
        <div className={styles.name}>
          <label className={styles.lable}>Sample Type</label>
          <p className={styles.gender}>
            {patientInfo?.sample_type ? patientInfo?.sample_type : ""}
          </p>
        </div>
        <div className={styles.name}>
          <label className={styles.lable}>Visit No</label>
          <p className={styles.gender}>
            {patientInfo?.visit_id ? patientInfo?.visit_id : ""}
          </p>
        </div>
      </div>
    </div>
  );
};
export default ViewPatientDetails;
