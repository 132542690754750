import CKEditorComponent from "../../../Core/CKEditorComponent";

const RiskforGenetikDisordersCKEditor = ({ editorData, setEditorData }) => {
  return (
    <div className="riskDisordersCKEditor">
      <CKEditorComponent
        editorData={editorData}
        handleEditorChange={setEditorData}
      />
    </div>
  );
};

export default RiskforGenetikDisordersCKEditor;
