import {
  Alert,
  AlertTitle,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addSerialNo } from "../../utils/addSerialNumberHelper";
import { prepareURLEncodedParams } from "../../utils/requestUtils";
import LoadingComponent from "../Core/LoadingComponent";
import TablePaginationComponent from "../Core/TablePagination";
import FilterOptions from "./FilterOptions";
import DeleteDialog from "../Core/DeleteDialog";
import SortImages from "../Core/SortImages";
import DateTimeFormate from "../Core/DateTimeFormate";
import DateFormate from "../Core/DateFormate";
import { Toaster, toast } from "sonner";

const GeneratedReports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AuthStateData = useSelector((state) => state.Signin);
  const userId = useSelector((state) => state?.Signin?.user_details);
  const userType = useSelector((state) => state.Signin.user_details?.user_type);

  const [generateResultFile, setGenerateResultFile] = useState(null);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [searchString, setSearchStaring] = useState("");
  const [successMessage, setSuccessMesssage] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [mount, setMount] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [urlSearchParams, setUrlSearchParams] = useState();
  const [id, setId] = useState("");
  const [resultFileId, setResultFileId] = useState("");
  const [deleteLoadingId, setDeleteLoadingId] = useState(false);
  const [openId, setOpenId] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");

  useEffect(() => {
    let params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    setUrlSearchParams(params);
    setSearchStaring(
      searchParams.get("search_string") ? searchParams.get("search_string") : ""
    );
    setFromDate(searchParams.get("from_date"));
    setToDate(searchParams.get("to_date"));
  }, []);

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const getGenerateReport = async ({
    page = searchParams.get("page"),
    limit = searchParams.get("limit"),
    search_string = searchParams.get("search_string"),
    report_status = searchParams.get("report_status"),
    from_date = searchParams.get("from_date"),
    to_date = searchParams.get("to_date"),
    sort_by = searchParams.get("sort_by"),
    sort_type = searchParams.get("sort_type"),
    category = searchParams.get("category")
  }) => {
    setLoading(true);
    let queryParams = {
      page: 1,
      limit: 20,
    };
    if (page) {
      queryParams["page"] = page;
    }
    if (limit) {
      queryParams["limit"] = limit;
    }
    if (sort_by) {
      queryParams["sort_by"] = sort_by;
    }
    if (sort_type) {
      queryParams["sort_type"] = sort_type;
    }
    if (report_status) {
      queryParams["report_status"] = report_status;
    }
    if (search_string) {
      queryParams["search_string"] = search_string;
    }
    if (from_date) {
      queryParams["from_date"] = from_date;
    }
    if (to_date) {
      queryParams["to_date"] = to_date;
    }
    if (category) {
      queryParams["category"] = category;
    }
    try {
      let { page, limit, ...apiQueryParams } = queryParams;
      setSearchParams(queryParams);
      const url = prepareURLEncodedParams(
        `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/reports/${page}/${limit}`,
        apiQueryParams
      );
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        let { data, ...rest } = resJson;
        data = addSerialNo(data, rest?.page, rest?.limit);
        setLoading(false);
        setGenerateResultFile(data);
        setPaginationDetails(rest);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handelFinalize = async (result_file_id, _id) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/finalize-report/${_id}`;

      let options = {
        method: "PATCH",
        headers: new Headers({
          content_Type: "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (resJson.success === true) {
        getGenerateReport({});
        setShowSuccessAlert(true);
        setSuccessMesssage(resJson.message);
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 1500);
      }
      if (response.status === 200 || response.status === 201) {
        getGenerateReport();
        setLoading(false);
      } else if (response.statusCode === 403) {
        handleLogout();
      } else if (response.status === 400) {
        setTimeout(() => {
          toast.error(resJson.message);
        }, 1500);

      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleViewSummaryReport = async (data) => {
    setLoading(true);
    if (!data) return;
    try {
      let response = await fetch(data);
      const blob = await response.blob();
      let url = window.URL.createObjectURL(
        new Blob([blob], { type: "application/pdf" })
      );
      const printWindow = window.open(url, "_blank");
      printWindow.document.close();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (result_file_id, _id, action) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${_id}/download`;
      let options = {
        method: "POST",
        headers: new Headers({
          content_Type: "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        if (action === "preview") {
          await handleViewSummaryReport(resJson.data);
        } else if (action === "download") {
          window.open(resJson.data);
          setSuccessMesssage(resJson.message);
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 1500);
        }
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (result_file_id, id) => {
    setOpenId(true);
    setId(id);
    setResultFileId(result_file_id);
  };

  const confirmDelete = async () => {
    setDeleteLoadingId(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${resultFileId}/delete-report/${id}`;
      let options = {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/jsion",
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setOpenId(false);
        setSuccessMesssage(resJson.message);
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          getGenerateReport({});
        }, 1500);
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteLoadingId(false);
    }
  };

  useEffect(() => {
    if (mount) {
      const debounce = setTimeout(() => {
        getGenerateReport({
          search_string: searchString ? searchString : "",
          page: 1,
        });
      }, 500);
      return () => clearTimeout(debounce);
    } else {
      getGenerateReport({});
      setMount(true);
    }
  }, [searchString]);

  const capturePageNum = (value) => {
    getGenerateReport({
      page: value,
    });
  };

  const captureRowPerItems = (value) => {
    getGenerateReport({
      page: searchParams.get("page"),
      limit: value,
    });
  };
  const handleSort = (column) => {

    if (column == sortBy && sortType == "desc") {
      setSortBy("");
      setSortType("");
      getGenerateReport({
        sort_by: "",
        sort_type: "",
      });
    }
    else {
      const newSortType = sortType === "asc" ? "desc" : "asc";
      setSortBy(column);
      setSortType(newSortType);
      getGenerateReport({
        sort_by: column,
        sort_type: newSortType,
      });
    }
  };

  const categoryNavigation = (data) => {
    if(data?.category == "PHARMACOGENOMICS"){
      navigate(
        `/grand-reports/${data?.result_file_id}/update-report-template/${data?._id}/pharmaco-genomics`
      );
    }else if(data?.category == "RISK DISORDERS"){
      navigate(
        `/grand-reports/${data?.result_file_id}/update-report-template/${data?._id}/risk-chronic-disorders`
      );
    }else if(data?.category == "NUTRIGENOMICS"){
      navigate(
        `/grand-reports/${data?.result_file_id}/update-report-template/${data?._id}/nutrigenomics`
      );
    }else {
      navigate(
        `/grand-reports/${data?.result_file_id}/update-report-template/${data?._id}`
      );
    }
  };


  return (
    <div>
      <section class="resultFilesContainer">
        <div>
          <div>
            <FilterOptions
              setSearchStaring={setSearchStaring}
              searchString={searchString}
              setStatus={setStatus}
              status={status}
              setFromDate={setFromDate}
              fromDate={fromDate}
              setToDate={setToDate}
              toDate={toDate}
              getGenerateReport={getGenerateReport}
              setSelectCategory={setSelectCategory}
              selectCategory={selectCategory}
            />
          </div>
        </div>
        <div className="customTable">
          <TableContainer sx={{ height: "70vh", overflowX: "auto" }}>
            <Table stickyHeader>
              <TableHead className="table-head">
                <TableRow>
                  <TableCell
                    className="custm-th"
                    sx={{
                      width: "60px",
                      minWidth: "40px",
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{
                      width: "130px",
                      minWidth: "100px",
                    }}
                    onClick={() => handleSort("barcode_no")}
                  >
                    <span className="headerWithSort">
                      Barcode
                      <SortImages value="barcode_no" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{
                      width: "100px",
                      minWidth: "80px",
                    }}
                    onClick={() => handleSort("visit_id")}
                  >
                    <span className="headerWithSort">
                      Visit No
                      <SortImages value="visit_id" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{
                      width: "200px",
                      minWidth: "170px",
                    }}
                    onClick={() => handleSort("name")}
                  >
                    <span className="headerWithSort">
                      Patint Name
                      <SortImages value="name" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{
                      width: "150px",
                      minWidth: "130px",
                    }}
                    onClick={() => handleSort("date_of_collection")}
                  >
                    <span className="headerWithSort">
                      Date Of Collection
                      <SortImages value="date_of_collection" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{
                      width: "150px",
                      minWidth: "130px",
                    }}
                    onClick={() => handleSort("date_of_receiving")}
                  >
                    <span className="headerWithSort">
                      Date Of Receiving
                      <SortImages value="date_of_receiving" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{
                      width: "150px",
                      minWidth: "130px",
                    }}
                    onClick={() => handleSort("date_of_reporting")}
                  >
                    <span className="headerWithSort">
                      Date Of Reporting
                      <SortImages value="date_of_reporting" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "150px", minWidth: "130px" }}
                    onClick={() => handleSort("test")}
                  >
                    <span className="headerWithSort">
                      Test
                      <SortImages value="test" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "120px", minWidth: "100px" }}
                    onClick={() => handleSort("report_status")}
                  >
                    <span className="headerWithSort">
                      Status
                      <SortImages value="report_status" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "120px", minWidth: "100px" }}
                    onClick={() => handleSort("category")}
                  >
                    <span className="headerWithSort">
                      Category
                      <SortImages value="category" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "150px", minWidth: "130px" }}
                    onClick={() => handleSort("created_at")}
                  >
                    <span className="headerWithSort">
                      Generated On
                      <SortImages value="created_at" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{
                      width: "220px",
                      minWidth: "220px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-group">
                {generateResultFile?.length ? (
                  generateResultFile.map((data, index) => {
                    let processcolor =
                      data?.report_status === "APPROVED"
                        ? "rgba(163, 190, 60, 0.20)"
                        : data?.report_status === "COMPLETED"
                          ? "rgba(255, 122, 0, 0.20)"
                          : "";
                    let statusColor =
                      data?.report_status === "APPROVED"
                        ? "#86A60D"
                        : data?.report_status === "COMPLETED"
                          ? "#FF7A00"
                          : "";
                    let statusText =
                      data?.report_status === "APPROVED"
                        ? "Approved"
                        : data?.report_status === "COMPLETED"
                          ? "Completed"
                          : "";

                    return (
                      <TableRow className="table-item" key={index}>
                        <TableCell className="data">{data?.serial}</TableCell>
                        <TableCell className="data">
                          {data?.patient_info?.barcode_no
                            ? data?.patient_info?.barcode_no
                            : "--"}
                        </TableCell>
                        <TableCell className="data">
                          {data?.patient_info?.visit_id
                            ? data?.patient_info?.visit_id
                            : "--"}
                        </TableCell>
                        <TableCell className="data">
                          {data?.patient_info?.name
                            ? data?.patient_info?.name
                            : "--"}
                        </TableCell>
                        <TableCell className="data">
                          {data?.patient_info?.date_of_collection
                            ? DateFormate(data?.patient_info?.date_of_collection)
                            : "--"}
                        </TableCell>
                        <TableCell className="data">
                          {data?.patient_info?.date_of_receiving
                            ? DateFormate(data?.patient_info?.date_of_receiving)
                            : "--"}
                        </TableCell>
                        <TableCell className="data">
                          {data?.patient_info?.date_of_reporting
                            ? DateFormate(data?.patient_info?.date_of_reporting)
                            : "--"}
                        </TableCell>
                        <TableCell className="data">{"DNA Decoder"}</TableCell>
                        <TableCell className="data statusActionButtonTd">
                          <Typography
                            className="statusActionButton"
                            sx={{
                              backgroundColor: processcolor,
                              color: statusColor,
                            }}
                          >
                            {statusText}
                          </Typography>
                        </TableCell>
                        <TableCell className="data">
                          {data?.category
                            ? data?.category
                            : "--"}
                        </TableCell>
                        <TableCell className="data">
                          {data?.patient_info?.created_at
                            ? DateTimeFormate(data?.created_at)
                            : "--"}
                        </TableCell>
                        <TableCell className="data">
                          <div
                            className="cellAction"
                            style={{
                              fontSize: "clamp(13px, 0.625vw, 1.5rem)",
                              width: "100%",
                              display: "flex",

                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <img
                              src="actionIcon/PreviewIcon.svg"
                              height={25}
                              width={25}
                              alt="privew"
                              title="Preview"
                              onClick={() => {
                                handleDownload(
                                  data?.result_file_id,
                                  data?._id,
                                  "preview"
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            {data?.report_status === "COMPLETED" || userType == "DOCTOR" ? (
                              <img
                                src="actionIcon/EditIcon.svg"
                                height={25}
                                width={25}
                                alt="edit"
                                title="Edit"
                                onClick={() => {
                                  categoryNavigation(data)
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <img
                                src="actionIcon/EditIcon-disabled.svg"
                                height={25}
                                width={25}
                                alt="edit"
                                style={{
                                  cursor: "not-allowed",
                                }}
                              />
                            )}

                            <img
                              src="actionIcon/downloadIcon.svg"
                              height={25}
                              width={25}
                              alt="download"
                              title="Download"
                              onClick={() => {
                                handleDownload(
                                  data?.result_file_id,
                                  data?._id,
                                  "download"
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            <img
                              src="actionIcon/DeleteIcon.svg"
                              height={25}
                              width={25}
                              alt="edit"
                              title="Delete"
                              onClick={() =>
                                deleteLoadingId
                                  ? ""
                                  : handleDelete(
                                    data?.result_file_id,
                                    data?._id
                                  )
                              }
                              style={{ cursor: "pointer" }}
                            />

                            {data?.report_status === "APPROVED" ? (
                              <Button
                                variant="contained"
                                className="generateFileBtn finaliseBtn"
                                sx={{ cursor: "not-allowed" }}
                              >
                                Finalized
                              </Button>
                            ) : (
                              <>
                                {userType == "DOCTOR" ? (
                                  <div
                                    className="viewProcessBtn finaliseBtn"
                                    onClick={() => {
                                      handelFinalize(
                                        data?.result_file_id,
                                        data?._id
                                      );
                                    }}
                                  >
                                    <span className="text">
                                      <span className="gradientText">Finalize</span>
                                    </span>
                                  </div>
                                ) : (
                                  <Tooltip title="You are not allowed to Finalize this report">
                                    <div
                                      className="viewProcessBtn finaliseBtn"
                                      style={{ cursor: "not-allowed" }}
                                    >
                                      <span className="text">
                                        <span className="gradientText">Finalize</span>
                                      </span>
                                    </div>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    {!loading ? (
                      <TableCell colSpan={10} style={{ textAlign: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <img
                            src="/no-data.svg"
                            height={350}
                            width={350}
                            alt="No data"
                          />
                          <p style={{ fontSize: "clamp(18px, 2.72vw, 22px)" }}>
                            No Data
                          </p>
                        </div>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="customTablePagination">
            <TablePaginationComponent
              paginationDetails={paginationDetails}
              capturePageNum={capturePageNum}
              captureRowPerItems={captureRowPerItems}
            />
          </div>
        </div>
        <Snackbar
          open={showSuccessAlert}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        </Snackbar>
        <DeleteDialog
          setOpenId={setOpenId}
          openId={openId}
          confirmDelete={confirmDelete}
          deleteLoadingId={deleteLoadingId}
          label="Are you sure you want to delete this Generate report?"
        />
        <LoadingComponent loading={loading} />
        <Toaster closeButton richColors position="top-right" />
      </section>

    </div>
  );
};
export default GeneratedReports;
