import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../../Core/LoadingComponent";
import styles from "../index.module.css";
import WeeklyDietPlannerTable from "./WeeklyDietPlannerTable";

const WeeklyDietPlanner = () => {
  const AuthStateData = useSelector((state) => state.Signin);
  const userType = useSelector((state) => state.Signin.user_details?.user_type);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { report_id, result_file_id } = useParams();
  const emptyarray = [
    { day: "monday", diet_plan: {} },
    { day: "tuesday", diet_plan: {} },
    { day: "wednesday", diet_plan: {} },
    { day: "thursday", diet_plan: {} },
    { day: "friday", diet_plan: {} },
    { day: "saturday", diet_plan: {} },
    { day: "sunday", diet_plan: {} },
  ]

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [resultFile, setResultFile] = useState({});
  const [weeklyDietPlanData, setWeeklyDietPlanData] = useState(emptyarray);
  const [isEditOpenOrNot, setIsEditOpenOrNot] = useState(false);
  const [processSuccess, setProcessSuccess] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [previewData, setPreviewData] = useState("");



  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const handleCancel = async () => {
    setIsEditOpenOrNot(false);
    setWeeklyDietPlanData(emptyarray);
  }

  const onEdit = async () => {
    setIsEditOpenOrNot(true);
    getweeklydietplandata();
  }


  const getPrimaryReportById = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        setResultFile(resJson.data ? resJson.data : {});
        if (resJson.data.weekly_diet_planner?.length) {
          setWeeklyDietPlanData(resJson.data.weekly_diet_planner || []);
        }
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}/add-diet-plan`;
      const payload = {
        weekly_diet_planner: weeklyDietPlanData,
      };
      let options = {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
          "content-type": "application/json",
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setProcessSuccess(resJson?.message);
        setShowSuccessAlert(true);
        setIsEditOpenOrNot(false);
        setWeeklyDietPlanData(weeklyDietPlanData); //SG
        setTimeout(() => {
          setProcessSuccess("");
          setShowSuccessAlert(false);
        }, 1500);
        getPreview();
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getweeklydietplandata = async () => {
    setIsEditOpenOrNot(true);
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        if (resJson.data.weekly_diet_planner?.length) {
          setWeeklyDietPlanData(resJson.data.weekly_diet_planner || []);
        }
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  const getPreview = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/preview-report/${report_id}?category=DIET_PLANNER`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resText = await response.text();
      if (response.status === 200 || response.status === 201) {
        setPreviewData(resText);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!AuthStateData?.access_token) {
      navigate("/");
    }
    getPrimaryReportById();
    getPreview();
  }, []);

  return (
    <div className={styles.detailscontainer} id="weeklyDietPlanner">
      <div className="editBtnGrp">
        <h6 className="headingText">Weekly Diet Planner</h6>
        {isEditOpenOrNot === false ? (
          <div>
            {!resultFile?.is_finalized === true || userType == "DOCTOR" ? (
              <Button
                disableElevation={true}
                className="editBtn"
                variant="outlined"
                startIcon={
                  <img
                    src="/edit-pink-icon.svg"
                    alt=""
                    width={12}
                    height={12}
                  />
                }
                onClick={onEdit}
              >
                Edit
              </Button>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {isEditOpenOrNot ? (
        <div className="processSummaryBlock">

          <WeeklyDietPlannerTable
            weeklyDietPlanData={weeklyDietPlanData}
            setWeeklyDietPlanData={setWeeklyDietPlanData}
            loading={loading}
            setLoading={setLoading}
          />

          <div className="summaryEditBtnGrp">
            <Button
              className="cancelBtn"
              variant="text"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="saveBtn"
              variant="contained"
              onClick={onContinue}
            >
              Update
            </Button>
          </div>
        </div>
      ) : (
        <div className="previewBlock">
          <div
            className="htmlPreview"
            style={{ width: "95%", margin: "0 auto" }}
            dangerouslySetInnerHTML={{ __html: previewData }}
          ></div>
        </div>
      )}
      <LoadingComponent loading={loading} />

      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {processSuccess}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default WeeklyDietPlanner;
