import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Grid, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../PeriodicTests/periodic.module.css";

const AddFoodInteraction = ({ setData, data, foodData }) => {

  const normalizeDrugName = (name) => name.toLowerCase();
  
  const capitalizeDrugName = (name) => 
    name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

  const initialDrugs =
    foodData && foodData.length > 0
      ? [...new Set(foodData.map((item) => capitalizeDrugName(item.drug)))]
      : [""];

  const [fields, setFields] = useState(initialDrugs);

  const handleAddField = () => {
    const updatedFields = [...fields, ""];
    setFields(updatedFields);
    setData(updatedFields);
  };

  const handleRemoveField = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
    setData(updatedFields.length > 0 ? updatedFields : []);
  };

  const handleInputChange = (index, value) => {
    const updatedFields = fields.map((field, i) =>
      i === index ? value : field
    );
    setFields(updatedFields);

    const nonEmptyFields = updatedFields.filter((field) => field.trim() !== "");
    setData(nonEmptyFields);
  };

  const renderFieldRows = () => {
    const rows = [];
    for (let i = 0; i < fields.length; i += 4) {
      rows.push(fields.slice(i, i + 4));
    }
    return rows;
  };

  useEffect(() => {
    setData(initialDrugs);
  }, []);

  return (
    <div className={styles.dietaryBlock} id="dietoryEditBlock">
      <div className="periodic-tests-edit">
        {renderFieldRows().map((row, rowIndex) => (
          <Grid
            container
            spacing={2}
            key={rowIndex}
            style={{ marginBottom: 10 }}
          >
            {row.map((field, fieldIndex) => (
              <Grid item xs={12} sm={6} md={3} key={fieldIndex}>
                <TextField
                  placeholder={`Food Interaction ${
                    rowIndex * 4 + fieldIndex + 1
                  }`}
                  size="small"
                  value={field}
                  onChange={(e) =>
                    handleInputChange(rowIndex * 4 + fieldIndex, e.target.value)
                  }
                  variant="outlined"
                  fullWidth
                />
                <IconButton
                  onClick={() => handleRemoveField(rowIndex * 4 + fieldIndex)}
                  disabled={fields.length === 1}
                >
                  <RemoveCircle />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        ))}
        <IconButton onClick={handleAddField} color="primary">
          <AddCircle />
        </IconButton>
      </div>
    </div>
  );
};

export default AddFoodInteraction;
