import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
} from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./PreviewContainer.module.css";

const PreviewContainer = ({
  summaryReportPreview,
  summaryReportId,
  addSummaryReport,
  handleDownload,
  loading,
  updateSummaryReport,
}) => {
  const { report_id } = useParams();
  const printContent = useRef(null);
  const navigate = useNavigate();
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);

  const printAsPdf = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(summaryReportPreview);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <section className={styles.previewcontainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h3 className={styles.summaryreportHeading}>Summary Report Preview</h3>
        {summaryReportPreview && report_id ? (
          <Button
            variant="outlined"
            className={styles.printButton}
            onClick={printAsPdf}
          >
            Print
          </Button>
        ) : (
          <div></div>
        )}
      </div>
      {summaryReportPreview ? (
        <div
          ref={printContent}
          dangerouslySetInnerHTML={{ __html: summaryReportPreview }}
        />
      ) : (
        "No Report has been generated"
      )}
      {summaryReportPreview ? (
        <div className={styles.buttongroup}>
          {report_id ? (
            <Button
              className={styles.printButton}
              variant="outlined"
              onClick={() => {
                handleDownload(report_id || summaryReportId);
              }}
            >
              {loading ? (
                <CircularProgress size="1.5rem" sx={{ color: "#ed8819" }} />
              ) : (
                "Download as pdf"
              )}
            </Button>
          ) : (
            <Button
              className={styles.printButton}
              variant="outlined"
              onClick={() => setCancelConfirmationOpen(true)}
            >
              Cancel
            </Button>
          )}
          <Button
            className={styles.saveButton}
            color="primary"
            variant="contained"
            sx={{ borderRadius: "0px 0px 0px 0px" }}
            onClick={() => {
              if (report_id) {
                updateSummaryReport();
              } else {
                addSummaryReport();
              }
            }}
          >
            {report_id ? "Update" : "Save"}
          </Button>
        </div>
      ) : (
        ""
      )}

      <Dialog 
      open={cancelConfirmationOpen} 
  
      PaperProps={{
        sx:{
          borderRadius:"0.7rem"
        }
      }}
      >
        <Card>
          <div
           className={styles.summaryWarningCard}
          >
            <img src={"/cancel-warning.svg"} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                className={styles.warningText}
              >
                Warning
              </p>
              <span style={{ color: "#898989", fontFamily:"'IBM Plex Sans',Sans-serif", fontSize:"16px" }}>
                These changes may not be saved
              </span>
            </div>
            <DialogActions
             className={styles.warningButtonComponent}
            >
              <Button className={styles.warningCancelButton}
                onClick={() => setCancelConfirmationOpen(false)}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  "& .MuiButton-root": {
                    color: "##ff583e",
                    borderColor: "#ff583e",
                  },
                }}
              >
                Cancel
              </Button>
              <Button className={styles.warningConfirmButton}
                onClick={() => navigate(-1)}
                variant="contained"
                sx={{
                  textTransform: "none",
                  "& .MuiButton-root": {
                    color: "#fff",
                    background: "#006699",
                  },
                }}
              >
                Confirm!
              </Button>
            </DialogActions>
          </div>
        </Card>
      </Dialog>
    </section>
  );
};

export default PreviewContainer;
