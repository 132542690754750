import { RemoveCircle } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./dietary.module.css";
import DietaryGuidelinesDialog from "./DietaryGuidelinesDialog";
import { useState } from "react";

const DietaryGuidelinesTable = ({
  handleClose,
  open,
  foodsToAvoid,
  setFoodsToAvoid,
  foodsToInclude,
  setFoodsToInclude,
  dataLength,
  setDataLength,
  setFoodsAvoid,
  foodsAvoid,
  setFoodsInclude,
  foodsInclude
}) => {
  const onChangeTextField = (e, index) => {
    const { value, name } = e.target;
    if (name === "foods_to_avoid") {
      const avoidFood = [...foodsToAvoid];
      avoidFood[index] = value.replace(/^\s+/, '');
      setFoodsToAvoid(avoidFood);
      setFoodsAvoid(avoidFood.filter(e => e));
    }
    if (name === "foods_to_include") {
      const includeFood = [...foodsToInclude];
      includeFood[index] = value.replace(/^\s+/, '');
      setFoodsToInclude(includeFood);
      setFoodsInclude(includeFood.filter(e => e));
    }
  };

  const onAddExtraRow = () => {
    let include = foodsToInclude.filter(e => e)
    let avoid = foodsToAvoid.filter(e => e)
    setFoodsToAvoid(avoid);
    setFoodsToInclude(include);
    setFoodsAvoid(avoid);
    setFoodsInclude(include);
    setDataLength((prev) => prev + 1);
  };

  const removeItem = (index) => {
    const tempFoodsAvoid = [...foodsToAvoid];
    const tempFoodsInclude = [...foodsToInclude];
    tempFoodsAvoid.splice(index, 1);
    tempFoodsInclude.splice(index, 1);
    setFoodsToAvoid(tempFoodsAvoid);
    setFoodsToInclude(tempFoodsInclude);
    setFoodsAvoid(tempFoodsAvoid);
    setFoodsInclude(tempFoodsInclude);
    setDataLength((prev) => prev - 1);
  };

  return (
    <div className={styles.dietaryBlock} id="dietoryEditBlock">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
              S No
            </TableCell>
            <TableCell>Foods Avoid</TableCell>
            <TableCell>Foods Include</TableCell>
            <TableCell sx={{ width: "60px", minWidth: "60px", maxWidth: "60px" }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: dataLength }, (_, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <TextField
                  className="inputField"
                  value={foodsToAvoid[index] || ""}
                  name={"foods_to_avoid"}
                  onChange={(e) => onChangeTextField(e, index)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  className="inputField"
                  value={foodsToInclude[index] || ""}
                  name={"foods_to_include"}
                  onChange={(e) => onChangeTextField(e, index)}
                />
              </TableCell>
              <TableCell>
                <Tooltip title='Remove'>
                  <IconButton onClick={() => removeItem(index)}>
                    <RemoveCircle sx={{ color: "#f35a3e" }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={4}>
              <Button onClick={onAddExtraRow}>Add More</Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <DietaryGuidelinesDialog
        handleClose={handleClose}
        open={open}
        foodsToAvoid={foodsToAvoid}
        setFoodsToAvoid={setFoodsToAvoid}
        foodsToInclude={foodsToInclude}
        setFoodsToInclude={setFoodsToInclude}
        setDataLength={setDataLength}
        dataLength={dataLength}
        setFoodsAvoid={setFoodsAvoid}
        foodsAvoid={foodsAvoid}
        setFoodsInclude={setFoodsInclude}
        foodsInclude={foodsInclude}
      />
    </div>
  );
};

export default DietaryGuidelinesTable;
