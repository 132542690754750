import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const BaseLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const userType = useSelector((state) => state.Signin.user_details);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    localStorage.clear();
    navigate("/");
  };
  const getProfile = () => {
    navigate("/ViewProfile");
  };
  const handleUpdate = () => {
    navigate("/UpdatePassword");
  };

  return (
    <>
      {location.pathname !== "/patient-reports" ? (
        <nav id="navbar">
          <div className="container-fluid-navbar">
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <a href="/">
                  <img src="/login/login-logo.svg" alt="Yoda Logo" width="80%" />
                </a>
              </Grid>

              <Grid item xs={9}>
                <ul className="navMenu">
                  <li
                    className={
                      location.pathname == "/grand-reports"
                        ? "navLinkActive"
                        : ""
                    }
                  >
                    {location.pathname === "/grand-reports" ? (
                      <Tooltip title="You are in Result Files mode">
                        <IconButton href="/grand-reports">
                          Result Files
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Go to Result Files">
                        <IconButton href="/grand-reports">
                          {" "}
                          Result Files
                        </IconButton>
                      </Tooltip>
                    )}
                  </li>
                  <li
                    className={
                      location.pathname == "/generated-reports"
                        ? "navLinkActive"
                        : ""
                    }
                  >
                    {location.pathname === "/generated-reports" ? (
                      <Tooltip title="You are in Generated Reports mode">
                        <IconButton href="/generated-reports">
                          Generated Reports
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Go to Generated Reports">
                        <IconButton href="/generated-reports">
                          Generated Reports
                        </IconButton>
                      </Tooltip>
                    )}
                  </li>
                  {/* <li className={location.pathname == '/risk-disorders' ? "navLinkActive" : ""}>
                    {location.pathname === "/risk-disorders" ? (
                      <Tooltip title="You are in Risk Disorders Result Files mode">
                        <IconButton href="/risk-disorders">
                          Risk Disorders Result Files
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Go to Risk Disorders Result Files">
                        <IconButton href="/risk-disorders">Risk Disorders Result Files</IconButton>
                      </Tooltip>
                    )}
                  </li> */}
                  <li
                    className={
                      location.pathname == "/sumamry-reports"
                        ? "navLinkActive"
                        : ""
                    }
                  >
                    {location.pathname === "/sumamry-reports" ? (
                      <Tooltip title="You are in Summary Reports mode">
                        <IconButton href="/sumamry-reports">
                          Summary Reports
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Go to Summary Reports">
                        <IconButton href="/sumamry-reports">
                          Summary Reports
                        </IconButton>
                      </Tooltip>
                    )}
                  </li>
                  {/* <li className={location.pathname == '/createform' ? "navLinkActive" : ""}>
                    {location.pathname === "/createform" ? (
                      <Tooltip title="You are in Create Form mode">
                        <IconButton href="/createform">Create</IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Go to Create Form">
                        <IconButton href="/createform">Create</IconButton>
                      </Tooltip>
                    )}
                  </li> */}
                  {/* <li className={location.pathname == '/result-file' ? "navLinkActive" : ""}>
                    {location.pathname === "/result-file" ? (
                      <Tooltip title="You are in Result Files mode">
                        <IconButton href="/result-file">Result Files</IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Go to Result Files">
                        <IconButton href="/result-file">Result Files</IconButton>
                      </Tooltip>
                    )}
                  </li> */}
                  <li className={location.pathname == '/genotype-phenotype' ? "navLinkActive" : ""}>
                    {location.pathname === "/genotype-phenotype" ? (
                      <Tooltip title="You are in G-P mode">
                        <IconButton
                          href="/genotype-phenotype"
                          aria-label="Example"
                        >
                          <ChangeCircleOutlinedIcon />
                          G-P
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Go to G-P">
                        <IconButton
                          href="/genotype-phenotype"
                          aria-label="Example"
                        >
                          <ChangeCircleOutlinedIcon />
                          G-P
                        </IconButton>
                      </Tooltip>
                    )}
                  </li>
                  <li className="loginDropdown">
                    <Tooltip title="Open settings">
                      <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
                        {/* <Avatar
                          
                         
                          src="/static/images/avatar/2.jpg"
                        /> */}
                        <Avatar
                          sx={{
                            color: "#fff",
                            backgroundColor: "#b03877",
                            width: 35,
                            height: 35,
                          }}
                          sizes="small"
                          alt="Admin"
                        >
                          {(userType?.first_name?.[0].toUpperCase() || '') + (userType?.last_name?.[0].toUpperCase() || '') || "--"}
                        </Avatar>
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem onClick={() => {
                        getProfile();
                        handleCloseUserMenu();
                      }}>
                        <Grid>
                          <Grid item xs={12}>
                            <Typography textAlign="center">
                              View Profile
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>

                      <MenuItem onClick={() => {
                        handleUpdate();
                        handleCloseUserMenu();
                      }}>
                        <Grid>
                          <Grid item xs={12}>
                            <Typography textAlign="center">
                              Update Password
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>

                      <MenuItem onClick={() => {
                        handleLogout();
                        handleCloseUserMenu();
                      }}>
                        <Grid>
                          <Grid item xs={12}>
                            <Typography textAlign="center">Logout</Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    </Menu>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </div>
        </nav>
      ) : (
        ""
      )}
      <div
        className={
          location.pathname !== "/patient-reports"
            ? "primaryDashboard"
            : "patientDashboard"
        }
      >
        <Outlet />
      </div>
    </>
  );
};

export default BaseLayout;
