import { Alert, AlertTitle, Backdrop, Button, CircularProgress, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";


const SingleRiskDisoderResultFile = () => {
    const AuthStateData = useSelector((state) => state.Signin);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { _id, report_id } = useParams();

    const [loading, setLoading] = useState("");
    const [data, setData] = useState(null);
    const [finalize, setFinalize] = useState("");
    const [successMessage, setSuccessMesssage] = useState();
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const handleLogout = async () => {
        dispatch({
            type: "SIGN_OUT",
            payload: {},
        });
        navigate("/");
    };


    const getOneResultFile = async () => {
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/risk-disorders/result-file/${_id}/report/${report_id}`;
            let options = {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.access_token}`,
                }),
            };
            let response = await fetch(url, options);
            let resJson = await response.json();

            if (response.status === 200 || response.status === 201) {
                setLoading(false);
                setData(resJson.data);
                setFinalize(resJson.data.is_finalized == true)
            } else if (response.statusCode === 403) {
                handleLogout();
            }
        } catch (err) {
            setLoading(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    const handelFinalize = async () => {
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/risk-disorders/result_file/${_id}/report/${report_id}/finalize`;
            let options = {
                method: "PATCH",
                headers: new Headers({
                    content_Type: "application/json",
                    authorization: `${AuthStateData.access_token}`,
                }),
            };
            let response = await fetch(url, options);
            let resJson = await response.json();
            setSuccessMesssage(resJson.message);
            if (resJson.success === true) {
                setShowSuccessAlert(true);
                setTimeout(() => {
                    setShowSuccessAlert(false);
                }, 1500);
            }
            if (response.status === 200 || response.status === 201) {
                setLoading(false);
                setFinalize(resJson.success);
                getOneResultFile()
            } else if (response.statusCode === 403) {
                handleLogout();
            }
        } catch (err) {
            setLoading(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = async () => {
        setLoading(true);
        try {
            let url =
                `${process.env.REACT_APP_API_BASE_URL}/risk-disorders/download/` + report_id;
            let options = {
                method: "POST",
                headers: new Headers({
                    content_Type: "application/json",
                    authorization: `${AuthStateData.access_token}`,
                }),
            };
            let response = await fetch(url, options);
            let resJson = await response.json();
            setSuccessMesssage(resJson.message);
            if (resJson.success === true) {
                setShowSuccessAlert(true);
                setTimeout(() => {
                    setShowSuccessAlert(false);
                }, 1500);
            }
            if (response.status === 200 || response.status === 201) {
                setLoading(false);

                window.open(resJson.data);
            } else if (response.statusCode === 403) {
                handleLogout();
            }
        } catch (err) {
            setLoading(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!AuthStateData.access_token) {
            navigate("/");
        }
        getOneResultFile();
    }, []);

    return (
        <div id="resultDetails" className="resultFilesContainer">
            <Typography variant="body1" className="navbarNavigation">
                <Link to={`/risk-disorders`}>All Risk Disorders Result Files </Link> / {data?.barcode}
            </Typography>
            <div className="customTable">
                <Table>
                    <TableHead className="table-head">
                        <TableRow>
                            <TableCell className="custm-th">Barcode</TableCell>
                            <TableCell className="custm-th" style={{ width: "130px" }}>
                                Status
                            </TableCell>
                            <TableCell className="custm-th" style={{ width: "250px" }}>
                                Message
                            </TableCell>
                            <TableCell
                                className="custm-th"
                                style={{ width: "180px", textAlign: "center" }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="table-group">
                        <TableRow className="table-item">
                            <TableCell className="data">
                                {data?.barcode}

                            </TableCell>
                            <TableCell className="data" sx={{ color: "#2a9d8f" }}>
                                {data?.report_status}

                            </TableCell>
                            <TableCell className="data">
                                {data?.message ? data?.message : '--'}

                            </TableCell>
                            <TableCell className="data action-data">
                                <ul className="actionList">
                                    <li>
                                        <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                navigate(
                                                    `/risk-disorder/${_id}/preview-report/${report_id}/preview`
                                                );
                                            }}
                                        >
                                            <img
                                                src="/preview.svg"
                                                alt="icon"
                                                className="img-fluid"
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            style={{ cursor: "pointer" }}

                                            onClick={() => {
                                                navigate(
                                                    `/risk-disorders/${_id}/report/${report_id}/edit`
                                                );
                                            }}
                                        >
                                            <img
                                                src="/EditIcon.svg"
                                                alt="icon"
                                                className="img-fluid"
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            style={{ cursor: "pointer" }}

                                            onClick={handleDownload}
                                        >
                                            <img
                                                src="/downloadd.svg"
                                                alt="icon"
                                                className="img-fluid"
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        {finalize ? (
                                            <Button
                                                className="finalize-btn"
                                                variant="contained"
                                                size="small"
                                                disabled
                                            >
                                                Finalized
                                            </Button>
                                        ) : (
                                            <Button
                                                className="finalize-btn"
                                                variant="contained"
                                                size="small"
                                                onClick={handelFinalize}
                                            >
                                                Finalize
                                            </Button>
                                        )}
                                    </li>
                                </ul>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            <Snackbar
                open={showSuccessAlert}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
export default SingleRiskDisoderResultFile;