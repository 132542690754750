import styles from "./MainPage.module.css";

const FooterOfPatientReports = () => {
    return (
        <div className={styles.container1}>
            <div className={styles.logo}>
                <img
                    className={styles.logofooterIcon}
                    alt=""
                    src="/logofooter@2x.png"
                />
                <p className={styles.tagline1}>
                    TRANSFORMING LIVES THROUGH DNA DECODING
                </p>
            </div>
            <div className={styles.contactdetails}>
                <div className={styles.support}>
                    <label className={styles.lable}>Support</label>
                    <div className={styles.detailswrapper}>
                        <div className={styles.mobile}>
                            <img
                                className={styles.phoneicon}
                                alt=""
                                src="/phoneicon.svg"
                            />
                            <p className={styles.phonenumber}>040 35353535</p>
                        </div>
                        <div className={styles.mobile}>
                            <img
                                className={styles.phoneicon}
                                alt=""
                                src="/mailicon.svg"
                            />
                            <p className={styles.phonenumber}>@YODADNADECODER.COM</p>
                        </div>
                    </div>
                </div>
                <div className={styles.support}>
                    <label className={styles.lable}>Address</label>
                    <div className={styles.addresscontainer}>
                        <img
                            className={styles.phoneicon}
                            alt=""
                            src="/locationicon.svg"
                        />
                        <p className={styles.address}>
                            <span
                                className={styles.lalBungalowAdd}
                            >{`LAL BUNGALOW ADD ON, AMEERPET, `}</span>
                            <span className={styles.lalBungalowAdd}>
                                HYDERABAD - 500016
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default FooterOfPatientReports;