import { Card, MenuItem, Pagination, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import { useSearchParams } from "react-router-dom";

const TablePaginationComponent = ({
  paginationDetails,
  capturePageNum,
  captureRowPerItems,
  limitOptionsFromProps,
  limitValue,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageValue, setPageValue] = useState(paginationDetails?.page);

  const handlePagerowChange = (event) => {
    captureRowPerItems(event.target.value);
  };
  useEffect(() => {
    setPageValue(paginationDetails?.page);
  }, [paginationDetails]);

  const [limitOptions, setLimitOptions] = useState([]);

  useEffect(() => {
    setLimitOptions(
      limitOptionsFromProps?.length
        ? limitOptionsFromProps
        : [
            { title: "20/page", value: 20 },
            { title: "40/page", value: 40 },
            { title: "60/page", value: 60 },
            { title: "80/page", value: 80 },
            { title: "100/page", value: 100 },
          ]
    );
  }, []);

  return (
    <Card className="PaginationCard">
      <Pagination
        shape="rounded"
        className="pageNumber"
        page={+paginationDetails?.page ? +paginationDetails?.page : 1}
        count={
          +paginationDetails?.total_pages ? +paginationDetails?.total_pages : 1
        }
        onChange={(_, value) => {
          capturePageNum(value);
        }}
      />

      <div className="paginationGroup">
        <Typography className="paginationText">Go to</Typography>
        <TextField
          className="gotoField"
          inputProps={{ min: 0, style: { textAlign: "center" } }}
          type="number"
          value={pageValue}
          onChange={(e) => setPageValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              if (pageValue <= 0) {
                capturePageNum(1);
              } else if (pageValue >= paginationDetails?.total_pages) {
                capturePageNum(paginationDetails?.total_pages);
              } else {
                capturePageNum(pageValue);
              }
            }
          }}
          onWheel={(e) => e.target.blur()}
        ></TextField>

        <Select
          className="rowPerPageSelect"
          value={
            paginationDetails?.limit ||
            limitValue ||
            searchParams.get("limit") ||
            20
          }
          onChange={handlePagerowChange}
        >
          {limitOptions.map((item, index) => (
            <MenuItem className="menuItem" value={item.value} key={index}>
              {item.title}
            </MenuItem>
          ))}
        </Select>

        <Typography className="paginationText">
          Total {paginationDetails?.total}
        </Typography>
      </div>
    </Card>
  );
};
export default TablePaginationComponent;
