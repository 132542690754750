import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Grid,
  FormControl,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function EditReport(props) {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(null);
  const [newvalue, setNewValue] = React.useState(null);
  const [datesvalue, setDatesValue] = React.useState(null);
  const [datevalue, setDateValue] = React.useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setformData] = useState({});
  const [rows, setRowData] = useState([]);
  let token = localStorage.getItem("code");

  const [patientInfo, setPatientInfo] = useState({
    visit_id: "",
    name: "",
    age: "",
    gender: "",
    uhid_number: "",
    test: "",
    client_name: "",
    client_add: "",
    ref_doctor: "",
    sample_type: "",
    date_of_regestration: "",
    date_of_collection: "",
    date_of_receiving: "",
    date_of_reporting: "",
    hospital_name: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/medicines/data/636a2c69ad5df7f3bf3d532d`;
    let options = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        authorization: token,
      }),
    };
    let getData = await fetch(url, options);
    getData = await getData.json();

    setPatientInfo(getData.data.patient_info);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      setShowLoading(true);
      setOpen(true);

      let url = `${process.env.REACT_APP_API_BASE_URL}/medicines/data/639c007012a2e84c32920adc`;
      let options = {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: token,
        }),
        body: JSON.stringify({
          form: formData,
          patient_info: patientInfo,
        }),
      };
      let res = await fetch(url, options);
      let resJson = await res.json();
      if (res.status === 200 || res.status === 201) {
        setMessage("");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      setShowLoading(false);
      setOpen(false);
      console.error(err);
    }
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleDateChange = (newdateValue) => {
    setDateValue(newdateValue);
  };

  const handledateChange = (newDateValue) => {
    setNewValue(newDateValue);
  };

  const handlevalueChange = (value) => {
    setDatesValue(value);
  };

  const updatePatientsValue = (param, value) => {
    patientInfo[param] = value;
    setPatientInfo(patientInfo);
  };

  return (
    <section>
      <div className="container-fluid">
        <div className="patientInformation">
          <h4 className="blockTitle">Patient Information</h4>
        </div>
      </div>
      <form
        onSubmit={handleUpdate}
        sx={{ width: "100ch", marginTop: "2em" }}
        variant="outlined"
      >
        <Grid container spacing={4}>
          <Grid
            className="form-block"
            item
            xs={6}
            style={{ alignItems: "center" }}
          >
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Visit Id</Typography>
              <TextField
                className="text-field"
                sx={{ width: "30ch" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  updatePatientsValue("visit_id", e.target.value);
                }}
              />
              {/* <p className="details">{props.patient_info.visit_id}</p> */}
            </FormControl>
          </Grid>
          <Grid
            className="form-block"
            item
            xs={6}
            style={{ alignItems: "center" }}
          >
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Name</Typography>
              <TextField
                className="text-field"
                sx={{ width: "30ch" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  updatePatientsValue("name", e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            className="form-block"
            item
            xs={6}
            style={{ alignItems: "center" }}
          >
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Age</Typography>
              <TextField
                className="text-field"
                sx={{ width: "30ch" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  updatePatientsValue("age", e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            className="form-block"
            item
            xs={6}
            style={{ alignItems: "center" }}
          >
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Gender</Typography>
              <TextField
                className="text-field"
                sx={{ width: "30ch" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  updatePatientsValue("gender", e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            className="form-block"
            item
            xs={6}
            style={{ alignItems: "center" }}
          >
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">UHID Number</Typography>
              <TextField
                className="text-field"
                sx={{ width: "30ch" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  updatePatientsValue("uhid_number", e.target.value);
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            className="form-block"
            item
            xs={6}
            style={{ alignItems: "center" }}
          >
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Test</Typography>
              <TextField
                className="text-field"
                sx={{ width: "30ch" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  updatePatientsValue("test", e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            className="form-block"
            item
            xs={6}
            style={{ alignItems: "center" }}
          >
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Client Name</Typography>
              <TextField
                className="text-field"
                sx={{ width: "30ch" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  updatePatientsValue("client_name", e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            className="form-block"
            item
            xs={6}
            style={{ alignItems: "center" }}
          >
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Client Add</Typography>
              <TextField
                className="text-field"
                sx={{ width: "30ch" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  updatePatientsValue("client_add", e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            className="form-block"
            item
            xs={6}
            style={{ alignItems: "center" }}
          >
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Ref Doctor</Typography>
              <TextField
                className="text-field"
                sx={{ width: "30ch" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  updatePatientsValue("ref_doctor", e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            className="form-block"
            item
            xs={6}
            style={{ alignItems: "center" }}
          >
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Sample Type</Typography>
              <TextField
                className="text-field"
                sx={{ width: "30ch" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  updatePatientsValue("sample_type", e.target.value);
                }}
              />
            </FormControl>
          </Grid>

          <Grid className="form-block" item xs={6}>
            <FormControl className="form-inner">
              <Typography className="heading">Date of Regestration</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  className="text-field"
                  spacing={3}
                  sx={{ width: "30ch" }}
                >
                  <DesktopDatePicker
                    inputFormat="MM/DD/YYYY"
                    value={value}
                    disablePast="true"
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={6}>
            <FormControl className="form-inner">
              <Typography className="heading">Date of Collection</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  className="text-field"
                  spacing={3}
                  sx={{ width: "30ch" }}
                >
                  <DesktopDatePicker
                    inputFormat="MM/DD/YYYY"
                    value={datevalue}
                    disablePast="true"
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={6}>
            <FormControl className="form-inner">
              <Typography className="heading">Date of Receiving</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  className="text-field"
                  spacing={3}
                  sx={{ width: "30ch" }}
                >
                  <DesktopDatePicker
                    inputFormat="MM/DD/YYYY"
                    value={newvalue}
                    disablePast="true"
                    onChange={handledateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={6}>
            <FormControl className="form-inner">
              <Typography className="heading">Date of Reporting</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  className="text-field"
                  spacing={3}
                  sx={{ width: "30ch" }}
                >
                  <DesktopDatePicker
                    inputFormat="MM/DD/YYYY"
                    value={datesvalue}
                    disablePast="true"
                    onChange={handlevalueChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid
            className="form-block"
            item
            xs={6}
            style={{ alignItems: "center" }}
          >
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Hospital Name</Typography>
              <TextField
                className="text-field"
                sx={{ width: "30ch" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  updatePatientsValue("hospital_name", e.target.value);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div class="d-block">
          <Button
            variant="contained"
            type="submit"
            style={{ float: "right", marginRight: "200px" }}
          >
            Update
          </Button>
        </div>
      </form>
    </section>
  );
}

export default EditReport;
