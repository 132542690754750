import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Button from "@mui/material/Button";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import "../scss/ResultDetails.scss";
import BaseLayout from "../../layouts/BaseLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@mui/material";

const ResultTemplatePreview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AuthStateData = useSelector((state) => state.Signin);

  const [previewdata, setPreviewData] = useState();
  const [barcode, setBarCode] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [loadingResult, setLoadingResult] = useState(false);
  const [filename, setFileName] = useState();
  const [name, setName] = useState("");
  const [finalize, setFinalize] = useState("");
  const [successMessage, setSuccessMesssage] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  let token = localStorage.getItem("code");
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category");

  const { _id, report_id } = useParams();

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };
  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
    getDetails();
    handelProcessFile();
  }, []);

  const getDetails = async () => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-file/${_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();

      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        if (resJson.data) {
          setFileName(resJson.data.file_name);
        }
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
      setLoadingResult(false);
    } finally {
      setLoadingResult(false);
    }
  };
  const handelProcessFile = async () => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-file/${_id}/report/${report_id}`;
      if (category === "Nutrigenomics") {
        url = `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/result-file/${_id}/report/${report_id}`;
      }
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();

      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        if (resJson.data) {
          setBarCode(resJson.data.patient_info["barcode_no"]);
          setName(resJson.data.patient_info["name"]);
        }
        if (resJson.data.is_finalized == true) {
          setFinalize(resJson.data.is_finalized);
        }
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoadingResult(false);
      console.error(err);
    } finally {
      setLoadingResult(false);
    }
  };

  const handlePreview = async () => {
    setShowLoading(true);
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-file/${_id}/preview-report/${report_id}`;
      if (category === "Nutrigenomics") {
        url = `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/result-file/${_id}/preview-report/${report_id}`;
      }
      let options = {
        method: "GET",
        headers: new Headers({
          content_Type: "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      var template = await response.text();
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        setPreviewData(template);
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoadingResult(false);
      console.error(err);
    } finally {
      setShowLoading(false);
      setLoadingResult(false);
    }
  };
  useEffect(() => {
    handlePreview();
  }, []);
  const handelFinalize = async () => {
    setLoadingResult(true);
    setShowLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-file/${_id}/report/${report_id}/finalize`;
      if (category === "Nutrigenomics") {
        url = `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/result-file/${_id}/report/${report_id}/finalize`;
      }
      let options = {
        method: "PATCH",
        headers: new Headers({
          content_Type: "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      setSuccessMesssage(resJson.message);
      if (resJson.success === true) {
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 1500);
      }
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        handlePreview();

        setFinalize(resJson.success);
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setShowLoading(false);
      setLoadingResult(false);
      console.error(err);
    } finally {
      setShowLoading(false);
      setLoadingResult(false);
    }
  };

  const getPrint = () => {
    window.frames["report"].focus();
    document.getElementById("report").contentWindow.print();
  };
  const handleDownload = async () => {
    setLoadingResult(true);
    setShowLoading(true);
    try {
      let url =
        `${process.env.REACT_APP_API_BASE_URL}/medicines/download/` + report_id;
      if (category === "Nutrigenomics") {
        url =
          `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/download/` +
          report_id;
      }
      let options = {
        method: "POST",
        headers: new Headers({
          content_Type: "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      setSuccessMesssage(resJson.message);
      if (resJson.success === true) {
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 1500);
      }
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);

        window.open(resJson.data);
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setShowLoading(false);
      setLoadingResult(false);
      console.error(err);
    } finally {
      setShowLoading(false);
      setLoadingResult(false);
    }
  };

  return (
    <div>
      <div style={{ marginTop: "10px" }}>
        <div className="reportViewNavbar">
          <div className="inside">
            <ul>
              <li>
                <Button
                  className="print-btn"
                  size="small"
                  onClick={() => getPrint()}
                >
                  <LocalPrintshopIcon />
                  Print
                </Button>
              </li>
              <li>
                <Button
                  className="edit-button"
                  size="small"
                  onClick={() => {
                    const editUrl = category
                      ? `/result-file/${_id}/preview-report/${report_id}/edit?category=${category}`
                      : `/result-file/${_id}/preview-report/${report_id}/edit`;
                    navigate(editUrl);
                  }}
                >
                  <ModeEditIcon />
                  Edit
                </Button>
              </li>
              <li>
                {finalize ? (
                  <Button
                    className="finalize-disable"
                    size="small"
                    variant="contained"
                    disabled
                  >
                    Finalized
                  </Button>
                ) : (
                  <Button
                    className="finalize-button"
                    size="small"
                    onClick={() => {
                      handelFinalize();
                    }}
                  >
                    Finalize
                  </Button>
                )}
              </li>
              <li>
                <Button
                  className="download-btn"
                  size="small"
                  onClick={(file) => {
                    handleDownload();
                  }}
                >
                  Download
                </Button>
              </li>
              <li>
                <Button
                  className="back-button"
                  size="small"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon />
                  Back
                </Button>
              </li>
            </ul>
          </div>
        </div>
        <div style={{ marginTop: "55px" }}>
          <iframe
            width="100%"
            id="report"
            height="1100"
            frameBorder="0"
            srcDoc={previewdata}
          >
            {" "}
          </iframe>
        </div>
      </div>
      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={showLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingResult}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ResultTemplatePreview;
