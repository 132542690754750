import react, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { width } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import "../components/ViewProfile.css";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "./Core/LoadingComponent";

const bull = (
  <Box
    component="span"
    sx={{ dispaly: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    .
  </Box>
);

function ViewProfile() {
  const AuthStateData = useSelector((state) => state.Signin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const[userdata, setUserdata] = useState([]);
  const [loading, setLoading] = useState(false);
  let token = localStorage.getItem("code");

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
    getProfile();
  }, []);
  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const getProfile = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/users/profile`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      setUserdata(resJson.data);
      if (response.statusCode === 403) {
        handleLogout();
      }
      if (response.statusCode === 401) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getParameter = () => {
    navigate("/grand-reports");
  };
  return (
    <div id="viewProfile">
      <div className="container-fluid">
        <div class="backNavigation">
          <ul>
            <li>
              <Button
                onClick={getParameter}
                variant="outlined"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </li>
            <li>
              <Link to="/EditProfile">
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<EditIcon />}
                >
                  Edit
                </Button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="viewProfileCard">
          <h4 className="blockHeading">View Profile</h4>
          {(userdata) ?
          <div className="blockBody">
            <Grid container>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">First Name</p>
                  <p className="value">{userdata?.first_name}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">Last Name</p>
                  <p className="value">{userdata?.last_name}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">Signature</p>
                  <p className="value">{userdata?.signature_name}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">Gender</p>
                  <p className="value">{userdata?.gender}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">Username</p>
                  <p className="value">{userdata?.user_name}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">Email Address</p>
                  <p className="value">{userdata?.email}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">Phone Number</p>
                  <p className="value">{userdata?.phone_number}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">City</p>
                  <p className="value">{userdata?.address?.city}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">State</p>
                  <p className="value">{userdata?.address?.state}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">Zip</p>
                  <p className="value">{userdata?.address?.pincode}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">Designation</p>
                  <p className="value">{userdata?.desgination}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">Regd No</p>
                  <p className="value">{userdata?.regd_no}</p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <div className="formGroup">
                  <p className="name">Digital Signature</p>
                  <img src={userdata?.digital_signature} style={{width: '100px' , height:'70px'}}/>
                </div>
              </Grid>
            </Grid>
          </div>
           : "" }
        </div>
      </div>
      <LoadingComponent loading={loading} />
    </div>
  );
}
export default ViewProfile;
