import { Card, MenuItem, Pagination, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./table-pagination.module.css";

const TablePaginationComponent = ({
  paginationDetails,
  capturePageNum,
  captureRowPerItems,
  values,
}) => {
  const [pageNum, setPageNum] = useState();
  const [noOfRows, setNoOfRows] = useState(paginationDetails?.limit);

  useEffect(() => {
    setNoOfRows(paginationDetails?.limit);
  }, [paginationDetails]);

  const handlePagerowChange = (event) => {
    setNoOfRows(event.target.value);
    captureRowPerItems(event.target.value);
    setPageNum(1);
  };

  const [limitOptions] = useState([20, 40, 60, 80, 100]);

  return (
    <Card className={styles.tablePagenationBlock}>
      <div className={styles.tablePagination}>
        <div>
          <Typography variant="caption" className={styles.label}>
            {values} Per Page
          </Typography>

          <Select
            value={noOfRows}
            onChange={handlePagerowChange}
            sx={{
              height: "25px !important",
              borderRadius: "3px !important",
              fontSize: "11px",
              border: "none",
            }}
          >
            {limitOptions.map((item) => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div>
          <Typography variant="caption" className={styles.label}>
            {" "}
            {(paginationDetails?.page === 1
              ? 1
              : (paginationDetails?.page - 1) * paginationDetails?.limit + 1) +
              " - " +
              (paginationDetails?.page === paginationDetails?.total_pages
                ? paginationDetails?.total
                : paginationDetails?.total < paginationDetails?.limit
                ? paginationDetails?.total
                : paginationDetails?.page * paginationDetails?.limit)}{" "}
            of {paginationDetails?.total} {values}
          </Typography>
        </div>
        <div>
          <Pagination
            className="paginationSummary"
            shape="rounded"
            page={paginationDetails?.page}
            count={paginationDetails?.total_pages}
            onChange={(event, value) => {
              capturePageNum(value);
              setPageNum(+value);
            }}
          />
        </div>
      </div>
    </Card>
  );
};
export default TablePaginationComponent;
