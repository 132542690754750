import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import GenoCYP1A2 from "./genotophenotype/genoCYP1A2";
import GenoCYP2B6 from "./genotophenotype/genoCYP2B6";
import GenoCYP2C19 from "./genotophenotype/genoCYP2C19";
import GenoCYP2C9 from "./genotophenotype/genoCYP2C9";
import GenoCYP2D6 from "./genotophenotype/genoCYP2D6";
import GenoCYP3A5 from "./genotophenotype/genoCYP3A5";
// import GenoCYP2E1 from "./genotophenotype/genoCYP2E1";
import { useSelector } from "react-redux";
import GenoABCG2 from "./genotophenotype/genoABCG2";
import GenoCYP3A4 from "./genotophenotype/genoCYP3A4";
import GenoCYP4F2 from "./genotophenotype/genoCYP4F2";
import GenoDBH from "./genotophenotype/genoDBH";
import GenoDRD1 from "./genotophenotype/genoDRD1";
import GenoDRD2ANKK1 from "./genotophenotype/genoDRD2ANKK1";
import GenoDRD3 from "./genotophenotype/genoDRD3";
import GenoGRIK4 from "./genotophenotype/genoGRIK4";
import GenoHTR2A from "./genotophenotype/genoHTR2A";
import GenoHTR2C from "./genotophenotype/genoHTR2C";
import GenoMTHFR from "./genotophenotype/genoMTHFR";
import GenoOPRK1 from "./genotophenotype/genoOPRK1";
import GenoOPRM1 from "./genotophenotype/genoOPRM1";
import GenoSLCO1B1 from "./genotophenotype/genoSLCO1B1";
import GenoTPMT from "./genotophenotype/genoTPMT";
import GenoUGT2B15 from "./genotophenotype/genoUGT2B15";
import GenoVKORC1 from "./genotophenotype/genoVKORC1";
import GenoF2 from "./genotophenotype/genoF2";
import GenoADRB2 from "./genotophenotype/genoADRB2";
import GenoADRA2A from "./genotophenotype/genoADRA2A";
import GenoCACNA1C from "./genotophenotype/genoCACNA1C";
import GenoCACNA1Crs1006737 from "./genotophenotype/genoCACNA1Crs1006737";
import GenoABCB1 from "./genotophenotype/genoABCB1";
import GenoABCB1rs1045642 from "./genotophenotype/genoABCB1rs1045642";
import GenoF5 from "./genotophenotype/genoF5";
import GenoBDNF from "./genotophenotype/genoBDNF";
import GenoCOMT from "./genotophenotype/genoCOMT";

function GenotoPhenotype() {
  //protected routes
  const navigate = useNavigate();
  const AuthStateData = useSelector((state) => state.Signin);

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
  }, []);

  const [genotype, setGenoType] = React.useState("");

  const handleChange = (event) => {
    setGenoType(event.target.innerText);
  };

  let genos = [
    "CYP1A2",
    "CYP3A4",
    "CYP3A5",
    "CYP2B6",
    "CYP2C9",
    "CYP2C19",
    "CYP2D6",
    "CYP4F2",
    "DBH",
    "DRD1",
    "DRD2ANKK1",
    "DRD3",
    "GRIK4",
    "HTR2A",
    "HTR2C",
    "MTHFR",
    "OPRK1",
    "OPRM1",
    "SLCO1B1",
    "TPMT",
    "UGT2B15",
    "VKORC1",
    "ABCG2",
    "F2",
    "ADRB2",
    "ADRA2A",
    "CACNA1C",
    "CACNA1C rs1006737",
    "ABCB1rs1045642",
    "F5",
    "BDNF",
    "COMT"
  ];

  return (
    <>
      <section id="selectGeneType">
        <div className="container-fluid">
          <div className="headingPart">
            <h3>Select Gene Type</h3>
            <Autocomplete
              size="small"
              options={genos}
              autoHighlight
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Gene"
                  onChange={handleChange}
                  sx={{ width: 190 }}
                />
              )}
            />
          </div>
          <div className="gensPart">
            {genotype == "CYP2B6" ? <GenoCYP2B6 /> : ""}
            {genotype == "CYP2C19" ? <GenoCYP2C19 /> : ""}
            {genotype == "CYP1A2" ? <GenoCYP1A2 /> : ""}
            {genotype == "CYP3A5" ? <GenoCYP3A5 /> : ""}
            {genotype == "CYP2C9" ? <GenoCYP2C9 /> : ""}
            {genotype == "CYP2D6" ? <GenoCYP2D6 /> : ""}
            {/* {genotype == "CYP2E1" ? <GenoCYP2E1 /> : ""} */}
            {genotype == "CYP3A4" ? <GenoCYP3A4 /> : ""}
            {genotype == "TPMT" ? <GenoTPMT /> : ""}
            {genotype == "SLCO1B1" ? <GenoSLCO1B1 /> : ""}
            {genotype == "VKORC1" ? <GenoVKORC1 /> : ""}
            {genotype == "UGT2B15" ? <GenoUGT2B15 /> : ""}
            {genotype == "OPRM1" ? <GenoOPRM1 /> : ""}
            {genotype == "OPRK1" ? <GenoOPRK1 /> : ""}
            {genotype == "MTHFR" ? <GenoMTHFR /> : ""}
            {genotype == "HTR2C" ? <GenoHTR2C /> : ""}
            {genotype == "HTR2A" ? <GenoHTR2A /> : ""}
            {genotype == "GRIK4" ? <GenoGRIK4 /> : ""}
            {genotype == "DRD3" ? <GenoDRD3 /> : ""}
            {genotype == "DRD2ANKK1" ? <GenoDRD2ANKK1 /> : ""}
            {genotype == "DRD1" ? <GenoDRD1 /> : ""}
            {genotype == "DBH" ? <GenoDBH /> : ""}
            {genotype == "CYP4F2" ? <GenoCYP4F2 /> : ""}
            {genotype == "ABCG2" ? <GenoABCG2 /> : ""}
            {genotype == "F2" ? <GenoF2 /> : ""}
            {genotype == "ADRB2" ? <GenoADRB2 /> : ""}
            {genotype == "ADRA2A" ? <GenoADRA2A /> : ""}
            {genotype == "CACNA1C" ? <GenoCACNA1C /> : ""}
            {genotype == "CACNA1C rs1006737" ? <GenoCACNA1Crs1006737 /> : ""}
            {genotype == "ABCB1" ? <GenoABCB1 /> : ""}
            {genotype == "ABCB1rs1045642" ? <GenoABCB1rs1045642 /> : ""}
            {genotype == "F5" ? <GenoF5 /> : ""}
            {genotype == "BDNF" ? <GenoBDNF /> : ""}
            {genotype == "COMT" ? <GenoCOMT /> : ""}
          </div>
        </div>
      </section>
    </>
  );
}

export default GenotoPhenotype;
