import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./../index.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ReportTypeNavigateButtons = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const AuthStateData = useSelector((state) => state.Signin);
  const { report_id, result_file_id } = useParams();

  const [resultFile, setResultFile] = useState({});

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const getPrimaryReportById = async () => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        setResultFile(resJson.data ? resJson.data : {});
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!AuthStateData?.access_token) {
      navigate("/");
    }
    getPrimaryReportById();
  }, []);

  let params = location?.pathname

  return (
    <div className={styles.sideTabbar} id="sideNavBar">
      {resultFile?.category == "GRAND REPORT" ? (
      <div
        className="sideNavTab"
        title="Primary Reports"
        style={{
          background:
            params === `/grand-reports/${result_file_id}/update-report-template/${report_id}` ||
              params === `/grand-reports/${result_file_id}/update-report-template/${report_id}/organ-specific` ||
              params === `/grand-reports/${result_file_id}/update-report-template/${report_id}/dietary-guidelines` ||
              params === `/grand-reports/${result_file_id}/update-report-template/${report_id}/weekly-diet-planner` ||
              params === `/grand-reports/${result_file_id}/update-report-template/${report_id}/risk-for-genetic-disorders` ||
              params === `/grand-reports/${result_file_id}/update-report-template/${report_id}/physical-endurance` ||
              params === `/grand-reports/${result_file_id}/update-report-template/${report_id}/periodic-tests`
              ? "#F05B43" : "#fff",
        }}
        onClick={() =>
          navigate(
            `/grand-reports/${result_file_id}/update-report-template/${report_id}`
          )
        }
      >
        <img src="/Primary Reports 1.svg" height={25} width={25} alt="edit" />
      </div>
       ) : (
        ""
      )}
      {resultFile?.category == "RISK DISORDERS" || resultFile?.category == "GRAND REPORT" ? (
      <div
        title="Risk For Chronic Disorders"
        className="sideNavTab"
        style={{
          background:
            params.includes(`/grand-reports/${result_file_id}/update-report-template/${report_id}/risk-chronic-disorders`)
              ? "#F05B43"
              : "#fff",
        }}
        onClick={() =>
          navigate(
            `/grand-reports/${result_file_id}/update-report-template/${report_id}/risk-chronic-disorders`
          )
        }
      >
        <img src="/chronic-disorders .svg" height={25} width={25} alt="edit" />
      </div>
       ) : (
        ""
      )}
      {resultFile?.category == "PHARMACOGENOMICS" || resultFile?.category == "GRAND REPORT" ? (
      <div
        title="Pharmacogenomics"
        className="sideNavTab"
        style={{
          background:
            params.includes(`/grand-reports/${result_file_id}/update-report-template/${report_id}/pharmaco-genomics`) ? "#F05B43" : "#fff",
        }}
        onClick={() =>
          navigate(
            `/grand-reports/${result_file_id}/update-report-template/${report_id}/pharmaco-genomics`
          )
        }
      >
        <img src="/pharmacogeonamics.svg" height={25} width={25} alt="edit" />
      </div>
      ) : (
        ""
      )}
      {resultFile?.category == "NUTRIGENOMICS" || resultFile?.category == "GRAND REPORT" ? (
      <div
        title="Nutrigenomics"
        className="sideNavTab"
        style={{
          background:
            params.includes(`/grand-reports/${result_file_id}/update-report-template/${report_id}/nutrigenomics`) ? "#F05B43" : "#fff",
        }}
        onClick={() =>
          navigate(
            `/grand-reports/${result_file_id}/update-report-template/${report_id}/nutrigenomics`
          )
        }
      >
        <img src="/nutricogenomics.svg" height={25} width={25} alt="edit" />
      </div>
       ) : (
        ""
      )}
    </div>
  );
};

export default ReportTypeNavigateButtons;
