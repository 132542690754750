import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";
import HeaderContainer from "../HeaderName/HeaderContainer";
import HospitalInformation from "../HospitalDetails/HospitalInformation";
import PersonalDetails from "../PersonalDetails/PersonalDetails";
import Reports from "../Reports/Reports";
import TestingInformation from "../TestingDetails/TestingInformation";
import styles from "./AddSummaryReportForm.module.css";
import { useState } from "react";
import LoadingComponent from "../../Core/LoadingComponent";

const AddSummaryReportForm = ({
  setIsData,
  isData,
  data,
  setData,
  getPatientDetails,
  summaryData,
  setSummaryData,

  barCode,
  setBarCode,

  addDisabled,
  errorMessages,
  report_id,
  successMessage,
  errorMessage,
  loading,
  setErrorMessage,
  setErrorMessages,
  patientDetails,
  setPatientDetails,

  previewDummySummaryReport,
  setSummaryReportPreview
}) => {
  const [fileInput, setFileInput] = useState(false);
  const [fileData, setFileData] = useState();
  return (
    <section className={styles.addsummaryreportform}>
      <div className={styles.formcontainer}>
        <HeaderContainer data={data} getPatientDetails={getPatientDetails} />
        <form className={styles.form}>
          <PersonalDetails
            setIsData={setIsData}
            isData={isData}
            addDisabled={addDisabled}
            data={data}
            setData={setData}
            getPatientDetails={getPatientDetails}
            errorMessages={errorMessages}
            errorMessage={errorMessage}
            setBarCode={setBarCode}
            barCode={barCode}
            setErrorMessage={setErrorMessage}
            setErrorMessages={setErrorMessages}
            patientDetails={patientDetails}
            setPatientDetails={setPatientDetails}
            setFileData={setFileData}
            setFileInput={setFileInput}
            setSummaryReportPreview={setSummaryReportPreview}
          />
          <TestingInformation
            isData={isData}
            data={data}
            setData={setData}
            errorMessages={errorMessages}
            setErrorMessage={setErrorMessage}
            setErrorMessages={setErrorMessages}
          />
          <HospitalInformation
            isData={isData}
            data={data}
            setData={setData}
            errorMessages={errorMessages}
            setErrorMessage={setErrorMessage}
            setErrorMessages={setErrorMessages}
          />
          <Reports
            data={data}
            setData={setData}
            summaryData={summaryData}
            errorMessages={errorMessages}
            setSummaryData={setSummaryData}
            setErrorMessages={setErrorMessages}
            setFileData={setFileData}
            setFileInput={setFileInput}
            fileInput={fileInput}
            fileData={fileData}
          />
        </form>
        <div className={styles.updateButton}>
          {report_id ? (
            ""
          ) : (
            <Button
              onClick={() => {
                previewDummySummaryReport();
              }}
              className={styles.updatesubmitButton}
              sx={{ textDecoration: "none" }}
            >
              {"Get Preview"}
            </Button>
          )}
        </div>
      </div>

      <Snackbar
        open={!!successMessage}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
      {/* <Snackbar
        open={!!errorMessage}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar> */}
      <LoadingComponent loading={loading} />
    </section>
  );
};

export default AddSummaryReportForm;
