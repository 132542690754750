import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

import { useDispatch, useSelector } from "react-redux";
import { addSerialNo } from "../../utils/addSerialNumberHelper";
import { prepareURLEncodedParams } from "../../utils/requestUtils";
import LoadingComponent from "../Core/LoadingComponent";
import TablePaginationComponent from "../Core/TablePagination";
import "../scss/ResultFiles.scss";
import FilterOptions from "./FilterOptions";
import DeleteDialog from "../Core/DeleteDialog";
import SortImages from "../Core/SortImages";
import DateTimeFormate from "../Core/DateTimeFormate";

function GrandReportsList() {
  // const { data_id } = useParams();
  const AuthStateData = useSelector((state) => state.Signin);
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [gene, setGenerate] = useState();
  const [resultFile, setResultFile] = useState(null);
  const [success, setSuccess] = useState("");
  const [searchString, setSearchStaring] = useState("");
  const [status, setStatus] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showSuccessAlertgene, setShowSuccessAlertGene] = useState(false);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [result, setResult] = useState();
  const [message, setMessage] = useState("");
  const [processSuccess, setProcessSuccess] = useState("");
  const [generateSuccess, setGenerateSuccess] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [mount, setMount] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [urlSearchParams, setUrlSearchParams] = useState();
  const [details, setDetails] = useState({});
  const [id, setId] = useState("");
  const [deleteLoadingId, setDeleteLoadingId] = useState(false);
  const [openId, setOpenId] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");

  useEffect(() => {
    let params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    setUrlSearchParams(params);
    setSearchStaring(
      searchParams.get("search_string") ? searchParams.get("search_string") : ""
    );
    setFromDate(searchParams.get("from_date"));
    setToDate(searchParams.get("to_date"));
  }, []);

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const getResult = async ({
    page = searchParams.get("page"),
    limit = searchParams.get("limit"),
    search_string = searchParams.get("search_string"),
    status = searchParams.get("status"),
    from_date = searchParams.get("from_date"),
    to_date = searchParams.get("to_date"),
    sort_by = searchParams.get("sort_by"),
    sort_type = searchParams.get("sort_type"),
    category = searchParams.get("category")
  }) => {
    setLoading(true);
    let queryParams = {
      page: 1,
      limit: 20,
    };
    if (page) {
      queryParams["page"] = page;
    }
    if (limit) {
      queryParams["limit"] = limit;
    }
    if (sort_by) {
      queryParams["sort_by"] = sort_by;
    }
    if (sort_type) {
      queryParams["sort_type"] = sort_type;
    }
    if (status) {
      queryParams["status"] = status;
    }
    if (search_string) {
      queryParams["search_string"] = search_string;
    }
    if (from_date) {
      queryParams["from_date"] = from_date;
    }
    if (to_date) {
      queryParams["to_date"] = to_date;
    }
    if (category) {
      queryParams["category"] = category;
    }
    try {
      let { page, limit, ...apiQueryParams } = queryParams;
      setSearchParams(queryParams);
      const url = prepareURLEncodedParams(
        `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${page}/${limit}`,
        apiQueryParams
      );
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        let { data, ...rest } = resJson;
        data = addSerialNo(data, rest?.page, rest?.limit);
        setResultFile(data);
        setPaginationDetails(rest);
        setLoading(false);
      } else if (response.status == 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handelProcessFile = async (id) => {
    setLoading(true);
    setSuccess("");
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${id}/process-file`;
      let options = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      setProcessSuccess(resJson.message);
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        if (resJson.data) {
          setResult(resJson.data?.file_processed);
        }
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          getResult({});
        }, 1500);
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handelGenerateFile = async (id) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${id}/generate-report`;
      let options = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/jsion",
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      setGenerateSuccess(resJson.message);
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        setShowSuccessAlertGene(true);
        if (resJson.data) {
          setGenerate(resJson.data?.report_generated);
        }
        setTimeout(() => {
          setShowSuccessAlertGene(false);
          getResult({});
        }, 1500);
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (data) => {
    setOpenId(true);
    setId(data?._id);
    setDetails(data);
  };

  const confirmDelete = async () => {
    setDeleteLoadingId(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${id}/delete-result-file`;
      let options = {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/jsion",
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setGenerateSuccess(resJson.message);
        setOpenId(false);
        setShowSuccessAlertGene(true);
        setTimeout(() => {
          setShowSuccessAlertGene(false);
          getResult({});
        }, 1500);
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteLoadingId(false);
    }
  };

  useEffect(() => {
    if (mount) {
      const debounce = setTimeout(() => {
        getResult({
          search_string: searchString ? searchString : "",
          page: 1,
        });
      }, 500);
      return () => clearTimeout(debounce);
    } else {
      getResult({});
      setMount(true);
    }
  }, [searchString]);

  const capturePageNum = (value) => {
    getResult({
      page: value,
    });
  };

  const captureRowPerItems = (value) => {
    getResult({
      page: searchParams.get("page"),
      limit: value,
    });
  };
  const handleSort = (column) => {

    if (column == sortBy && sortType == "desc") {
      setSortBy("");
      setSortType("");
      getResult({
        sort_by: "",
        sort_type: "",
      });
    }
    else {
      const newSortType = sortType === "asc" ? "desc" : "asc";
      setSortBy(column);
      setSortType(newSortType);
      getResult({
        sort_by: column,
        sort_type: newSortType,
      });
    }
  };

  const categoryNavigation = (data) => {
    if(data?.category == "PHARMACOGENOMICS"){
      navigate(
        `/grand-reports/${data?._id}/update-report-template/${data?.report_id}/pharmaco-genomics`
      );
    }else if(data?.category == "RISK DISORDERS"){
      navigate(
        `/grand-reports/${data?._id}/update-report-template/${data?.report_id}/risk-chronic-disorders`
      );
    }else if(data?.category == "NUTRIGENOMICS"){
      navigate(
        `/grand-reports/${data?._id}/update-report-template/${data?.report_id}/nutrigenomics`
      );
    }else {
      navigate(
        `/grand-reports/${data?._id}/update-report-template/${data?.report_id}`
      );
    }
  };

  return (
    <>
      <section class="resultFilesContainer">
        <div>
          <div>
            <FilterOptions
              setSearchStaring={setSearchStaring}
              searchString={searchString}
              setStatus={setStatus}
              status={status}
              getResult={getResult}
              setFromDate={setFromDate}
              fromDate={fromDate}
              setToDate={setToDate}
              toDate={toDate}
              setSelectCategory={setSelectCategory}
              selectCategory={selectCategory}
            />
          </div>
        </div>
        <div className="customTable">
          <TableContainer sx={{ height: "70vh" }}>
            <Table stickyHeader>
              <TableHead className="table-head">
                <TableRow>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "60px", minWidth: "40px" }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "130px", minWidth: "100px" }}
                    onClick={() => handleSort("barcode")}
                  >
                    <span className="headerWithSort">
                      Barcode
                      <SortImages value="barcode" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "250px", minWidth: "250px" }}
                  >
                    File Name
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "150px", minWidth: "150px" }}
                    onClick={() => handleSort("status")}
                  >
                    <span className="headerWithSort">
                      Status
                      <SortImages value="status" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "150px", minWidth: "150px" }}
                    onClick={() => handleSort("category")}
                  >
                    <span className="headerWithSort">
                      Category
                      <SortImages value="category" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "180px", minWidth: "160px" }}
                    onClick={() => handleSort("created_at")}
                  >
                    <span className="headerWithSort">
                      Date
                      <SortImages value="created_at" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "150px", minWidth: "150px" }}
                  >
                    Progress
                  </TableCell>
                  <TableCell
                    className="custm-th"
                    sx={{ width: "100px", minWidth: "80px" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-group">
                {resultFile?.length ? (
                  resultFile.map((data, index) => {
                    let processcolor =
                      data?.status === "COMPLETED"
                        ? "rgba(255, 122, 0, 0.20)"
                        : data?.status === "TO-START"
                          ? "rgba(42, 116, 157, 0.20)"
                          : data?.status === "IN-PROGRESS"
                            ? "rgba(155, 36, 40, 0.20)"
                            : "";
                    let statusText =
                      data?.status === "COMPLETED"
                        ? "Completed"
                        : data?.status === "TO-START"
                          ? "To-Start"
                          : data?.status === "IN-PROGRESS"
                            ? "In-Progress"
                            : "--";
                    let statusColor =
                      data?.status === "COMPLETED"
                        ? "#FF7A00"
                        : data?.status === "TO-START"
                          ? "#2A749D"
                          : data?.status === "IN-PROGRESS"
                            ? "#9B2428"
                            : "";

                    let generatecolor =
                      data?.generate === "Generate Report"
                        ? "#33eb91"
                        : "#00a152";
                    return (
                      <TableRow className="table-item" key={index}>
                        <TableCell className="data">{data?.serial}</TableCell>
                        <TableCell className="data">
                          {data?.barcode ? data?.barcode : "--"}
                        </TableCell>
                        <TableCell className="data">
                          {data?.file_name ? data?.file_name : "--"}
                        </TableCell>
                        <TableCell className="data statusActionButtonTd">
                          <Typography
                            className="statusActionButton"
                            sx={{ bgcolor: processcolor, color: statusColor }}
                          >
                            {statusText}
                          </Typography>
                        </TableCell>
                        <TableCell className="data">
                          {data?.category ? data?.category : "--"}
                        </TableCell>
                        <TableCell className="data">
                          {DateTimeFormate(data?.created_at)}
                        </TableCell>
                        {data?.file_processed === false &&
                          data?.report_generated === false ? (
                          <TableCell className="data">
                            <Button
                              className="processFileBtn"
                              variant="contained"
                              size="small"
                              onClick={() => handelProcessFile(data?._id)}
                            >
                              Process file
                            </Button>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {data?.file_processed === true &&
                          data?.report_generated === false ? (
                          <TableCell className="data">
                            <Button
                              className="generateFileBtn"
                              variant="contained"
                              size="small"
                              onClick={() => handelGenerateFile(data?._id)}
                            >
                              Generate File
                            </Button>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {data?.report_generated === true &&
                          data?.file_processed === true ? (
                          <TableCell className="data">
                            <div
                              className="viewProcessBtn"
                              onClick={() => {
                                categoryNavigation(data);
                              }
                              }
                            >
                              <span className="text">
                                <span className="gradientText">
                                  View Progress
                                </span>
                              </span>
                            </div>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell className="data">
                          <div
                            className="cellAction"
                            style={{
                              fontSize: "clamp(13px, 0.625vw, 1.5rem)",
                              width: "100%",
                              display: "flex",

                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <img
                              src="actionIcon/DeleteIcon.svg"
                              height={25}
                              width={25}
                              alt="edit"
                              title="Delete"
                              onClick={() =>
                                deleteLoadingId ? "" : handleDelete(data)
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    {!loading ? (
                      <TableCell colSpan={7} style={{ textAlign: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <img
                            src="/no-data.svg"
                            height={350}
                            width={350}
                            alt="No data"
                          />
                          <p style={{ fontSize: "clamp(18px, 2.72vw, 22px)" }}>
                            No Data
                          </p>
                        </div>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="customTablePagination">
            <TablePaginationComponent
              paginationDetails={paginationDetails}
              capturePageNum={capturePageNum}
              captureRowPerItems={captureRowPerItems}
            />
          </div>
        </div>
        <Snackbar
          open={showSuccessAlert}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {processSuccess}
          </Alert>
        </Snackbar>
        <Snackbar
          open={showSuccessAlertgene}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {generateSuccess}
          </Alert>
        </Snackbar>
        <LoadingComponent loading={loading} />
        {details?.report_generated === true &&
          details?.file_processed === true ? (
          <DeleteDialog
            setOpenId={setOpenId}
            openId={openId}
            confirmDelete={confirmDelete}
            deleteLoadingId={deleteLoadingId}
            label="Deleting this file will remove the report. Continue?"
          />
        ) : (
          <DeleteDialog
            setOpenId={setOpenId}
            openId={openId}
            confirmDelete={confirmDelete}
            deleteLoadingId={deleteLoadingId}
            label="Are you sure you want to delete this Result file?"
          />
        )}
      </section>
    </>
  );
}
export default GrandReportsList;
