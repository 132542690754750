const sampleData = [
    {
        day: "Monday",
        diet_plan: {
            bed_time: "Fresh butter milk with soaked chia seeds and few drops of lemon",
            break_fast: "Oats with 200ml of skimmed milk",
            lunch: "1 cup of Boiled Quinoa / Boiled Millet + Veg / Non-veg curry / Dal with Greenleaf veg (or) veg chutney Curd (or) butter milk",
            mid_morning: "Fruit salad (1 cup)",
            evening_snacks: "Dry nuts (soaked almonds - 6 + walnuts - 3)",
            dinner: "Millet roti - 2 nos With Paneer curry",
            early_morning: "1 glass of warm water + 1 cup of green tea with 1 tsp of honey"
        }
    },
    {
        day: "Tuesday",
        diet_plan: {
            bed_time: "Fresh butter milk with soaked chia seeds and few drops of lemon",
            break_fast: "Oats with 200ml of skimmed milk",
            lunch: "1 cup of Boiled Quinoa / Boiled Millet + Veg / Non-veg curry / Dal with Greenleaf veg (or) veg chutney Curd (or) butter milk",
            mid_morning: "Fruit salad (1 cup)",
            evening_snacks: "Dry nuts (soaked almonds - 6 + walnuts - 3)",
            dinner: "Millet roti - 2 nos With Paneer curry",
            early_morning: "1 glass of warm water + 1 cup of green tea with 1 tsp of honey"
        }
    },
    {
        day: "Wednesday",
        diet_plan: {
            bed_time: "Fresh butter milk with soaked chia seeds and few drops of lemon",
            break_fast: "Oats with 200ml of skimmed milk",
            lunch: "1 cup of Boiled Quinoa / Boiled Millet + Veg / Non-veg curry / Dal with Greenleaf veg (or) veg chutney Curd (or) butter milk",
            mid_morning: "Fruit salad (1 cup)",
            evening_snacks: "Dry nuts (soaked almonds - 6 + walnuts - 3)",
            dinner: "Millet roti - 2 nos With Paneer curry",
            early_morning: "1 glass of warm water + 1 cup of green tea with 1 tsp of honey"
        }
    },
    {
        day: "Thursday",
        diet_plan: {
            bed_time: "Fresh butter milk with soaked chia seeds and few drops of lemon",
            break_fast: "Oats with 200ml of skimmed milk",
            lunch: "1 cup of Boiled Quinoa / Boiled Millet + Veg / Non-veg curry / Dal with Greenleaf veg (or) veg chutney Curd (or) butter milk",
            mid_morning: "Fruit salad (1 cup)",
            evening_snacks: "Dry nuts (soaked almonds - 6 + walnuts - 3)",
            dinner: "Millet roti - 2 nos With Paneer curry",
            early_morning: "1 glass of warm water + 1 cup of green tea with 1 tsp of honey"
        }
    },
    {
        day: "Friday",
        diet_plan: {
            bed_time: "Fresh butter milk with soaked chia seeds and few drops of lemon",
            break_fast: "Oats with 200ml of skimmed milk",
            lunch: "1 cup of Boiled Quinoa / Boiled Millet + Veg / Non-veg curry / Dal with Greenleaf veg (or) veg chutney Curd (or) butter milk",
            mid_morning: "Fruit salad (1 cup)",
            evening_snacks: "Dry nuts (soaked almonds - 6 + walnuts - 3)",
            dinner: "Millet roti - 2 nos With Paneer curry",
            early_morning: "1 glass of warm water + 1 cup of green tea with 1 tsp of honey"
        }
    },
    {
        day: "Saturday",
        diet_plan: {
            bed_time: "Fresh butter milk with soaked chia seeds and few drops of lemon",
            break_fast: "Oats with 200ml of skimmed milk",
            lunch: "1 cup of Boiled Quinoa / Boiled Millet + Veg / Non-veg curry / Dal with Greenleaf veg (or) veg chutney Curd (or) butter milk",
            mid_morning: "Fruit salad (1 cup)",
            evening_snacks: "Dry nuts (soaked almonds - 6 + walnuts - 3)",
            dinner: "Millet roti - 2 nos With Paneer curry",
            early_morning: "1 glass of warm water + 1 cup of green tea with 1 tsp of honey"
        }
    },
    {
        day: "Sunday",
        diet_plan: {
            bed_time: "Fresh butter milk with soaked chia seeds and few drops of lemon",
            break_fast: "Oats with 200ml of skimmed milk",
            lunch: "1 cup of Boiled Quinoa / Boiled Millet + Veg / Non-veg curry / Dal with Greenleaf veg (or) veg chutney Curd (or) butter milk",
            mid_morning: "Fruit salad (1 cup)",
            evening_snacks: "Dry nuts (soaked almonds - 6 + walnuts - 3)",
            dinner: "Millet roti - 2 nos With Paneer curry",
            early_morning: "1 glass of warm water + 1 cup of green tea with 1 tsp of honey"
        }
    }
  ];

  export {sampleData};