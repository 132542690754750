import { Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import styles from "./deleteDialog.module.css"

const DeleteDialog = ({ setOpenId, openId, confirmDelete, label, deleteLoadingId }) => {

    return (
        <div>
            <Dialog
                open={!!openId}
                PaperProps={{
                    sx: {
                        borderRadius: "1rem",
                        width: "80%",
                        margin: "0",
                        maxWidth: "350px",
                    },
                }}
            >
                <Card className={styles.canceldeletePopup}>
                    <DialogTitle className={styles.confirmDelete}>
                        Confirm Delete!
                    </DialogTitle>
                    <DialogContent className={styles.sureyouwanttoDelete}>
                        {label}
                    </DialogContent>
                    <DialogActions
                        sx={{
                            "& .MuiDialogActions-root": {
                                padding: "2rem",
                                border: "1px solid",
                            },
                        }}
                    >
                        <Button
                            className={styles.cancelButton}
                            variant="outlined"
                            onClick={() => setOpenId(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={styles.deleteButton}
                            variant="contained"
                            onClick={confirmDelete}
                        >
                            {deleteLoadingId ? (
                                <CircularProgress size={"1.5rem"} sx={{ color: "#fff" }} />
                            ) : (
                                "Delete!"
                            )}
                        </Button>
                    </DialogActions>
                </Card>
            </Dialog>
        </div>
    );
}
export default DeleteDialog;