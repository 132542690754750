import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { prepareURLEncodedParams } from "../../utils/requestUtils";
import { Alert, AlertTitle, Backdrop, Button, CircularProgress, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";


const AllRiskDisordersDetails = () => {
    const AuthStateData = useSelector((state) => state.Signin);
    const { search } = useLocation();
    const page = new URLSearchParams(search).get("page");
    const take = new URLSearchParams(search).get("take");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState("");
    const [riskDisordersData, setRiskDisorderData] = useState(null);
    const [generateSuccess, setGenerateSuccess] = useState("");
    const [riskDisordersCount, setRiskDisordersCount] = useState(0);
    const [showSuccessAlertgene, setShowSuccessAlertGene] = useState(false);
    const [showError, setShowError] = useState(false);
    const [gene, setGenerate] = useState();
    const [message, setMessage] = useState("");
    const [controller, setController] = useState({
        page: page || 0,
        rowsPerPage: take || 10,
    });

    const handleLogout = async () => {
        dispatch({
            type: "SIGN_OUT",
            payload: {},
        });
        navigate("/");
    };

    const handlePageChange = (event, newPage) => {
        setController({
            ...controller,
            page: newPage,
        });
        riskDisordersResultFiles();
    };

    const handleChangeRowsPerPage = (event) => {
        setController({
            ...controller,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
        riskDisordersResultFiles();
    };

    const riskDisordersResultFiles = async () => {
        setLoading(true)
        try {
            let queryParams = {
                page: controller.page + 1,
                limit: controller.rowsPerPage,
            };


            let url = prepareURLEncodedParams(
                `${process.env.REACT_APP_API_BASE_URL}/risk-disorders`,
                queryParams
            );
            let options = {
                method: "GET",
                headers: new Headers({
                    authorization: `${AuthStateData?.access_token}`,
                }),
            };
            let response = await fetch(url, options);
            let resJson = await response.json();
            if (response.status == 200 || response.status == 201) {
                setLoading(false);
                setRiskDisorderData(resJson.data);
                setRiskDisordersCount(resJson.count);
            } else if (response.status == 422) {
                setMessage(resJson.data["message"]);
            } else if (response.status === 401) {
                handleLogout();
            } else if (response.status === 403) {
                handleLogout();
            }
        } catch (err) {
            setLoading(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handelGenerateFile = async (id) => {
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/risk-disorders/${id}/generate_report`;
            let options = {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/jsion",
                    authorization: `${AuthStateData?.access_token}`,
                }),
            };
            let response = await fetch(url, options);
            let resJson = await response.json();
            setGenerateSuccess(resJson.message);
            setLoading(false);
            if (response.status === 200 || response.status === 201) {
                setShowSuccessAlertGene(true);
                if (resJson.data) {
                    setGenerate(resJson.data?.report_generated);
                }
                setTimeout(() => {
                    setShowSuccessAlertGene(false);
                    riskDisordersResultFiles();
                }, 1500);
            } else if (response.status === 422) {
                setShowError(true);
                setMessage(resJson.message);
                setTimeout(() => {
                    setShowError(false);
                    riskDisordersResultFiles();
                }, 1500);
            } else if (response.statusCode === 403) {
                handleLogout();
            }
        } catch (err) {
            setLoading(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!AuthStateData?.access_token) {
            navigate("/");
        }
        riskDisordersResultFiles(); // Pass selectedCategory to the initial API call
    }, [controller]);

    const dateTime = (data) => {
        const localDate = new Date(data);

        // Extract local date components
        const day = localDate.getDate().toString().padStart(2, "0");
        const month = (localDate.getMonth() + 1).toString().padStart(2, "0");
        const year = localDate.getFullYear();
        let hours = localDate.getHours();
        const minutes = localDate.getMinutes().toString().padStart(2, "0");
        const ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight

        // Format the time string
        const formattedTimeString = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;

        return formattedTimeString;
    };

    return (
        <div>
            <section class="resultFilesContainer">
                <div className="customTable">
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <TableContainer sx={{ height: "63vh" }}>
                        <Table stickyHeader>
                            <TableHead className="table-head">
                                <TableRow>
                                    <TableCell className="custm-th">S.No</TableCell>
                                    <TableCell className="custm-th">Barcode</TableCell>
                                    <TableCell className="custm-th">Category</TableCell>
                                    <TableCell className="custm-th" width={120}>
                                        Status
                                    </TableCell>
                                    <TableCell className="custm-th" width={200}>
                                        Date
                                    </TableCell>
                                    <TableCell className="custm-th" width={130}>
                                        Progress
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table-group">
                                {riskDisordersData &&
                                    riskDisordersData.map((data, index) => {
                                        let processcolor =
                                            data?.status === "COMPLETED"
                                                ? "#2a9d8f"
                                                : data?.status == "INPROGRESS"
                                                    ? "#5162fd"
                                                    : "";
                                        const tableNumber =
                                            index + 1 + controller.page * controller.rowsPerPage;
                                        return (
                                            <TableRow className="table-item" key={index}>
                                                <TableCell className="data">{tableNumber}</TableCell>
                                                <TableCell className="data">
                                                    {data?.barcode ? data?.barcode : ""}
                                                </TableCell>
                                                <TableCell className="data">
                                                    {data?.category ? data?.category

                                                        : ""}
                                                </TableCell>
                                                <TableCell className="data statusActionButtonTd">
                                                    <Button
                                                        className="statusActionButton"
                                                        variant="contained"
                                                        size="small"
                                                        sx={{ bgcolor: processcolor }}
                                                    >
                                                        {data?.status}
                                                    </Button>
                                                </TableCell>
                                                <TableCell className="data">
                                                    {dateTime(data?.created_at)}
                                                </TableCell>
                                                {data?.report_generated === false ? (
                                                    <TableCell className="data">
                                                        <Button
                                                            className="progressActionButton"
                                                            variant="contained"
                                                            size="small"
                                                            sx={{ bgcolor: "brown" }}
                                                            onClick={() =>
                                                                handelGenerateFile(data?._id)
                                                            }
                                                        >
                                                            Generate File
                                                        </Button>
                                                    </TableCell>
                                                ) : (
                                                    ""
                                                )}
                                                {data?.report_generated === true ? (
                                                    <TableCell className="data">
                                                        <Link
                                                            to={{
                                                                pathname: `/risk-disorder/${data?._id}/preview-report/${data?.report_id}`
                                                            }}
                                                        >
                                                            <Button
                                                                className="progressActionButton"
                                                                variant="contained"
                                                                size="small"
                                                                sx={{ bgcolor: "#03045e" }}
                                                            >
                                                                View Progress
                                                            </Button>
                                                        </Link>
                                                    </TableCell>
                                                ) : (
                                                    ""
                                                )}
                                                <Snackbar
                                                    open={showSuccessAlertgene}
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                >
                                                    <Alert severity="success">
                                                        <AlertTitle>Success</AlertTitle>
                                                        {generateSuccess}
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar
                                                    open={showError}
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                >
                                                    <Alert severity='error'>
                                                        <AlertTitle>Error</AlertTitle>
                                                        {message}
                                                    </Alert>
                                                </Snackbar>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    <div className="customTablePagination">
                        <TablePagination
                            className="pagination"
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            onPageChange={handlePageChange}
                            page={controller.page}
                            count={riskDisordersCount}
                            rowsPerPage={controller.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        // getrowid={(row) => row._id}
                        />
                    </div>
                </div>
            </section>
        </div>
    )
}
export default AllRiskDisordersDetails;