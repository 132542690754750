
import { RemoveCircle } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  InputAdornment,
  Select,
  MenuItem
} from "@mui/material";
import styles from "./periodic.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { Toaster, toast } from "sonner";


const PeriodicTestsTable = ({
  handleClose,
  open,
  dataLength,
  setDataLength,
  indicationData,
  setIndicationData,
  frequencyData,
  setFrequencyData,
  recommendedData,
  setRecommendedData,
  isEditOpenOrNot,
}) => {

  // const {selectData , monthdata}= frequencyData.split(" ");
  // console.log(selectData);
  // console.log(monthdata);
  const [numericalPart, setNumericalPart] = useState([]);
  const [unitPart, setUnitPart] = useState([]);

  const data = () => {
    const newNumericalPart = [];
    const newUnitPart = [];

    frequencyData.forEach(item => {
      const [num, unit] = item.split(" ");
      newNumericalPart.push(num);
      newUnitPart.push(unit || "");
    });

    setNumericalPart(newNumericalPart);
    setUnitPart(newUnitPart);
  };

  useEffect(() => {
    data();
  },
    [frequencyData])
  const onChangeTextField = (e, index) => {
    const { value, name } = e.target;
    if (name === "indication") {
      const indication = [...indicationData];
      indication[index] = value.trim();
      setIndicationData(indication.filter(e => e));
    }
    if (name === "recommended") {
      const recommended = [...recommendedData];
      recommended[index] = value.trim();
      setRecommendedData(recommended.filter(e => e));
    }
    if (name === "frequency") {
      const newNumericalPart = [...numericalPart];
      newNumericalPart[index] = Number(value);
      const newFrequencyData = [...frequencyData];
      newFrequencyData[index] = `${newNumericalPart[index]} ${unitPart[index] || ""}`;
      console.log(newFrequencyData[index], "frequency");
      setNumericalPart(newNumericalPart);
      setFrequencyData(newFrequencyData.filter(e => e));
    }
  };

  const onAddExtraRow = () => {
    let addindication = indicationData.filter(e => e);
    let addrecommended = recommendedData.filter(e => e);
    let addfrequency = frequencyData.filter(e => e);
    setIndicationData(addindication);
    setRecommendedData(addrecommended);
    setFrequencyData([...addfrequency, "0 months"]);
    setDataLength((prev) => prev + 1);
  };

  const removeItem = (index) => {
    const tempIndication = [...indicationData];
    const tempRecommended = [...recommendedData];
    const tempFrequency = [...frequencyData];
    tempIndication.splice(index, 1);
    tempRecommended.splice(index, 1);
    tempFrequency.splice(index, 1);
    setIndicationData(tempIndication);
    setRecommendedData(tempRecommended);
    setFrequencyData(tempFrequency);
    setDataLength((prev) => prev - 1);
  };
  const checkNumber = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    event.target.value = value
  };

  const handleSelectChange = (e, index) => {
    const value = e.target.value;
    console.log(value, "value");
    const newUnitPart = [...unitPart];
    newUnitPart[index] = value;
    setUnitPart(newUnitPart);

    const newFrequencyData = [...frequencyData];
    newFrequencyData[index] = `${numericalPart[index]} ${newUnitPart[index] || "months"}`;
    setFrequencyData(newFrequencyData.filter(e => e));
  };

  return (
    <>
      <div className={styles.dietaryBlock} id="dietoryEditBlock">
        <div className="periodic-tests-edit">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
                  S No
                </TableCell>
                <TableCell>Indication</TableCell>
                <TableCell>Recommended Tests</TableCell>
                <TableCell>Frequency</TableCell>
                <TableCell sx={{ width: "60px", minWidth: "60px", maxWidth: "60px", color: 'black' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: dataLength }, (_, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <TextField
                      className="inputField"
                      value={indicationData[index] || ""}
                      name={"indication"}
                      onChange={(e) => onChangeTextField(e, index)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className="inputField"
                      value={recommendedData[index] || ""}
                      name={"recommended"}
                      onChange={(e) => onChangeTextField(e, index)}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="custom-frequency">
                      <TextField
                        className="inputField"
                        value={numericalPart[index] || "0"}
                        name={"frequency"}
                        defaultValue={"0"}
                        onChange={(e) => onChangeTextField(e, index)}
                        onInput={checkNumber}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Select
                                value={unitPart[index] || "months"}
                                onChange={(e) => handleSelectChange(e, index)}
                                defaultValue={"months"}
                                sx={{ backgroundColor: 'white', borderColor: 'black', maxHeight: '40px', gap: '0px' }}
                              >
                                <MenuItem value="" disabled>Select</MenuItem>
                                <MenuItem value="days">Days</MenuItem>
                                <MenuItem value="months">Months</MenuItem>
                                <MenuItem value="years">Years</MenuItem>
                              </Select>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>

                  </TableCell>
                  <TableCell>
                    <Tooltip title='Remove'>
                      <IconButton onClick={() => removeItem(index)}>
                        <RemoveCircle sx={{ color: "#f35a3e" }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={6}>
                  <Button onClick={onAddExtraRow}>Add More</Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </div>

      </div>
      <Toaster closeButton richColors position="top-right"></Toaster>
    </>
  );
};

export default PeriodicTestsTable;
