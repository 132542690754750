import moment from "moment";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";
import styles from "./downloadReport.module.css";

const DownloadFinalizedReport = () => {
  const { report_track_id } = useParams();

  const [loading, setLoading] = useState(false);

  const [reportSrc, setReportSrc] = useState("");

  //preview for pdfs
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  //function to preview pdfs
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const downloadReport = async (url) => {
    if (!url) {
      return;
    }

    let visiId = reportSrc?.visit_id;
    let date = moment(new Date()).format("DD/MM/YYYY_hh_mm_ss_A");
    let fileName = visiId + "_" + date + "_" + "summary-report";

    try {
      const response = await fetch(url);

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      downloadLink.download = fileName ? fileName : "report";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Clean up the blob URL
      window.URL.revokeObjectURL(blobUrl);
    } catch (err) {
      console.error(err);
    }
  };
  const getDownloadUrl = async () => {
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports/public/${report_track_id}`;
      const response = await fetch(url);
      const resData = await response.json();
      if (response.status == 200 || response.status == 201) {
        if (resData?.data) setReportSrc(resData?.data);
      } else {
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDownloadUrl();
  }, []);
  return (
    <div className={styles.mainContainer}>
      <div className={styles.detailsContainer}>
        <div className={styles.details}>
          <div className={styles.label}>Bar Code:</div>
          <div className={styles.value}>{reportSrc?.barcode_no}</div>
        </div>
        <div className={styles.details}>
          <div className={styles.label}>Visit Id:</div>
          <div className={styles.value}>{reportSrc?.visit_id}</div>
        </div>
        <div className={styles.details}>
          <div className={styles.label}>Patient Name:</div>
          <div className={styles.value}>{reportSrc?.patient_name}</div>
        </div>
        <div className={styles.details}>
          <div className={styles.label}>UHID/MR No:</div>
          <div className={styles.value}>{reportSrc?.uhid_number}</div>
        </div>
        <div className={styles.details}>
          <div className={styles.label}>Download Report</div>
          <div
            className={styles.downloadReport}
            style={{ color: "blue", cursor: "pointer" }}
            id="downloadReport"
            onClick={() => {
              downloadReport(reportSrc?.download_url);
            }}
          >
            Click to download
          </div>
        </div>
      </div>
      {reportSrc?.download_url ? (
        <div className={styles.iframeContainer}>
          <Document
            file={reportSrc?.download_url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {/* Render all pages */}
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DownloadFinalizedReport;
