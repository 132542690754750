import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingComponent from "../../../Core/LoadingComponent";

const PrimaryReportPreview = () => {
  const dispatch = useDispatch();
  const { report_id, result_file_id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const AuthStateData = useSelector((state) => state.Signin);

  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState("");

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const getPreview = async () => {
    setLoading(true);
    try {
      const category = searchParams.get("category");
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/preview-report/${report_id}?category=${category}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resText = await response.text();
      if (response.status == 200 || response.status == 201) {
        setPreview(resText);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPreview();
  }, []);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: preview }}></div>{" "}
      <LoadingComponent loading={loading} />
    </div>
  );
};

export default PrimaryReportPreview;
