import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadIcon from "@mui/icons-material/Upload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Menu from "@mui/material/Menu";
import { ArrowBack } from "@mui/icons-material";
import {
  AlertTitle,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TableContainer,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TablePagination,
} from "@mui/material";

import "../scss/ResultFiles.scss";
import BaseLayout from "../../layouts/BaseLayout";
import { green } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { prepareURLEncodedParams } from "../../utils/requestUtils";

function ResultFiles() {
  // const { data_id } = useParams();
  const AuthStateData = useSelector((state) => state.Signin);
  const { search } = useLocation();
  const page = new URLSearchParams(search).get("page");
  const take = new URLSearchParams(search).get("take");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [gene, setGenerate] = useState();
  const [resultFile, setResultFile] = useState(null);
  const [processFile, setProcessFile] = useState(null);
  const [generateReport, setGenerateReport] = useState(null);
  const [preview, setPreview] = useState(null);
  const [resultCount, setResultCount] = useState(0);
  const [showLoading, setShowLoading] = useState("");
  const [getLoading, setGetLoading] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState("");
  const [loadingResult, setLoadingResult] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showSuccessAlertgene, setShowSuccessAlertGene] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [search_string, setSearch_string] = useState();
  const [result, setResult] = useState();
  const [message, setMessage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [processSuccess, setProcessSuccess] = useState("");
  const [generateSuccess, setGenerateSuccess] = useState("");
  const [controller, setController] = useState({
    page: page || 0,
    rowsPerPage: take || 10,
  });
  let token = localStorage.getItem("code");

  useEffect(() => {
    if (!AuthStateData?.access_token) {
      navigate("/");
    }
    getResult(selectedCategory); // Pass selectedCategory to the initial API call
  }, [controller, selectedCategory]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
    getResult();
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
    getResult();
  };
  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const handleChangeCategory = (event) => {
    const category = event.target.value;

    setController({
      ...controller,
      page: 0, // Reset the page to 0 when the category changes
    });
    // getResult(category);
    if (category == "All Files") {
      getResult("");
      setSelectedCategory("All Files");
    } else {
      getResult(category);
      setSelectedCategory(category);
    }
  };

  const getResult = async (category) => {
    setLoadingResult(true);
    try {
      let queryParams = {
        page: controller.page + 1,
        limit: controller.rowsPerPage,
      };
      if (category !== "All Files") {
        queryParams["category"] = selectedCategory;
      }

      let url = prepareURLEncodedParams(
        `${process.env.REACT_APP_API_BASE_URL}/result-files`,
        queryParams
      );
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        setLoadingResult(false);
        setResultFile(resJson.data);
        setResultCount(resJson.count);
      } else if (response.status == 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoadingResult(false);
      console.error(err);
    } finally {
      setLoadingResult(false);
    }
  };

  const handelProcessFile = async (id, category) => {
    setLoading(true);
    setSuccess("");
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-files/${id}/process-file`;
      if (category === "Nutrigenomics") {
        url = `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/result-files/${id}/process-file`;
      }
      let options = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      setProcessSuccess(resJson.message);
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        if (resJson.data) {
          setResult(resJson.data?.file_processed);
        }
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          getResult();
        }, 1500);
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
      setShowLoading(false);
    }
  };

  const handelGenerateFile = async (id, category) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-files/${id}/generate-report`;
      if (category === "Nutrigenomics") {
        url = `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/result-files/${id}/generate-report`;
      }
      let options = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/jsion",
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      setGenerateSuccess(resJson.message);
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        setShowSuccessAlertGene(true);
        if (resJson.data) {
          setGenerate(resJson.data?.report_generated);
        }
        setTimeout(() => {
          setShowSuccessAlertGene(false);
          getResult();
        }, 1500);
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };
  const getResults = () => {
    navigate("/createform");
  };

  const dateTime = (data) => {
    // let result =
    //   data?.slice(8, 10) + "-" + data?.slice(5, 7) + "-" + data?.slice(0, 4);
    // let dt = new Date(data);
    // let hours = dt.getHours();
    // let AmOrPm = hours >= 12 ? "pm" : "am";
    // hours = hours % 12 || 12;
    // let minutes = dt.getMinutes();
    // result = result + "  " + hours + ":" + minutes + " " + AmOrPm;
    // return result;

    // Convert UTC timestamp to local time
    const localDate = new Date(data);

    // Extract local date components
    const day = localDate.getDate().toString().padStart(2, "0");
    const month = (localDate.getMonth() + 1).toString().padStart(2, "0");
    const year = localDate.getFullYear();
    let hours = localDate.getHours();
    const minutes = localDate.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight

    // Format the time string
    const formattedTimeString = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;

    return formattedTimeString;
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <section class="resultFilesContainer">
        <div className="searchFilterNavbar">
          <div className="leftItem">
            <FormControl className="select-container">
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                size="small"
                value={selectedCategory ? selectedCategory : "All Files"}
                onChange={handleChangeCategory}
              >
                <MenuItem value="All Files">All Files</MenuItem>
                <MenuItem value="Pharmacogenomics">Pharmacogenomics</MenuItem>
                <MenuItem value="Nutrigenomics">Nutrigenomics</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="rightItem">
            <ul>
              <li>
                <Button
                  aria-controls="dropdown-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <Button className="uploadDropdown" startIcon={<UploadIcon />}>
                    Upload
                  </Button>
                </Button>
                <Menu
                  id="dropdown-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <Link to="/upload-file">PHARMACOGENOMICS FILE</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to="/upload-nutrigenomics-file">
                      NUTRIGENOMICS FILE
                    </Link>
                  </MenuItem>
                </Menu>
              </li>
              <li>
                <div className="backButtonAction" onClick={getResults}>
                  <IconButton disableElevation={true}>
                    <ArrowBack /> Back
                  </IconButton>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="insideNavbar">
          <ul>
            <li>
              <Link to="/upload-file">
                <Button startIcon={<UploadIcon />}>
                  UPLOAD PHARMACOGENOMICS FILE
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/upload-nutrigenomics-file">
                <Button startIcon={<UploadIcon />}>
                  UPLOAD NUTRIGENOMICS FILE
                </Button>
              </Link>
            </li>
          </ul>
        </div> */}

        <div className="customTable">
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <TableContainer sx={{ height: "63vh" }}>
            <Table stickyHeader>
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="custm-th">S.No</TableCell>
                  <TableCell className="custm-th">Name</TableCell>
                  <TableCell className="custm-th">Category</TableCell>
                  <TableCell className="custm-th" width={120}>
                    Status
                  </TableCell>
                  <TableCell className="custm-th" width={200}>
                    Date
                  </TableCell>
                  <TableCell className="custm-th" width={130}>
                    Progress
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-group">
                {resultFile &&
                  resultFile.map((data, index) => {
                    let processcolor =
                      data?.status === "COMPLETED"
                        ? "#2a9d8f"
                        : data?.status == "TO-START"
                          ? "#5162fd"
                          : data?.status == "IN-PROGRESS"
                            ? "#eb5757"
                            : "";

                    let generatecolor =
                      data?.generate === "Generate Report"
                        ? "#33eb91"
                        : "#00a152";
                    const tableNumber =
                      index + 1 + controller.page * controller.rowsPerPage;
                    return (
                      <TableRow className="table-item" key={index}>
                        <TableCell className="data">{tableNumber}</TableCell>
                        <TableCell className="data">
                          {data?.file_name ? data?.file_name : ""}
                        </TableCell>
                        <TableCell className="data">
                          {data?.category && data?.category?.length
                            ? data?.category[0]
                            : ""}
                        </TableCell>
                        <TableCell className="data statusActionButtonTd">
                          <Button
                            className="statusActionButton"
                            variant="contained"
                            size="small"
                            sx={{ bgcolor: processcolor }}
                          >
                            {data?.status}
                          </Button>
                        </TableCell>
                        <TableCell className="data">
                          {dateTime(data?.created_at)}
                        </TableCell>
                        {data?.file_processed === false &&
                          data?.report_generated === false ? (
                          <TableCell className="data">
                            <Button
                              className="progressActionButton"
                              variant="contained"
                              size="small"
                              onClick={() =>
                                handelProcessFile(data?._id, data?.category[0])
                              }
                            >
                              Process file
                            </Button>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {data?.file_processed === true &&
                          data?.report_generated === false ? (
                          <TableCell className="data">
                            <Button
                              className="progressActionButton"
                              variant="contained"
                              size="small"
                              sx={{ bgcolor: "brown" }}
                              onClick={() =>
                                handelGenerateFile(data?._id, data?.category[0])
                              }
                            >
                              Generate File
                            </Button>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {data?.report_generated === true &&
                          data?.file_processed === true ? (
                          <TableCell className="data">
                            <Link
                              to={{
                                pathname: `/result-file/${data?._id}/preview-report/${data?.report_id}`,
                                search:
                                  data?.category && data?.category?.length
                                    ? `?category=${data?.category[0]}`
                                    : "",
                              }}
                            >
                              <Button
                                className="progressActionButton"
                                variant="contained"
                                size="small"
                                sx={{ bgcolor: "#03045e" }}
                              >
                                View Progress
                              </Button>
                            </Link>
                          </TableCell>
                        ) : (
                          ""
                        )}

                        <Snackbar
                          open={showSuccessAlert}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Alert severity="success">
                            <AlertTitle>Success</AlertTitle>
                            {processSuccess}
                          </Alert>
                        </Snackbar>
                        <Snackbar
                          open={showSuccessAlertgene}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Alert severity="success">
                            <AlertTitle>Success</AlertTitle>
                            {generateSuccess}
                          </Alert>
                        </Snackbar>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="customTablePagination">
            <TablePagination
              className="pagination"
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              onPageChange={handlePageChange}
              page={controller.page}
              count={resultCount}
              rowsPerPage={controller.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            // getrowid={(row) => row._id}
            />
          </div>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingResult}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </section>
    </>
  );
}
export default ResultFiles;
