const DateTimeFormate = (data) => {

    // Convert UTC timestamp to local time
    const localDate = new Date(data);

    // Extract local date components
    const day = localDate.getDate().toString().padStart(2, "0");
    const month = (localDate.getMonth() + 1).toString().padStart(2, "0");
    const year = localDate.getFullYear();
    let hours = localDate.getHours();
    const minutes = localDate.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight

    // Format the time string
    const formattedTimeString = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;

    return formattedTimeString;
}
export default DateTimeFormate;