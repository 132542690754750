import { Button, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import {
  setAccessToken,
  setRefreshToken,
  setUserDetails,
} from "../StoreSlices/AuthSlice";
import { signInAPI } from "../services/authService";

function SignIn() {
  const SigninSliceData = useSelector((state) => state.Signin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (SigninSliceData.access_token) {
      navigate("/grand-reports");
    }
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoading(true);
    try {
      setValidationErrors({});
      setErrorMessage("");

      let res = await signInAPI(username, password);

      let responseData = await res.json();

      if (res.status === 200 || res.status === 201) {
        dispatch(setUserDetails(responseData.data.userData));
        dispatch(setAccessToken(responseData.data.access_token));
        dispatch(setRefreshToken(responseData.data.refresh_token));
        navigate("/grand-reports");
      } else if (res.status === 401) {
        setErrorMessage(responseData.message);
      } else if (res.status === 403) {
        setErrorMessage(responseData.message);
      } else if (res.status == 422) {
        setValidationErrors(responseData.errors);
      } else {
        toast.error(responseData.message || "Login Failed");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <>
      <div id="signIn">
        <img
          src="login/login-top-right-img.png"
          alt=""
          className="top-right-img"
        />
        <img
          src="login/login-bottom-left-img.png"
          alt=""
          className="bottom-left-img"
        />
        <div className="loginCard">
          <div className="loginLogoBlock">
            <img src="login/login-logo.svg" alt="" className="loginLogo" />
          </div>
          <div className="headingPart">
            <h3>Welcome Back</h3>
            <p>Enter your email and password to access your account</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="formControl">
              <label>User Name</label>
              <TextField
                size="small"
                placeholder="Enter your user name"
                variant="outlined"
                type={"text"}
                name="name"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                style={{
                  background: "#FAF4F0",
                  width: "100%",
                  borderRadius: "8px",
                }}
                error={
                  validationErrors["user_name"]
                    ? validationErrors["user_name"]
                    : ""
                }
              />
              <Typography className="errorMessage">
                {validationErrors["user_name"]}
              </Typography>
            </div>
            <div className="formControl">
              <label>Password </label>

              <TextField
                size="small"
                variant="outlined"
                placeholder="Enter your password"
                type={"password"}
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  background: "#FAF4F0",
                  width: "100%",
                  borderRadius: "8px",
                }}
                error={
                  validationErrors["password"]
                    ? validationErrors["password"]
                    : ""
                }
              />
              <Typography className="errorMessage">
                {validationErrors["password"]}
              </Typography>
            </div>
            <Typography className="errorMessage">{errorMessage}</Typography>
            <Button
              type="submit"
              variant="contained"
              disabled={showLoading === true ? true : false}
              className="loginButton"
            >
              {showLoading ? (
                <CircularProgress size={"1.5rem"} sx={{ color: "#fff" }} />
              ) : (
                "Sign In"
              )}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default SignIn;
