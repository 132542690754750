const DateFormate = (data) => {

    const localDate = new Date(data);

    // Extract local date components
    const day = localDate.getDate().toString().padStart(2, "0");
    const month = (localDate.getMonth() + 1).toString().padStart(2, "0");
    const year = localDate.getFullYear();
    let hours = localDate.getHours();
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight

    // Format the time string
    const formattedTimeString = `${day}-${month}-${year}`;

    return formattedTimeString;
}
export default DateFormate;