import { TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import "rsuite/dist/rsuite.css";
import styles from "./HospitalInformation.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const HospitalInformation = ({ data, setData, errorMessages, setErrorMessage, setErrorMessages, isData }) => {


  const changeDateFormat = (value) => {
    // Your date string
    let dateString = value;

    // Convert the date string to a Date object
    let date = new Date(dateString);

    // Define the days array
    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    // Define the months array
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Get the day, month, year, hours, minutes, and seconds
    let day = days[date.getDay()];
    let month = months[date.getMonth()];
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    // Get the timezone offset in milliseconds and calculate the offset in hours and minutes
    let timezoneOffset = date.getTimezoneOffset();
    let offsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    let offsetMinutes = Math.abs(timezoneOffset % 60);

    // Determine the timezone string
    let timezoneString = (timezoneOffset >= 0 ? '-' : '+') +
      ("0" + offsetHours).slice(-2) + ("0" + offsetMinutes).slice(-2);

    // Construct the formatted date string
    let formattedDateString = `${day} ${month} ${("0" + date.getDate()).slice(-2)} ${year} ${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${("0" + seconds).slice(-2)} GMT${timezoneString} (India Standard Time)`;

    return formattedDateString;

  }
  const [registerationDate, setRegisterationDate] = useState(null)
  const [completedDate, setCompletedDate] = useState(null)
  const [recevingDate, setRecevingDate] = useState(null)
  const updateFields = useCallback(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    if (Object.keys(data)?.length) {
      updateFields();
    }
  }, [data, updateFields]);

  const Datestyles = { width: "100%", height: "10%", display: 'block', marginBottom: 10, zIndex: 1500 };


  return (
    <div className={styles.hospitalinformation}>
      <div className={styles.headinglable}>
        <div className={styles.iconcontainer}>
          <img className={styles.icon} alt="" src="/icon2.svg" />
        </div>
        <h2 className={styles.personalDetails}>Hospital Information</h2>
      </div>
      <div className={styles.hospitalnamedate}>
        <div className={styles.hospitalname}>
          <div className={styles.lablecontainer}>
            <label className={styles.lable}>Hospital Name</label>
          </div>
          <TextField
            className={styles.inputField}
            disabled={isData == false}
            color="primary"
            placeholder="Enter Hospital Name"
            variant="outlined"
            sx={{
              "& .MuiInputBase-root": {
                height: "30px",
                fontSize: "clamp(12px,0.72vw,16px)",
                fontFamily: "'IBM Plex Sans', Sans-serif",
                fontWeight: "500",
              },
              "& .MuiOutlinedInput-input": {
                padding: "5px 14px !important",
              },
            }}
            value={data?.hospital_name ? data?.hospital_name : ""}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                hospital_name: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.hospitalname}>
          <div className={styles.lablecontainer}>
            <label className={styles.lable}>Date of Registration</label>
          </div>
          <div className={styles.datepicker} id="summaryDatePicker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                disabled={isData == false}
                keyboard
                inputFormat="MM/DD/YYYY"
                style={Datestyles}
                value={
                  registerationDate || data?.date_of_regestration ? registerationDate || data?.date_of_regestration : null
                }
                onChange={(newValue) => {
                  setRegisterationDate(newValue);
                  setData((prev) => ({
                    ...prev,
                    date_of_regestration: newValue,
                  }));
                }}
                renderInput={(params) => <TextField {...params} />}

              />
            </LocalizationProvider>
            <p style={{ color: "red" }}>{errorMessages["date_of_regestration"]}</p>
          </div>
        </div>
        <div className={styles.hospitalname}>
          <div className={styles.lablecontainer}>
            <label className={styles.lable}>Date of Collection</label>
          </div>
          <div className={styles.datepicker} id="summaryDatePicker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                disabled={isData == false}
                keyboard
                inputFormat="MM/DD/YYYY"
                style={Datestyles}
                value={
                  completedDate || data?.date_of_collection ? completedDate || data?.date_of_collection : null
                }
                onChange={(newValue) => {
                  setData((prev) => ({
                    ...prev,
                    date_of_collection: newValue,
                  }));
                  setCompletedDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <p style={{ color: "red" }}>{errorMessages["date_of_collection"]}</p>
          </div>
        </div>
        <div className={styles.hospitalname}>
          <div className={styles.lablecontainer}>
            <label className={styles.lable}>Date of Receiving</label>
          </div>
          <div className={styles.datepicker} id="summaryDatePicker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                disabled={isData == false}
                keyboard
                inputFormat="MM/DD/YYYY"
                style={Datestyles}
                value={
                  recevingDate || data?.date_of_receiving ? recevingDate || data?.date_of_receiving : null
                }
                onChange={(newValue) => {
                  setData((prev) => ({
                    ...prev,
                    date_of_receiving: newValue,
                  }));
                  setRecevingDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <p style={{ color: "red" }}>{errorMessages["date_of_receiving"]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalInformation;
