import styles from "./../index.module.css";

const PatientInfoComponent = ({ resultFile }) => {
  const { patient_info: patientInfo } = resultFile;
  return (
    <div className={styles.patientInformation}>
      <header className={styles.header2}>
        <img
          className={styles.patienticon}
          alt=""
          src="/primaryreports/patienticon.svg"
        />
        <h3 className={styles.heading3}>Patient Info</h3>
      </header>
      <div className={styles.details}>
        <div className={styles.wrapper}>
          <label className={styles.lable}>Barcode</label>
          <p className={styles.details2}>
            {patientInfo?.barcode_no ? patientInfo?.barcode_no : "--"}
          </p>
        </div>
        <div className={styles.wrapper}>
          <label className={styles.lable}>Name</label>
          <p className={styles.details2}>
            {patientInfo?.name ? patientInfo?.name : "--"}
          </p>
        </div>
        <div className={styles.wrapper}>
          <label className={styles.lable}>Visit No</label>
          <p className={styles.details2}>
            {patientInfo?.visit_id ? patientInfo?.visit_id : "--"}
          </p>
        </div>
        <div className={styles.wrapper}>
          <label className={styles.lable}>Test</label>
          <p className={styles.details2}>
            {patientInfo?.test ? patientInfo?.test : "DNA Decoder"}
          </p>
        </div>
        <div className={styles.wrapper}>
          <label className={styles.lable}>Sample Type</label>
          <p className={styles.details2}>
            {patientInfo?.sample_type ? patientInfo?.sample_type : "--"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PatientInfoComponent;
