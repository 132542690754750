import { Alert, AlertTitle, Backdrop, Button, CircularProgress, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const PreviewRiskDisorderResultFile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AuthStateData = useSelector((state) => state.Signin);
    const { _id, report_id } = useParams();

    const [previewdata, setPreviewData] = useState();
    const [loading, setLoading] = useState(false);
    const [finalize, setFinalize] = useState("");
    const [successMessage, setSuccessMesssage] = useState();
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const handleLogout = async () => {
        dispatch({
            type: "SIGN_OUT",
            payload: {},
        });
        navigate("/");
    };

    const handlePreview = async () => {
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/risk-disorders/result-file/${_id}/preview-report/${report_id}`;
            let options = {
                method: "GET",
                headers: new Headers({
                    content_Type: "application/json",
                    authorization: `${AuthStateData.access_token}`,
                }),
            };
            let response = await fetch(url, options);
            var template = await response.text();
            if (response.status === 200 || response.status === 201) {
                setLoading(false);
                setPreviewData(template);
            } else if (response.statusCode === 403) {
                handleLogout();
            }
        } catch (err) {
            setLoading(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    const getOneResultFile = async () => {
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/risk-disorders/result-file/${_id}/report/${report_id}`;
            let options = {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.access_token}`,
                }),
            };
            let response = await fetch(url, options);
            let resJson = await response.json();

            if (response.status === 200 || response.status === 201) {
                setLoading(false);
                setFinalize(resJson.data.is_finalized == true)
            } else if (response.statusCode === 403) {
                handleLogout();
            }
        } catch (err) {
            setLoading(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    const handelFinalize = async () => {
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/risk-disorders/result_file/${_id}/report/${report_id}/finalize`;
            let options = {
                method: "PATCH",
                headers: new Headers({
                    content_Type: "application/json",
                    authorization: `${AuthStateData.access_token}`,
                }),
            };
            let response = await fetch(url, options);
            let resJson = await response.json();
            setSuccessMesssage(resJson.message);
            if (resJson.success === true) {
                setShowSuccessAlert(true);
                setTimeout(() => {
                    setShowSuccessAlert(false);
                }, 1500);
            }
            if (response.status === 200 || response.status === 201) {
                setLoading(false);
                setFinalize(resJson.success);
                getOneResultFile()
                handlePreview()
            } else if (response.statusCode === 403) {
                handleLogout();
            }
        } catch (err) {
            setLoading(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    const handleDownload = async () => {
        setLoading(true);
        try {
            let url =
                `${process.env.REACT_APP_API_BASE_URL}/risk-disorders/download/` + report_id;
            let options = {
                method: "POST",
                headers: new Headers({
                    content_Type: "application/json",
                    authorization: `${AuthStateData.access_token}`,
                }),
            };
            let response = await fetch(url, options);
            let resJson = await response.json();
            setSuccessMesssage(resJson.message);
            if (resJson.success === true) {
                setShowSuccessAlert(true);
                setTimeout(() => {
                    setShowSuccessAlert(false);
                }, 1500);
            }
            if (response.status === 200 || response.status === 201) {
                setLoading(false);

                window.open(resJson.data);
            } else if (response.statusCode === 403) {
                handleLogout();
            }
        } catch (err) {
            setLoading(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!AuthStateData.access_token) {
            navigate("/");
        }
        getOneResultFile();
        handlePreview();
    }, []);

    return (
        <div>
            <div style={{ marginTop: "10px" }}>
                <div className="reportViewNavbar">
                    <div className="inside">
                        <ul>
                            <li>
                                <Button
                                    className="download-btn"
                                    size="small"
                                    onClick={() => {
                                        navigate(
                                            `/risk-disorders/${_id}/report/${report_id}/edit`
                                        );
                                    }}
                                >
                                    Edit
                                </Button>
                            </li>
                            <li>

                                {finalize ? (
                                    <Button
                                        className="finalize-btn"
                                        variant="contained"
                                        size="small"
                                        disabled
                                    >
                                        Finalized
                                    </Button>
                                ) : (
                                    <Button
                                        className="finalize-btn"
                                        variant="contained"
                                        size="small"
                                        onClick={handelFinalize}
                                    >
                                        Finalize
                                    </Button>
                                )}
                            </li>

                            <li>
                                <Button
                                    className="download-btn"
                                    size="small"
                                    onClick={(file) => {
                                        handleDownload();
                                    }}
                                >
                                    Download
                                </Button>
                            </li>
                            <li>
                                <Button
                                    className="back-button"
                                    size="small"
                                    onClick={() => navigate(-1)}
                                >
                                    <ArrowBackIcon />
                                    Back
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div style={{ marginTop: "55px" }}>
                    <iframe
                        width="100%"
                        id="report"
                        height="1100"
                        frameBorder="0"
                        srcDoc={previewdata}
                    >
                        {" "}
                    </iframe>
                </div>
            </div>
            <Snackbar
                open={showSuccessAlert}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
export default PreviewRiskDisorderResultFile;