import { useState } from "react";
import timePipe from "../../Pipes/timePipe";
import styles from "./MainPage.module.css";
import OtpVerification from "./OtpVerification";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "sonner";
import LoadingComponent from "../LoadingComponent";
import OtpDrawerVerification from "./OtpDrawerVerificatio";
const ReportsCard = ({ reportsData }) => {

    const [otpDialogOpen, setOtpDialogOpen] = useState(false)
    const [otpDrawerOpen, setOtpDrawerOpen] = useState(false)
    const [reportDetails, setReportDetails] = useState()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const AuthStateData = useSelector((state) => state.Signin);
    const [loading, setLoading] = useState(false);
    const handleLogout = async () => {
        dispatch({
            type: "SIGN_OUT",
            payload: {},
        });
        navigate("/");
    };


    const formatDateAndTime = (dateString) => {
        if (!dateString) return "";

        let inputDate = new Date(dateString);
        inputDate.setHours(inputDate.getHours() + 5);
        inputDate.setMinutes(inputDate.getMinutes() + 30);
        return timePipe(inputDate, "DD/MM/YYYY hh:mm A");
    };
    //get otp 
    const getOtpFromMobile = async (phoneNumber) => {
        setLoading(true)
        try {
            let options = {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.access_token}`,
                }),
                body: JSON.stringify({
                    "mobile": phoneNumber
                })
            };

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/summary-reports/patient-reports/send-otp`, options);
            let responseData = await response.json();
            if (response?.status >= 200 && response?.status < 300) {
                toast.success(responseData?.message)
                const userAgent = navigator.userAgent;
                const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
                if (isMobile) {
                    setTimeout(() => {
                        setOtpDrawerOpen(true)
                    }, 1000)
                } else {
                    setTimeout(() => {
                        setOtpDialogOpen(true)
                    }, 1000)
                }
            }
            else if (response?.status == 403 || response?.status == 401) {
                handleLogout();
            }
        }
        catch (err) {
            console.error(err)
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <div className={styles.reportcardscontainer}>

            <div className={styles.reportcard}>
                <img className={styles.reporticon} alt="" src="/reporticon.svg" />
                <div className={styles.details}>
                    <div className={styles.row}>
                        <div className={styles.patientnameandid}>
                            <h3 className={styles.patientname}>{reportsData?.name}</h3>
                            <p className={styles.visitid}>{reportsData?.visit_id}</p>
                        </div>
                        <h4 className={styles.dateandtime}>{formatDateAndTime(reportsData?.created_at)}</h4>
                    </div>
                    <div className={styles.chipscontainer}>
                        {/* <div className={styles.casetypechip}>
                            <p className={styles.casetype}>Nutrigenomics</p>
                        </div> */}
                        <div className={styles.casetypechip}>
                            <p className={styles.barcode}>{reportsData?.barcode_no}</p>
                        </div>
                    </div>
                    <p className={styles.hospitalname}>
                        {reportsData?.hospital_name}
                    </p>
                    <button className={styles.downloadreportbutton} onClick={() => {
                        setReportDetails(reportsData)
                        getOtpFromMobile(reportsData?.mobile)
                    }}>
                        <p className={styles.actionname1}>Download Report</p>
                    </button>
                </div>
            </div>
            <OtpDrawerVerification
                otpDialogOpen={otpDrawerOpen}
                setOtpDialogOpen={setOtpDrawerOpen}
                reportDetails={reportDetails}
                getOtpFromMobile={getOtpFromMobile} />
            <OtpVerification
                otpDialogOpen={otpDialogOpen}
                setOtpDialogOpen={setOtpDialogOpen}
                reportDetails={reportDetails}
                getOtpFromMobile={getOtpFromMobile} />
            <Toaster richColors closeButton position="top-right" />
            <LoadingComponent loading={loading} />
        </div>
    )
}
export default ReportsCard;