import { Edit } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingComponent from "../../../Core/LoadingComponent";
import styles from "./../index.module.css";
import EditPatientDetails from "./EditPatientDetails";
import SummaryDetailsBlock from "./SummaryDetailsBlock";
import ViewPatientDetails from "./ViewPatientDetails";

const PrimaryReport = () => {
  const AuthStateData = useSelector((state) => state.Signin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { report_id, result_file_id } = useParams();
  const userType = useSelector((state) => state.Signin.user_details?.user_type);

  const [isSummaryEditOpenOrNot, setIsSummaryEditOpenOrNot] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editorData, setEditorData] = useState("");
  const [resultFile, setResultFile] = useState({});
  const [editResultFile, setEditResultFile] = useState({});
  const [message, setMessage] = useState("");
  const [processSuccess, setProcessSuccess] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [preview, setPreview] = useState("");

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const getPrimaryReportById = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        setResultFile(resJson.data ? resJson.data : {});
        setEditResultFile(resJson.data ? resJson.data : {});
        getPreview();
        setEditorData(
          resJson?.data?.report_summary_details
            ? resJson?.data?.report_summary_details
            : ""
        );
      } else if (response.status == 422) {
        setMessage(resJson.data);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onChangePatientDetails = async (payload) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}/update-patient-demographics`;
      let options = {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
          "content-type": "application/json",
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        setProcessSuccess(resJson?.message);
        setShowSuccessAlert(true);
        setTimeout(() => {
          setProcessSuccess("");
          setShowSuccessAlert(false);
        }, 1500);
        getPreview();
        setIsEditOpen(false);
        getPrimaryReportById();
        window.location.reload();
        return resJson;
      } else if (response.status == 422) {
        setMessage(resJson.errors);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onSaveUserDetails = async (payload) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/update-report-template/${report_id}`;
      let options = {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
          "content-type": "application/json",
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        setProcessSuccess(resJson?.message);
        setShowSuccessAlert(true);
        setTimeout(() => {
          setProcessSuccess("");
          setShowSuccessAlert(false);
        }, 1500);
        getPreview();
        setIsEditOpen(false);
        getPrimaryReportById();
        window.location.reload();
        return resJson;
      } else if (response.status == 422) {
        setMessage(resJson.errors);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    let payload = {
      report_summary_details: editorData,
    };
    let data = await onSaveUserDetails(payload);
    if (data) {
      setProcessSuccess(data?.message);
      setShowSuccessAlert(true);
      setIsSummaryEditOpenOrNot(false);
      setTimeout(() => {
        setProcessSuccess("");
        setShowSuccessAlert(false);
      }, 1500);
      await getPreview();
    }
  };

  const getPreview = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/preview-report/${report_id}?category=SUMMARY`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resText = await response.text();
      if (response.status == 200 || response.status == 201) {
        setPreview(resText);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onEditClick = () => {
    setIsSummaryEditOpenOrNot(true);
  };

  useEffect(() => {
    if (!AuthStateData?.access_token) {
      navigate("/");
    }
    getPrimaryReportById();
    getPreview();
  }, []);

  return (
    <div className={styles.detailscontainer} id="summaryDetails">
      <div className={styles.hyperophicdialatedCardiomyop}>
        {isEditOpen ? (
          <EditPatientDetails
            isEditOpen={isEditOpen}
            resultFile={editResultFile}
            setResultFile={setEditResultFile}
            onSaveUserDetails={onChangePatientDetails}
            setIsEditOpen={setIsEditOpen}
            message={message}
            setMessage={setMessage}
          />
        ) : (
          <ViewPatientDetails
            setIsEditOpen={setIsEditOpen}
            resultFile={resultFile}
          />
        )}
      </div>
      {!isSummaryEditOpenOrNot ? (
        <div className="editBtnGrp">
          <h6 className="headingText">Summary</h6>
          {!resultFile?.is_finalized === true || userType == "DOCTOR" ? (
            <Button
              disableElevation={true}
              className="editBtn"
              variant="outlined"
              startIcon={
                <img src="/edit-pink-icon.svg" alt="" width={12} height={12} />
              }
              onClick={onEditClick}
            >
              Edit
            </Button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {isSummaryEditOpenOrNot ? (
        <div className="processSummaryBlock">
          <SummaryDetailsBlock
            editorData={editorData}
            setEditorData={setEditorData}
          />
          <div className="summaryEditBtnGrp ">
            <Button
              className="cancelBtn"
              onClick={() => {
                setIsSummaryEditOpenOrNot(false);
              }}
              color="error"
              variant="text"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="saveBtn"
              onClick={onContinue}
            >
              Update
            </Button>
          </div>
        </div>
      ) : (
        <div
          className="rishDisorder_pdf"
          style={{
            width: "100%",
            margin: "0 auto",
          }}
          dangerouslySetInnerHTML={{ __html: preview }}
        ></div>
      )}

      <LoadingComponent loading={loading} />

      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {processSuccess}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PrimaryReport;
