import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import styles from "./HeaderContainer.module.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const HeaderContainer = () => {
  const navigate = useNavigate();
  const { report_id } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <header className={styles.headercontainer}>
      <IconButton
        disableElevation={true}
        color="primary"
        size="2x"
        variant="contained"
        sx={{ borderRadius: "0px 0px 0px 0px" }}
        onClick={() =>
          searchParams.get("from") ? navigate("/sumamry-reports") : navigate(-1)
        }
      >
        <ArrowBack />{" "}
      </IconButton>

      <h1 className={styles.heading}>
        {" "}
        {report_id ? "Update Summary Report" : "Add Summary Report"}
      </h1>
    </header>
  );
};

export default HeaderContainer;
