export const addSerialNo = (dataArray, page, limit) => {
    if (dataArray.length) {
        let arrayAfterSerial = dataArray.map((item, index) => {
            return { ...item, serial: (((page - 1) * limit) + (index + 1)) }
        })
        return arrayAfterSerial;
    }
    return [];
}

