import React, { useState, useEffect, useRef } from "react";
import { ReactDOM } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AgGridReact } from "ag-grid-react";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const GenerateReport = (props) => {
  const [items, setData] = useState([]);

  function createData(
    name = "string",
    increasedtoxicity = "string",
    requiredincreasesdoses = "string",
    requiredreduceddoses = "string"
  ) {
    return {
      name,
      increasedtoxicity,
      requiredincreasesdoses,
      requiredreduceddoses,
    };
  }

  function createdata(
    name = "string",
    abcg2 = "string",
    apoe = "string",
    comt = "string",
    bdnf = "string",
    cacna1c = "string"
  ) {
    return {
      name,
      abcg2,
      apoe,
      comt,
      bdnf,
      cacna1c,
    };
  }

  const rows = [
    createData("Repaglinide", "Clopidogrel", "Telmisartan", "Mexiletine"),
    createData(
      "Rosiglitazone",
      "Dextroamphetamine",
      "Lamotrigine",
      "Acenocoumarol"
    ),
    createData("Dabigatran", "Oxcarbazepine", "Tacrolimus", "Warfarin"),
    createData("Ticagrelor", "Phenobarbital", "", "Rovaroxaban"),
    createData("Atenolol", "Trazodone", "", "Carvidelol"),
    createData("Propranolol", "Chlorpromazine", "", "Labetalol"),
    createData("Granisetron", "Fluphenazine", "", "Lansoprazole"),
    createData("Disulfiram", "Trifluoperazine", "", "Omeprazole"),
  ];

  const rows1 = [
    createdata("F2", "F5", "CYP2B6", "CYP2C9", "CYP2C19", "CYP2D6"),
    createdata("CYP3A4", "CYP3A5", "CYP4F2", "DRD2/ANKK1", "DRD3", "MTHFR"),
    createdata("OPRK1", "OPRM1", "VKORC1", "SLC6A3", "SLCO1B1", "TPMT"),
    createdata("UGT2V15", "", "", "", "", ""),
  ];

  const card = (
    <React.Fragment>
      <CardContent>
        <Typography className="pb-1" pt={3}>
          Name :
        </Typography>
        <Typography className="pb-1" pt={3}>
          Age/Gender :
        </Typography>
        <Typography className="pb-1" pt={3}>
          Ref Doctor :
        </Typography>
        <Typography className="pb-1" pt={3}>
          Test : DNA Decoder Panel
        </Typography>
      </CardContent>
    </React.Fragment>
  );
  const card1 = (
    <React.Fragment>
      <CardContent>
        <Typography className="pb-1" pt={3}>
          Date of Collection : 28.06.2022
        </Typography>
        <Typography className="pb-1" pt={3}>
          Date of Receiving : 28.06.2022
        </Typography>
        <Typography className="pb-1" pt={3}>
          Date of Reporting : 03.08.2022
        </Typography>
        <Typography className="pb-1" pt={3}>
          Sample Type : EDTA blood
        </Typography>
      </CardContent>
    </React.Fragment>
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/users/update-password`;
    let options = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
    let result = await fetch(url, options)
      .then((result) => result.json())
      .then((data) => {
        setData(data.records);
      });
  };
  const rowlistItems = (classes) => {
    return classes.map((clas) => (
      <TableRow key={clas.toString()}>
        <TableCell>{clas.class_name}</TableCell>
        <TableCell>
          {clas.standard_medication.map((item) => (
            <li key={item.toString()}>{item.name}</li>
          ))}
        </TableCell>
        <TableCell>
          {clas.use_with_caution.map((items) => (
            <li key={items.toString()}>{items.name}</li>
          ))}
        </TableCell>
        <TableCell>
          {clas.avoid.map((lists) => (
            <li key={lists.toString()}>{lists.name}</li>
          ))}
        </TableCell>
      </TableRow>
    ));
  };

  const listItems = items.map((item) => (
    <ListItem disablePadding key={item.toString()}>
      <ListItemButton>
        <ListItemIcon>
          <img
            height="50px"
            src="https://as2.ftcdn.net/v2/jpg/01/63/53/19/1000_F_163531909_f8hqGna4l1YtuCoHRzEU2fgY9HE4cmm8.jpg"
          />
        </ListItemIcon>
        <ListItemText>{item.speciality_name}</ListItemText>
      </ListItemButton>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Class</TableCell>
            <TableCell>Standard</TableCell>
            <TableCell>Use with caution</TableCell>
            <TableCell>Avoid</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{rowlistItems(item.classes)}</TableBody>
      </Table>
    </ListItem>
  ));

  return (
    <div style={{ height: 400, margin: "0 auto", width: "100%" }}>
      <br></br>
      <Box
        sx={{
          minWidth: 275,
          display: "inline-grid",
          justifyContent: "center",
          variant: "outlined",
        }}
      >
        <Card variant="outlined">{card}</Card>
      </Box>
      <Box
        sx={{
          minWidth: 300,
          display: "inline-grid",
          justifyContent: "center",
        }}
      >
        <Card variant="outlined">{card1}</Card>
      </Box>

      <li>{listItems}</li>

      <TableContainer component={Paper}>
        <h3>list of Medications with Altered Therapeutic Response</h3>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Deceased Response</TableCell>
              <TableCell>Increased Toxicity</TableCell>
              <TableCell>Required increases Doses</TableCell>
              <TableCell>Required reduced Doses</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.increasedtoxicity}</TableCell>
                <TableCell>{row.requiredincreasesdoses}</TableCell>
                <TableCell>{row.requiredreduceddoses}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <h3>The Candidate genes covered in Pharmacogenomic</h3>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>ABCB1</TableCell>
              <TableCell>ABCG2</TableCell>
              <TableCell>APOE</TableCell>
              <TableCell>COMT</TableCell>
              <TableCell>BDNF</TableCell>
              <TableCell>CACNA1C</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows1.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.abcg2}</TableCell>
                <TableCell>{row.apoe}</TableCell>
                <TableCell>{row.comt}</TableCell>
                <TableCell>{row.bdnf}</TableCell>
                <TableCell>{row.cacna1c}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GenerateReport;
