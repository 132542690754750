import { SearchOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button, InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./summery.module.css";

const SummaryTableFilterHeader = ({ getAllSummaries, search, setSearch }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.summaryPageHeader} >
      <div>
        <TextField
          className="summarySearch"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined sx={{fontSize:"1.2rem"}} />
              </InputAdornment>
            ),
          }}
          sx={{
            width: "300px",
          }}
          size="small"
          placeholder="Search by name or bar code or visit id"
          value={search}
          type="search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div>
        <Button
          variant="contained"
          className={styles.summeryNewButton}
          onClick={() => navigate("/add-summary")}
        >
          <AddIcon sx={{ fontSize: "small" }} />
          New
        </Button>
      </div>
    </div>
  );
};

export default SummaryTableFilterHeader;
