export const arrayToUrlString = (params, key, value) => {
    let arrayUrl = value.map((item) => {
        return `${key}[]=${item}`;
    });
    return arrayUrl.join("&");
};

export const prepareURLEncodedParams = (url, params) => {
    let paramsArray = Object.keys(params)
        .map((key) => {
            const value = params[key];

            if (value && value.length) {
                if (Array.isArray(value)) {
                    return arrayToUrlString(params, key, value);
                }
                return `${key}=${params[key]}`;
            } else if (value) {
                return `${key}=${params[key]}`;
            } else {
                return "";
            }
        })
        .filter((e) => e.length);

    let paramsURLs = paramsArray.filter((e) => e).join("&");

    if (paramsURLs) {
        return url + "?" + paramsURLs;
    }
    return url;
};

export const prepareURLEncodedParamsForClients = (url, params) => {
    let paramsArray = Object.keys(params).map((key) => {

        let value;
        if (Array.isArray(params[key])) {
            value = params[key];
        } else {
            value = params[key] ? params[key].toString() : ""
        }

        if (value && value.length) {
            if (Array.isArray(value)) {
                return arrayToUrlString(params, key, value);
            } else {
                return `${key}=${params[key]}`
            }


        }
        else {
            return ""
        }
    }).filter((e) => e.length)

    let paramsURLs = paramsArray.filter((e) => e).join('&');

    if (paramsURLs) {
        return url + '?' + paramsURLs;
    }
    return url;
}



