import { combineReducers, configureStore } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"
import SigninSlice from "./StoreSlices/AuthSlice";
import CreateFormSlice from "./StoreSlices/CreateFormSlice";

const appReducer = combineReducers({
    Signin: SigninSlice,
    createform: CreateFormSlice
})

const rootReducer = (state, action) => {
    if (action.type === "SIGN_OUT") {
        storage.removeItem('persist:root')
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const persistConfig = {
    key: "root",
    storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export default configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})