import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from "react";
import "./organ.module.css";

const OrganSpecificEditTable = ({ editorData, setEditorData }) => {

    const [checked, setChecked] = useState("");
    const updateOrganSpecificRisk = (risk, index) => {
        let deatils = [...editorData]
        setChecked(risk);
        deatils[index]["risk"] = risk
        setEditorData(deatils)
    };

    return (
        <div className='OrganSpecificEditPage'>
            <section id="defaultReportPage" layout="div">
                <table class="overall-table">
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    <div id="Pharmacogenomics">
                                        <table>
                                            <thead>
                                                <tr class="heading">
                                                    <th colspan="4">Organ-specific Genetic Risk</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="sub-heading-one">Organ</td>
                                                    <td class="sub-heading-two">No Risk</td>
                                                    <td class="sub-heading-three">Carrier</td>
                                                    <td class="sub-heading-four">At Risk</td>
                                                </tr>
                                                {editorData?.length ?
                                                    editorData?.map((data, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{data?.organ}</td>
                                                                <td>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={data?.risk == "NO_RISK"}
                                                                                onChange={() => {
                                                                                    updateOrganSpecificRisk("NO_RISK", index)
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={data?.risk == "CARRIER"}
                                                                                onChange={() => {
                                                                                    updateOrganSpecificRisk("CARRIER", index)
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={data?.risk === "AT_RISK"}
                                                                                onChange={() => {
                                                                                    updateOrganSpecificRisk("AT_RISK", index)
                                                                                }}
                                                                            />
                                                                        }
                                                                    />

                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                    : ""
                                                }
                                                <tr>
                                                    <td colspan="4">
                                                        <div class="details">
                                                            <ul>
                                                                <li><p>No Risk</p></li>
                                                                <li>
                                                                    <p>Carrier</p>
                                                                </li>
                                                                <li><p>At Risk</p></li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p>
                                                                        No Genetic defect affecting the particular
                                                                        organ or system
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p>
                                                                        No direct impact on the organ. Can pass on to
                                                                        offspring. (Hence offspring should be tested)
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p>
                                                                        Genetic defect affecting the particular organ
                                                                        or system
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <div class="emptyFooter"></div>
                                <div class="defaultFooter">
                                    <img
                                        class="logoImage"
                                        src="https://api-pgxyodadiagnostics-development.up.railway.app/images/pharmacogenomics_footer.svg"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </section>
        </div>
    );
};
export default OrganSpecificEditTable;