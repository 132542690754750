import { useSearchParams } from "react-router-dom";

const SortImages = ({ value }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const sortBy = searchParams.get("sort_by");
    const sortType = searchParams.get("sort_type");

    let src = "/up-arrow.svg"; // default image
    let className = "sortImageDefault"

    if (sortBy === value) {
        if (sortType === "desc") {
            src = "/down-arrow.svg";
            className = "sortImage";

        } else if (sortType === "asc") {
            src = "/up-arrow.svg";
            className = "sortImage";

        }
    }

    return <img style={{ width: "16px", height: "16px" }} alt="" src={src} className={className} />;
};

export default SortImages;
