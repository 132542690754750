import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AlertTitle,
  Button,
  Card,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import { useSelector } from "react-redux";
import "./EditDetails.css";

function EditFiles() {
  const AuthStateData = useSelector((state) => state.Signin);

  const [patientInfo, setPatientInfo] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMesssage] = useState();
  const [errorMessage, setErrorMesssage] = useState();
  const [loadingResult, setLoadingResult] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
    handleEditPatientinfo();
    handleEditgenes();
  }, []);
  const { _id, report_id } = useParams();
  const [preview, setPreview] = useState();
  const [nutritions, setNutritions] = useState();
  const [adeqate, setAdeqate] = useState();
  const [editedRecommendations, setEditedRecommendations] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category");
  const handleEditPatientinfo = async () => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-file/${_id}/report/${report_id}`;
      if (category === "Nutrigenomics") {
        url = `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/result-file/${_id}/report/${report_id}`;
      }
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      var resjson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        setPatientInfo(resjson.data["patient_info"]);
        setGens(resjson.data["form"]);
        setPreview(resjson.data["form"]);
        setNutritions(resjson.data["nutrients_summary"]);
        setAdeqate(resjson.data["nutrient_diet"]);
      }
    } catch (err) {
      setLoadingResult(false);
      console.error(err);
    } finally {
      setLoadingResult(false);
    }
  };

  const handleEditgenes = async () => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-file/${_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      var resjson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
      }
    } catch (err) {
      setLoadingResult(false);
      console.error(err);
    } finally {
      setLoadingResult(false);
    }
  };

  const handleEdit = async (data) => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-files/${_id}/update-report/${report_id}`;
      if (category === "Nutrigenomics") {
        url = `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/result-files/${_id}/update-report-template/${report_id}`;
      }

      let requestBody;

      if (category === "Nutrigenomics") {
        // Include additional properties based on some condition
        requestBody = {
          patient_info: data,
          nutrients_summary: nutritions,
          nutrient_diet: {
            adeqate: data.adeqate,
            suplements_required: data.suplements_required,
            avoid: data.avoid,
          },
        };
      } else {
        // Exclude additional properties based on the else condition
        requestBody = {
          patient_info: data,
          form: gens,
        };
      }

      let options = {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
        body: JSON.stringify(requestBody),
      };

      let response = await fetch(url, options);
      let responseData = await response.json();
      setSuccessMesssage(responseData.message);
      setErrorMesssage(responseData.message);
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        setLoading(false);
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate(-1);
        }, 1500);
      } else {
        setShowErrorAlert(true);
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 1500);
      }
    } catch (err) {
      setLoadingResult(false);
      setLoading(false);
      console.error(err);
    } finally {
      setLoadingResult(false);
      setLoading(false);
    }
  };

  const handleRecommendationChange = (mainIndex, subIndex, index, nutrient_summary_recomindex, value) => {
    const onlyLettersValue = value.replace(/[^a-zA-Z\s.]/g, "");

    let tempNutritions = [];

    tempNutritions = nutritions.map((mainItem, mainMapIndex) => {
      if (mainIndex === mainMapIndex) {
        return {
          ...mainItem,
          sub_specialities: mainItem?.sub_specialities?.map(
            (subItem, subMapIndex) => {
              if (subMapIndex == subIndex) {
                return {
                  ...subItem,
                  summary: subItem?.summary?.map((item, mapIndex) => {
                    if (index == mapIndex) {
                      return {
                        ...item,

                        nutrient_summary: item.nutrient_summary.map((reccommandationItem, recommandationIndex) => {
                          if (recommandationIndex == nutrient_summary_recomindex) {
                            return {
                              ...reccommandationItem,
                              recommendation: onlyLettersValue
                            }

                          }
                          else {
                            return { ...reccommandationItem };
                          }
                        })
                      };
                    } else {
                      return { ...item };
                    }
                  }),
                };
              } else {
                return { ...subItem };
              }
            }
          ),
        };
      } else {
        return { ...mainItem };
      }
    });

    setNutritions(tempNutritions);
  };

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [date, setDate] = useState();
  const [date1, setDate1] = useState();

  const handleRegistration = (data) => {
    if (data) {
      let temp = { ...data }
      temp["visit_id"] = patientInfo["visit_id"]
      handleEdit(temp);
      setLoading(true);
    }
  };
  const onError = (errors, e) => console.error(errors, e);
  const [gens, setGens] = useState();

  const dateTime = (data) => {
    let result =
      data.slice(8, 10) + "-" + data.slice(5, 7) + "-" + data.slice(0, 4);
    let dt = new Date(data);
    let hours = dt.getHours();
    let AmOrPm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    let minutes = dt.getMinutes();
    result = result + "  " + hours + ":" + minutes + " " + AmOrPm;
    return result;
  };
  const handleInput = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    event.target.value = value.slice(0, 10);
  };

  const handleInputName = (event) => {
    const value = event.target.value.replace(/[^a-zA-Z\s.]/g, "");
    event.target.value = value.slice(0, 10);
  };

  const commaSeperateString = (data) => {
    const impacts = data.map(item => item.impact).filter(impact => impact !== "");
    const impactsString = impacts.join(", ");
    return impactsString;
  }

  return (
    <div style={{ marginTop: "20px" }}>
      {/* <h1></h1> */}
      <Box className="edit-block">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <h3 className="reportEditTitle">Patient Results Edit</h3>
          <Typography varient="h1"> </Typography>

          <Button
            size="small"
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
            Back
          </Button>
        </div>
        {category === "Nutrigenomics" ? (
          <Card className="edit-card">
            <Card
              className="small-editcard"
              style={{ backgroundColor: "#2eaf9f" }}
            >
              <Typography variant="h2">
                DEPARTMENT OF BIOCHEMICAL GENETICS
              </Typography>
            </Card>
            {patientInfo ? (
              <div>
                <form onSubmit={handleSubmit(handleRegistration, onError)}>
                  <div className="Patient-info">
                    <table className="infoBlock">
                      <tbody>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Visit Id</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              disabled
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo["visit_id"]}
                              {...register("visit_id")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Name</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              type="text"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo["name"]}
                              onInput={handleInputName}
                              {...register("name")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Bar Code</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              disabled
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo["barcode_no"]}
                              {...register("barcode_no")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Gender</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo["gender"]}
                              {...register("gender")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Age</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo.age}
                              {...register("age")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Client Name</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo.client_name}
                              {...register("client_name")}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="infoBlock">
                      <tbody>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Email</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo.email}
                              {...register("email")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>UHID Number</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo.uhid_number}
                              {...register("uhid_number")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Mobile</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo.mobile}
                              onInput={handleInput}
                              {...register("mobile")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Ref Doctor</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo.ref_doctor}
                              {...register("ref_doctor")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Sample Type</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo.sample_type}
                              {...register("sample_type")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Collected Date</Typography>
                          </td>
                          <td className="inputField">
                            <Controller
                              width="100%"
                              name="date_of_collection"
                              defaultValue={
                                patientInfo.date_of_collection
                                  ? patientInfo.date_of_collection
                                  : date1
                              }
                              control={control}
                              render={({
                                field: { onChange, ...restField },
                              }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    label="Request Date"
                                    style={{ width: "220px", height: "30px" }}
                                    onChange={(event) => {
                                      onChange(new Date(event).toISOString());
                                      setDate(event);
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    {...restField}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="infoBlock">
                      <tbody>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Date</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={dateTime(patientInfo.created_at)}
                              {...register("created_at")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Received Date</Typography>
                          </td>
                          <td className="inputField">
                            <Controller
                              width="100%"
                              name="date_of_receving"
                              defaultValue={
                                patientInfo.date_of_receiving
                                  ? patientInfo.date_of_receiving
                                  : date1
                              }
                              control={control}
                              render={({
                                field: { onChange, ...restField },
                              }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    label="Request Date"
                                    style={{ width: "220px", height: "30px" }}
                                    onChange={(event) => {
                                      onChange(new Date(event).toISOString());
                                      setDate(event);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        className="inputField"
                                        {...params}
                                      />
                                    )}
                                    {...restField}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Registration Date</Typography>
                          </td>
                          <td className="inputField">
                            <Controller
                              width="100%"
                              name="date_of_regestration"
                              defaultValue={
                                patientInfo.date_of_regestration
                                  ? patientInfo.date_of_regestration
                                  : date
                              }
                              control={control}
                              render={({
                                field: { onChange, ...restField },
                              }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    label="Request Date"
                                    style={{ width: "220px", height: "30px" }}
                                    onChange={(event) => {
                                      onChange(new Date(event).toISOString());
                                      setDate(event);
                                    }}
                                    renderInput={(params) => (
                                      <TextField fullWidth {...params} />
                                    )}
                                    {...restField}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Reported Date</Typography>
                          </td>
                          <td className="inputField">
                            <Controller
                              width="100%"
                              name="date_of_reporting"
                              defaultValue={
                                patientInfo.date_of_reporting
                                  ? patientInfo.date_of_reporting
                                  : date
                              }
                              control={control}
                              render={({
                                field: { onChange, ...restField },
                              }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    label="Request Date"
                                    style={{ width: "220px", height: "30px" }}
                                    onChange={(event) => {
                                      onChange(new Date(event).toISOString());
                                      setDate(event);
                                    }}
                                    renderInput={(params) => (
                                      <TextField fullWidth {...params} />
                                    )}
                                    {...restField}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Hospital Name</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo.hospital_name}
                              {...register("hospital_name")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Client Add</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo.client_add}
                              {...register("client_add")}
                            />
                          </td>
                        </tr>
                        <tr className="item">
                          <td className="inputname">
                            <Typography>Client Code</Typography>
                          </td>
                          <td className="inputField">
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              defaultValue={patientInfo.client_code}
                              {...register("client_code")}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="categoryDetail">
                    <Typography variant="h6">Nutrigenomics</Typography>
                  </div>
                  <Card className="macronutrient-block">
                    {nutritions?.length &&
                      nutritions.map((item, mainIndex) => {
                        return (
                          <table key={mainIndex}>
                            <thead>
                              <tr>
                                <Typography className="heading">
                                  {item.speciality}
                                </Typography>
                              </tr>
                              <tr>
                                <th style={{ width: "250px" }}>Nutrient</th>
                                <th style={{ width: "300px" }}>
                                  Impact of Genotype
                                </th>
                                <th style={{ width: "350px" }}>
                                  Recommendation
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.sub_specialities?.length
                                ? item?.sub_specialities.map(
                                  (subItem, subIndex) => {
                                    return (
                                      <React.Fragment key={subIndex}>
                                        <tr>
                                          <h4>{subItem?.sub_speciality}</h4>
                                        </tr>
                                        {subItem?.summary?.length
                                          ? subItem?.summary?.map(
                                            (sum, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td>{sum.nutrient_type}</td>

                                                  <td>
                                                    {sum.nutrient_summary?.length ?
                                                      sum.nutrient_summary.map((nutrient_summary_item, nutrient_summary_index) => {
                                                        return (
                                                          <ul key={nutrient_summary_index} >
                                                            {nutrient_summary_item?.impact ?
                                                              <li> {nutrient_summary_item?.impact}</li> : ""}
                                                          </ul>
                                                        )
                                                      })
                                                      : ""}
                                                  </td>

                                                  <td>
                                                    {sum.nutrient_summary?.length ?

                                                      sum.nutrient_summary.map((nutrient_recommadation_item, nutrient_summary_recomindex) => {
                                                        return (
                                                          <tr key={nutrient_summary_recomindex} >
                                                            {nutrient_recommadation_item?.impact ?
                                                              <Select
                                                                value={
                                                                  nutrient_recommadation_item?.recommendation ||
                                                                  ""
                                                                }
                                                                onChange={(e) =>
                                                                  handleRecommendationChange(
                                                                    mainIndex,
                                                                    subIndex,
                                                                    index,
                                                                    nutrient_summary_recomindex,
                                                                    e.target.value
                                                                  )
                                                                }
                                                                sx={{ maxWidth: "100%" }}
                                                                variant="outlined"
                                                              >
                                                                <MenuItem value="Adequate">
                                                                  Adequate
                                                                </MenuItem>
                                                                <MenuItem value="Supplements required">
                                                                  Supplements Required
                                                                </MenuItem>
                                                                <MenuItem value="Foods to avoid">
                                                                  Foods To Avoid
                                                                </MenuItem>
                                                                <MenuItem value="None">
                                                                  None
                                                                </MenuItem>
                                                              </Select> : ""}
                                                          </tr>
                                                        )
                                                      })
                                                      : ""}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                          : ""}
                                      </React.Fragment>
                                    );
                                  }
                                )
                                : ""}
                            </tbody>
                          </table>
                        );
                      })}
                  </Card>
                  <div
                    className="macronutrient-block"
                    style={{
                      marginTop: "2rem",
                      background: "#f5f5f5 !important",
                    }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th style={{ width: "250px" }}>Adequate</th>
                          <th style={{ width: "300px" }}>
                            Supplements required
                          </th>
                          <th style={{ width: "350px" }}>Foods to avoid</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {adeqate["adequate"]?.length
                              ? adeqate["adequate"]?.map((item) => (
                                <div>{item}</div>
                              ))
                              : ""}
                          </td>
                          <td>
                            {adeqate["supplements_required"]?.length
                              ? adeqate["supplements_required"]?.map((item) => (
                                <div>{item}</div>
                              ))
                              : ""}
                          </td>
                          <td>
                            {adeqate["avoid"]?.length
                              ? adeqate["avoid"]?.map((item) => (
                                <div>{item}</div>
                              ))
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Button
                      className="update-btn"
                      variant="contained"
                      type="submit"
                    >
                      Update
                    </Button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )
            }
          </Card >
        ) : (
          <Card className="edit-card">
            <Card
              className="small-editcard"
              style={{ backgroundColor: "#2eaf9f" }}
            >
              <Typography variant="h2">
                DEPARTMENT OF BIOCHEMICAL GENETICS
              </Typography>
            </Card>
            {patientInfo ? (
              <form onSubmit={handleSubmit(handleRegistration, onError)}>
                <div className="Patient-info">
                  <table className="infoBlock">
                    <tbody>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Visit Id</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            disabled
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo["visit_id"]}
                            {...register("visit_id")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Name</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            type="text"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo["name"]}
                            onInput={handleInputName}
                            {...register("name")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Bar Code</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            disabled
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo["barcode_no"]}
                            {...register("barcode_no")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Gender</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo["gender"]}
                            {...register("gender")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Age</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo.age}
                            {...register("age")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Client Name</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo.client_name}
                            {...register("client_name")}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="infoBlock">
                    <tbody>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Email</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo.email}
                            {...register("email")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>UHID Number</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo.uhid_number}
                            {...register("uhid_number")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Mobile</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo.mobile}
                            onInput={handleInput}
                            {...register("mobile")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Ref Doctor</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo.ref_doctor}
                            {...register("ref_doctor")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Sample Type</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo.sample_type}
                            {...register("sample_type")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Collected Date</Typography>
                        </td>
                        <td className="inputField">
                          <Controller
                            width="100%"
                            name="date_of_collection"
                            defaultValue={
                              patientInfo.date_of_collection
                                ? patientInfo.date_of_collection
                                : date1
                            }
                            control={control}
                            render={({ field: { onChange, ...restField } }) => (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Request Date"
                                  style={{ width: "220px", height: "30px" }}
                                  onChange={(event) => {
                                    onChange(new Date(event).toISOString());
                                    setDate(event);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  {...restField}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="infoBlock">
                    <tbody>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Date</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={dateTime(patientInfo.created_at)}
                            {...register("created_at")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Received Date</Typography>
                        </td>
                        <td className="inputField">
                          <Controller
                            width="100%"
                            name="date_of_receving"
                            defaultValue={
                              patientInfo.date_of_receiving
                                ? patientInfo.date_of_receiving
                                : date1
                            }
                            control={control}
                            render={({ field: { onChange, ...restField } }) => (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Request Date"
                                  style={{ width: "220px", height: "30px" }}
                                  onChange={(event) => {
                                    onChange(new Date(event).toISOString());
                                    setDate(event);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      className="inputField"
                                      {...params}
                                    />
                                  )}
                                  {...restField}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Registration Date</Typography>
                        </td>
                        <td className="inputField">
                          <Controller
                            width="100%"
                            name="date_of_regestration"
                            defaultValue={
                              patientInfo.date_of_regestration
                                ? patientInfo.date_of_regestration
                                : date
                            }
                            control={control}
                            render={({ field: { onChange, ...restField } }) => (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Request Date"
                                  style={{ width: "220px", height: "30px" }}
                                  onChange={(event) => {
                                    onChange(new Date(event).toISOString());
                                    setDate(event);
                                  }}
                                  renderInput={(params) => (
                                    <TextField fullWidth {...params} />
                                  )}
                                  {...restField}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Reported Date</Typography>
                        </td>
                        <td className="inputField">
                          <Controller
                            width="100%"
                            name="date_of_reporting"
                            defaultValue={
                              patientInfo.date_of_reporting
                                ? patientInfo.date_of_reporting
                                : date
                            }
                            control={control}
                            render={({ field: { onChange, ...restField } }) => (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Request Date"
                                  style={{ width: "220px", height: "30px" }}
                                  onChange={(event) => {
                                    onChange(new Date(event).toISOString());
                                    setDate(event);
                                  }}
                                  renderInput={(params) => (
                                    <TextField fullWidth {...params} />
                                  )}
                                  {...restField}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Hospital Name</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo.hospital_name}
                            {...register("hospital_name")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Client Add</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo.client_add}
                            {...register("client_add")}
                          />
                        </td>
                      </tr>
                      <tr className="item">
                        <td className="inputname">
                          <Typography>Client Code</Typography>
                        </td>
                        <td className="inputField">
                          <TextField
                            size="small"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            defaultValue={patientInfo.client_code}
                            {...register("client_code")}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="genes-block">
                  <Typography variant="h5" className="gene-heading">
                    Gene
                  </Typography>
                  <Box sx={{ flexGrow: 1 }}>
                    <div className="form-fields">
                      {preview &&
                        Object.keys(preview).map((item, index) => {
                          return (
                            <div key={index} className="single-formField">
                              <Typography className="form-heading">
                                {item}
                              </Typography>
                              <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={preview[item]}
                                sx={{ width: "100%" }}
                                onChange={(e) => {
                                  gens[item] = e.target.value;
                                  setGens(gens);
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </Box>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Button
                    className="update-btn"
                    variant="contained"
                    type="submit"
                  >
                    Update
                  </Button>
                </div>
              </form>
            ) : (
              ""
            )}
          </Card>
        )}
      </Box >
      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingResult}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div >
  );
}
export default EditFiles;
