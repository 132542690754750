import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingComponent from "../../../Core/LoadingComponent";
import EditPatientDetails from "../EditPatientDetails";
import ViewPatientDetails from "../ViewPatientDetails";
const NutriGenomicsView = () => {
  const dispatch = useDispatch();
  const { report_id, result_file_id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const AuthStateData = useSelector((state) => state.Signin);

  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState("");
  const [successMessage, setSuccessMesssage] = useState();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [resultFile, setResultFile] = useState({});
  const [editResultFile, setEditResultFile] = useState({});
  const [message, setMessage] = useState("");
  const [processSuccess, setProcessSuccess] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };
  const getPreview = async () => {
    setLoading(true);
    try {
      const category = searchParams.get("category");
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/preview-report/${report_id}?category=NUTRIGENOMIC`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resText = await response.text();
      if (response.status == 200 || response.status == 201) {
        setPreview(resText);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getPrimaryReportById = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        setResultFile(resJson.data ? resJson.data : {});
        setEditResultFile(resJson.data ? resJson.data : {});
        getPreview();
      } else if (response.status == 422) {
        setMessage(resJson.data);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onChangePatientDetails = async (payload) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}/update-patient-demographics`;
      let options = {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
          "content-type": "application/json",
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        setProcessSuccess(resJson?.message);
        setShowSuccessAlert(true);
        setTimeout(() => {
          setProcessSuccess("");
          setShowSuccessAlert(false);
        }, 1500);
        getPreview();
        setIsEditOpen(false);
        getPrimaryReportById();
        window.location.reload();
        return resJson;
      } else if (response.status == 422) {
        setMessage(resJson.errors);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPreview();
    getPrimaryReportById();
  }, []);

  return (
    <div id="nutriGenomicsView">
      {resultFile?.category == "NUTRIGENOMICS" ? (
        <>
        <div id="summaryDetails">
          {isEditOpen ? (
            <EditPatientDetails
              isEditOpen={isEditOpen}
              resultFile={editResultFile}
              setResultFile={setEditResultFile}
              onSaveUserDetails={onChangePatientDetails}
              setIsEditOpen={setIsEditOpen}
              message={message}
              setMessage={setMessage}
            />
          ) : (
            <ViewPatientDetails
              setIsEditOpen={setIsEditOpen}
              resultFile={resultFile}
            />
          )}
        </div>
        <div className="editBtnGrp">
        <h6 className="headingText">Nutrigenomics</h6>
        <Button
          disableElevation={true}
          className="editBtn"
          variant="outlined"
          startIcon={
            <img src="/edit-pink-icon.svg" alt="" width={12} height={12} />
          }
          onClick={() =>
            navigate(
              `/grand-reports/${result_file_id}/update-report-template/${report_id}/nutrigenomics/edit`
            )
          }
        >
          Edit
        </Button>
      </div>
        </>
      ) : (
        ""
      )}
      <div className="rishDisorder_pdf">
        <div className="previewBlock">
          <div
            className="htmlPreview"
            dangerouslySetInnerHTML={{ __html: preview }}
          ></div>
        </div>
      </div>
      <LoadingComponent loading={loading} />
    </div>
  );
};

export default NutriGenomicsView;
