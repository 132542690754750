import { createSlice } from "@reduxjs/toolkit";


export const SigninSlice = createSlice({
    name: 'Signin',
    initialState: {
        user_details: {
        },
        access_token: "",
        refresh_token: ""
    },
    reducers: {
        setUserDetails(state, data) {
            state.user_details = data.payload
        },
        setAccessToken(state, data) {
            state.access_token = data.payload
        },
        setRefreshToken(state, data) {
            state.refresh_token = data.payload
        }
    }
})
export const { setUserDetails, setAccessToken, setRefreshToken } = SigninSlice.actions

export default SigninSlice.reducer