import { Button, TextField } from "@mui/material";
import Papa from "papaparse";
import { useState } from "react";
import { toast, Toaster } from 'sonner';
import * as XLSX from 'xlsx';
import styles from "../index.module.css";
import { sampleData } from "./SampleData";


const WeeklyDietPlannerTable = ({
  weeklyDietPlanData,
  setWeeklyDietPlanData,
  loading,
  setLoading,
}) => {
  const [data, setData] = useState([]);

  const handleFileUpload = (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    
    if (file) {
      setLoading(true);
      const fileExtension = file.name.split('.').pop().toLowerCase(); 
  
      if (fileExtension === 'csv') {
        Papa.parse(file, {
          complete: function (results) {
            const success = processParsedData(results.data);
            fileInput.value = ''; 
            if (success) {
              toast.success("File Uploaded Successfully");
              setLoading(false);
            }
          },
          header: false,
        });
      } else if (fileExtension === 'xlsx') {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
          
          const success = processParsedData(worksheet);
          fileInput.value = ''; 
          if (success) {
            toast.success("File Uploaded Successfully");
            setLoading(false);
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        toast.error('Unsupported file format');
        setLoading(false);
        fileInput.value = ''; 
      }
    }
  };
  
  const processParsedData = (parsedData) => {
    setData(parsedData);
  
    const isEmpty = parsedData.every(row => row.every(cell => String(cell).trim() === ''));
    
    if (isEmpty) {
      toast.error('File has empty data');
      setLoading(false);
      return false; 
    }
    
    
    if (parsedData[0][0] === 'day' && parsedData[0][7] === 'bed_time') {
      const updatedPlanData = parsedData.slice(1, 8).map((row) => ({
        day: row[0],
        diet_plan: {
          early_morning: row[1],
          break_fast: row[2],
          mid_morning: row[3],
          lunch: row[4],
          evening_snacks: row[5],
          dinner: row[6],
          bed_time: row[7],
        },
      }));
      setWeeklyDietPlanData(updatedPlanData);
      return true; 
    } else {
      toast.error('File does not match the required format');
      setLoading(false);
      return false; 
    }
    setLoading(false);
  };


  const updatedFeildsDetails = (index, feildName, value) => {
    let details = [...weeklyDietPlanData];
    if (value) {
      details[index]["diet_plan"][feildName] = value;
    } else {
      delete details[index]["diet_plan"][feildName];
    }
    setWeeklyDietPlanData(details);
  };

  const downloadCSV = () => {
    let csvContent = "day,early_morning,break_fast,mid_morning,lunch,evening_snacks,dinner,bed_time\n";
    sampleData.forEach((item) => {
      csvContent += `${item.day},${item.diet_plan.early_morning},${item.diet_plan.break_fast},${item.diet_plan.mid_morning},${item.diet_plan.lunch},${item.diet_plan.evening_snacks},${item.diet_plan.dinner},${item.diet_plan.bed_time}\n`;
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Example-diet-plan.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error('Your browser does not support downloading files.');
    }
  };

  return (
    <>

      <div className="App" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
        <input
          type="file"
          id="fileInput"
          accept=".csv,.xlsx,.xls"
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        <label htmlFor="fileInput" style={{ margin: 0 }}>
          <Button
            className="editBtn"
            variant="outlined"
            component="span"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '6px 16px',
              textTransform: 'none',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              fontSize: '0.875rem',
              lineHeight: '1.75',
              minWidth: '64px',
              height: '36px'
            }}
          >
            <img src="/upload-pink-icon.svg" alt="" width={12} height={12} style={{ marginRight: '8px' }} />
            Upload File
          </Button>
        </label>
        <Button
          disableElevation={true}
          className="editBtn"
          variant="outlined"
          startIcon={<img src="/download-pink-icon.svg" alt="" width={12} height={12} />}
          onClick={downloadCSV}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px 16px',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            fontSize: '0.875rem',
            lineHeight: '1.75',
            minWidth: '64px',
            height: '36px'
          }}
        >
          Sample Data
        </Button>
      </div>
      <div className="macronutrient-block">
        <table>
          <thead>
            <tr>
              <th>Day</th>
              <th>Early Morning</th>
              <th>Breakfast</th>
              <th>Mid Morning</th>
              <th>Lunch</th>
              <th>Evening Snacks</th>
              <th>Dinner</th>
              <th>Bed Time</th>
            </tr>
          </thead>
          <tbody>
            {weeklyDietPlanData?.length
              ? weeklyDietPlanData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.day.toUpperCase()}</td>
                    <td>
                      <TextField
                        sx={{
                          width: "100px",
                          "& .MuiInputBase-root": { padding: 0.2 },
                        }}
                        maxRows={6}
                        minRows={6}
                        multiline
                        value={item?.diet_plan["early_morning"] || ""}
                        onChange={(e) =>
                          updatedFeildsDetails(index, "early_morning", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <TextField
                        sx={{
                          width: "100px",
                          "& .MuiInputBase-root": { padding: 0 },
                        }}
                        maxRows={6}
                        minRows={6}
                        multiline
                        value={item?.diet_plan["break_fast"]}
                        onChange={(e) =>
                          updatedFeildsDetails(index, "break_fast", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <TextField
                        sx={{
                          width: "100px",
                          "& .MuiInputBase-root": { padding: 0 },
                        }}
                        maxRows={6}
                        minRows={6}
                        multiline
                        value={item?.diet_plan["mid_morning"]}
                        onChange={(e) =>
                          updatedFeildsDetails(index, "mid_morning", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <TextField
                        sx={{
                          width: "100px",
                          "& .MuiInputBase-root": { padding: 0 },
                        }}
                        maxRows={6}
                        minRows={6}
                        multiline
                        value={item?.diet_plan["lunch"]}
                        onChange={(e) =>
                          updatedFeildsDetails(index, "lunch", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <TextField
                        sx={{
                          width: "100px",
                          "& .MuiInputBase-root": { padding: 0 },
                        }}
                        maxRows={6}
                        minRows={6}
                        multiline
                        value={item?.diet_plan["evening_snacks"]}
                        onChange={(e) =>
                          updatedFeildsDetails(index, "evening_snacks", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <TextField
                        sx={{
                          width: "100px",
                          "& .MuiInputBase-root": { padding: 0 },
                        }}
                        maxRows={6}
                        minRows={6}
                        multiline
                        value={item?.diet_plan["dinner"]}
                        onChange={(e) =>
                          updatedFeildsDetails(index, "dinner", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <TextField
                        sx={{
                          width: "100px",
                          "& .MuiInputBase-root": { padding: 0 },
                        }}
                        maxRows={6}
                        minRows={6}
                        multiline
                        value={item?.diet_plan["bed_time"]}
                        onChange={(e) =>
                          updatedFeildsDetails(index, "bed_time", e.target.value)
                        }
                      />
                    </td>
                  </tr>
                );
              })
              : <tr><td colSpan="8">File has empty data</td></tr>}
          </tbody>
        </table>
      </div>
      <Toaster closeButton richColors position="top-right" />
    </>
  );
};

export default WeeklyDietPlannerTable;
