import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../LoadingComponent";
import FooterOfPatientReports from "./FooterOfPatientReports";
import styles from "./MainPage.module.css";
import ReportsCard from "./ReportsCard";

const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AuthStateData = useSelector((state) => state.Signin);

  const [reportsData, setReportsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changeNumber, setChangeNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [noReportsData, setNoReportsData] = useState("");

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const getPatientReports = async () => {
    setLoading(true);
    try {
      let options = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
        body: JSON.stringify({
          mobile: phoneNumber,
        }),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/summary-reports/patient/reports`,
        options
      );
      let responseData = await response.json();
      if (response?.status >= 200 && response?.status < 300) {
        setReportsData(responseData?.data);
        setNoReportsData('')
      } else if (response?.status == 422) {
        setErrorMessage(responseData?.errors?.mobile)
      } else if (response?.status == 400) {
        setNoReportsData(responseData.message)
      }
      else if (response?.status == 403 || response?.status == 401) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Add class to body when component mounts
    document.body.classList.add("patient-reports-page");
    // Remove class from body when component unmounts
    return () => {
      document.body.classList.remove("patient-reports-page");
    };
  }, []);

  //phone number textfeild
  const handleInput = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    event.target.value = value.slice(0, 10);
  };

  return (
    <>
      <header className={styles.mobileHeader}>
        <div>

          <img src="/patientreports/dna-decoder-web-logo.svg" alt="" className={styles.headerWebLogo} />
          <img src="/patientreports/dna-decoder-logo.svg" alt="" className={styles.headerMobileLogo} />

        </div>
        <p className={styles.headerText}>
          ONE TEST, ONE TIME FOR ONE LIFE
        </p>
        {/* <Button className={styles.helpBtn} startIcon={<img src="/patientreports/info-icon.svg" alt="" />}>
          Help
        </Button> */}
      </header>
      <section id="patientReports">
        <div className="headingBlock">
          <h1>Welcome To DNA Decoder</h1>
          <p>
            Please provide your Mobile Number below in order to <br className="break" /> retrieve
            your details.
          </p>
        </div>
        <form action="">
          <div className="formItem">
            <TextField
              className={styles.mobilenumberinputbox}
              color="primary"
              placeholder="Enter Mobile Number"
              required={true}
              value={phoneNumber}
              disabled={changeNumber == true || reportsData?.length}
              variant="outlined"
              onInput={handleInput}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                setErrorMessage('')
                setReportsData(null);
                setNoReportsData('')
              }}
              startAdornment={
                <InputAdornment position="start">
                  <PhoneIphoneOutlinedIcon />
                </InputAdornment>
              }

              sx={{
                "& .MuiInputBase-root": { height: "35px", color: "#000", fontSize: 'clamp(12px, 0.83vw, 16px)', fontFamily: "'IBM Plex Sans', sans-serif" },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: "1px solid #000", borderRadius: "8px"
                }
              }}
            />
            <p className="errorMessage">{errorMessage}</p>
          </div>
          <div className="buttonsGrp">
            {reportsData?.length ? (
              <button
                className={styles.changeNumberBtn} onClick={() => {
                  setChangeNumber(false);
                  setReportsData(null);
                }}
              >
                <p className={styles.btnText}>
                  Change Number</p>


              </button>
            ) : (
              ""
            )}
            <button
              className={styles.submitbutton}
              onClick={() => {
                getPatientReports();
              }}
              disabled={loading || !phoneNumber ? true : false}
            >
              <p className={styles.actionname}>Submit</p>
            </button>
          </div>
          <div class="lineShapeLeft">
            <img alt="" src="/patient-reports-line-icon-one.png" />
          </div>
          <div class="lineShapeRight">
            <img alt="" src="/patient-reports-line-icon-two.png" />
          </div>
        </form>
        {noReportsData ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/NoDataImageSummaryReport.svg"
              height={200}
              width={200}
              alt="nodata"
            />
            <p style={{ fontSize: "clamp(18px, 2.72vw, 22px)" }}>
              No Reports
            </p>
          </div>
        ) : (
          <div className="listItemGroup">
            {reportsData?.length
              ? reportsData?.map((item, index) => {
                return (
                  <div className="listItem">
                    <ReportsCard reportsData={item} key={index} />
                  </div>
                );
              })
              : ""}
          </div>
        )}
        <div class="shape">
          <img alt="" src="/dnapatternimage@2x.png" />
        </div>
        <footer className={styles.footer}>
          <FooterOfPatientReports />
        </footer>
        <LoadingComponent loading={loading} />
      </section>
    </>
  );
};
export default MainPage;