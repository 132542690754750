import { Edit } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../../Core/LoadingComponent";
import styles from "./../index.module.css";
import DietaryGuidelinesTable from "./DietaryGuidelinesTable";
import DietaryGuidelinesDialog from "./DietaryGuidelinesDialog";

const DietaryGuidelines = () => {
  const AuthStateData = useSelector((state) => state.Signin);
  const userType = useSelector((state) => state.Signin.user_details?.user_type);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { report_id, result_file_id } = useParams();

  const [isEditOpenOrNot, setIsEditOpenOrNot] = useState(false);
  const [foodsToAvoid, setFoodsToAvoid] = useState([]);
  const [foodsToInclude, setFoodsToInclude] = useState([]);
  const [dataLength, setDataLength] = useState(1);
  const [loading, setLoading] = useState("");
  const [resultFile, setResultFile] = useState({});
  const [message, setMessage] = useState("");
  const [processSuccess, setProcessSuccess] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [preview, setPreview] = useState("");
  const [open, setOpen] = useState(false);
  const [foodsAvoid, setFoodsAvoid] = useState([]);
  const [foodsInclude, setFoodsInclude] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const setDataForDietaryGuidelines = (data) => {
    const { foods_to_avoid, foods_to_include } = data;
    const avoid = foods_to_avoid ? foods_to_avoid : [];
    const include = foods_to_include ? foods_to_include : [];
    setFoodsToAvoid(avoid);
    setFoodsToInclude(include);
    setFoodsAvoid(avoid);
    setFoodsInclude(include);
    let datalen = Math.max(avoid.length, include.length);
    setDataLength(datalen ? datalen : 1);
  };

  const getPrimaryReportById = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        setResultFile(resJson.data ? resJson.data : {});
        setDataForDietaryGuidelines(resJson.data ? resJson.data : {});
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/update-report-template/${report_id}`;
      const payload = {
        foods_to_avoid: foodsToAvoid.filter(e => e),
        foods_to_include: foodsToInclude.filter(e => e),
      };
      let options = {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
          "content-type": "application/json",
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setProcessSuccess(resJson?.message);
        setShowSuccessAlert(true);
        setIsEditOpenOrNot(false);
        setTimeout(() => {
          setProcessSuccess("");
          setShowSuccessAlert(false);
        }, 1500);
        await getPreview();
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getPreview = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/preview-report/${report_id}?category=DIETARY_GUIDELINES`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resText = await response.text();
      if (response.status === 200 || response.status === 201) {
        setPreview(resText);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const onEditClick = () => {
    setIsEditOpenOrNot(true);
    getPrimaryReportById();
  };

  useEffect(() => {
    if (!AuthStateData?.access_token) {
      navigate("/");
    }
    getPrimaryReportById();
    getPreview();
  }, []);

  return (
    <div className={styles.detailscontainer} id="dietaryGuidelines">
      <div className="editBtnGrp">
        <h6 className="headingText">Dietary Guidelines</h6>
        {!isEditOpenOrNot ? (
          <div>
            {!resultFile?.is_finalized === true || userType == "DOCTOR" ? (
              <Button
                disableElevation={true}
                className="editBtn"
                variant="outlined"
                startIcon={
                  <img
                    src="/edit-pink-icon.svg"
                    alt=""
                    width={12}
                    height={12}
                  />
                }
                onClick={onEditClick}
              >
                Edit
              </Button>
            ) : (
              ""
            )}
          </div>
        ) : (
          <Button
            className="editBtn"
            variant="outlined"
            onClick={handleClickOpen}
          >
            Add Dietary Guidelines
          </Button>
        )}
      </div>
      {isEditOpenOrNot ? (
        <div className="processSummaryBlock">
          <DietaryGuidelinesTable
            handleClose={handleClose}
            open={open}
            resultFile={resultFile}
            foodsToAvoid={foodsToAvoid}
            setFoodsToAvoid={setFoodsToAvoid}
            foodsToInclude={foodsToInclude}
            setFoodsToInclude={setFoodsToInclude}
            dataLength={dataLength}
            setDataLength={setDataLength}
            setFoodsAvoid={setFoodsAvoid}
            foodsAvoid={foodsAvoid}
            setFoodsInclude={setFoodsInclude}
            foodsInclude={foodsInclude}
          />
          <div className="summaryEditBtnGrp">
            <Button
              className="cancelBtn"
              variant="text"
              onClick={() => {
                setIsEditOpenOrNot(false)
                setFoodsAvoid([]);
                setFoodsInclude([]);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="saveBtn"
              onClick={() => {
                onContinue();
                setFoodsAvoid([]);
                setFoodsInclude([]);
              }}
            >
              Update
            </Button>
          </div>
        </div>
      ) : (
        <div className="previewBlock">
          <div
            className="htmlPreview"
            style={{ width: "95%", margin: "0 auto" }}
            dangerouslySetInnerHTML={{ __html: preview }}
          ></div>
        </div>
      )}
      <LoadingComponent loading={loading} />
      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {processSuccess}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DietaryGuidelines;
