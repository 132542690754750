import { TextField } from "@mui/material";
import { useCallback, useEffect } from "react";
import styles from "./TestingInformation.module.css";

const TestingInformation = ({ data, setData, errorMessages, setErrorMessage, setErrorMessages, isData }) => {
  const updateFields = useCallback(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    if (Object.keys(data)?.length) {
      updateFields();
    }
  }, [data, updateFields]);

  return (
    <div className={styles.testinginformation}>
      <div className={styles.headinglable}>
        <div className={styles.iconcontainer}>
          <img className={styles.icon} alt="" src="/icon1.svg" />
        </div>
        <h2 className={styles.personalDetails}>Testing Information</h2>
      </div>
      <div className={styles.testclientname}>
        <div className={styles.test}>
          <div className={styles.lablecontainer}>
            <label className={styles.lable}>
              Test<strong style={{ color: "red" }}>*</strong>
            </label>
          </div>
          <TextField
            className={styles.inputField}
            disabled={isData == false}
            color="primary"
            placeholder="Enter Test"
            variant="outlined"
            sx={{
              "& .MuiInputBase-root": {
                height: "30px",
                fontSize: "clamp(12px,0.72vw,16px)",
                fontFamily: "'IBM Plex Sans', Sans-serif",
                fontWeight: "500",
              },
              "& .MuiOutlinedInput-input": {
                padding: "5px 14px !important",
              },
            }}
            value={data?.test ? data?.test : ""}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                test: e.target.value,
              }));
            }}
          />
          <p style={{ color: "red", fontSize: "16px" }}>
            {errorMessages["test"]}
          </p>
        </div>
        <div className={styles.test}>
          <div className={styles.lablecontainer}>
            <label className={styles.lable}>Client Name</label>
          </div>
          <TextField
            className={styles.inputField}
            disabled={isData == false}
            color="primary"
            placeholder="Enter Client Name"
            variant="outlined"
            sx={{
              "& .MuiInputBase-root": {
                height: "30px",
                fontSize: "clamp(12px,0.72vw,16px)",
                fontFamily: "'IBM Plex Sans', Sans-serif",
                fontWeight: "500",
              },
              "& .MuiOutlinedInput-input": {
                padding: "5px 14px !important",
              },
            }}
            value={data?.client_name ? data?.client_name : ""}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                client_name: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.test}>
          <div className={styles.lablecontainer}>
            <label className={styles.lable}>
              Referring Doctor<strong style={{ color: "red" }}>*</strong>
            </label>
          </div>
          <TextField
            className={styles.inputField}
            disabled={isData == false}
            color="primary"
            placeholder="Enter Referring Doctor"
            variant="outlined"
            sx={{
              "& .MuiInputBase-root": {
                height: "30px",
                fontSize: "clamp(12px,0.72vw,16px)",
                fontFamily: "'IBM Plex Sans', Sans-serif",
                fontWeight: "500",
              },
              "& .MuiOutlinedInput-input": {
                padding: "5px 14px !important",
              },
            }}
            value={data?.ref_doctor ? data?.ref_doctor : ""}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                ref_doctor: e.target.value,
              }));
            }}
          />
          <p style={{ color: "red", fontSize: "16px" }}>
            {errorMessages["ref_doctor"]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestingInformation;
