import { Button, Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OtpInput from "react18-input-otp";
import { Toaster, toast } from "sonner";
import LoadingComponent from "../LoadingComponent";
import styles from "./OtpVerification.module.css";

const OtpDrawerVerification = ({
    otpDialogOpen,
    setOtpDialogOpen,
    reportDetails,
    getOtpFromMobile,
}) => {

    const [otp, setOtp] = useState("");
    const [seconds, setSeconds] = useState(30);
    const [minutes, setMinutes] = useState(0);
    const descriptionElementRef = useRef < HTMLElement > null;
    const [otpError, setOtpError] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const AuthStateData = useSelector((state) => state.Signin);
    const [loading, setLoading] = useState(false);

    const handleLogout = async () => {
        dispatch({
            type: "SIGN_OUT",
            payload: {},
        });
        navigate("/");
    };

    //verify otp
    const verifyOtp = async (otpNumber) => {
        setLoading(true);
        try {
            let options = {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.access_token}`,
                }),
                body: JSON.stringify({
                    mobile: reportDetails?.mobile,
                    otp: otpNumber ? otpNumber : otp,
                }),
            };

            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/summary-reports/patient-reports/verify-otp`,
                options
            );
            let responseData = await response.json();
            if (response?.status >= 200 && response?.status < 300) {
                toast.success(responseData?.message);
                await getDownloadReportUrl();
                setOtp("");
                setOtpError("");
            } else if (responseData?.success == false) {
                setOtpError(responseData?.message);
            } else if (response?.status == 403 || response?.status == 401) {
                handleLogout();
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    //get download report url
    const getDownloadReportUrl = async () => {
        setLoading(true);
        try {
            let options = {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.access_token}`,
                }),
                body: JSON.stringify({
                    report_path: reportDetails?.report_path,
                }),
            };
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/summary-reports/patient/reports/download`,
                options
            );
            let responseData = await response.json();
            if (response?.status >= 200 && response?.status < 300) {
                toast.success("Report Download Successfully");
                setOtpDialogOpen(false);
                await downloadReport(responseData?.data?.download_url);
            } else if (response?.status == 403 || response?.status == 401) {
                handleLogout();
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const downloadReport = async (url) => {
        if (!url) {
            return;
        }
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement("a");
            downloadLink.href = blobUrl;
            downloadLink.download = "Patient-report" + new Date().toLocaleDateString() + new Date().toLocaleTimeString();
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            // Clean up the blob URL
            window.URL.revokeObjectURL(blobUrl);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
            descriptionElement?.focus();
        }
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
                // dispatch(setOtpCountDown(seconds - 1));
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(30);
                    // dispatch(setOtpCountDown(30));
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    return (
        <Drawer
            open={otpDialogOpen}
            anchor={"bottom"}
            sx={{
                '& .MuiPaper-root': {
                    width: "100%", maxWidth: "500px", margin: "0 auto", borderRadius: "12px 12px 0 0"
                }
            }}
        >
            <div className={styles.otpverificationDrawer} id="verificationOTPContainer">
                <div className={styles.drawerHeader}>

                    <h2>Verification Required</h2>

                    <button
                        className={styles.closeBtn}
                        onClick={() => {
                            setOtpDialogOpen(false);
                            setOtp("");
                            setOtpError("");
                        }}
                    >
                        <img className={styles.closeicon} alt="" src="/closeicon.svg" />
                    </button>
                </div>


                <div className="drawerHeader">
                    <p>
                        To ensure the security and privacy of your medical information,
                        please verify your identity by entering the one-time password (OTP)
                        sent to your mobile number.
                    </p>
                </div>
                <div className="otpInputContainer">
                    <OtpInput
                        value={otp}
                        onChange={(e) => {
                            setOtp(e);
                            if (e.length === 4) {
                                verifyOtp(e);
                                setOtp(e);
                            }
                            setOtpError('')
                        }}
                        numInputs={4}
                        isInputNum
                        shouldAutoFocus
                        inputStyle="otpInputs"
                    />
                    {otpError ? <p className="otpErrorMessage">{otpError}</p> : ""}
                </div>
                <div className={styles.otpdetailscontainer}>
                    <div className={styles.options}>
                        <div className={styles.timerresend}>
                            {!seconds ? (
                                <p style={{ margin: "0", width: "100%", textAlign: "end" }}>
                                    {"Did not receive an OTP?"}
                                    <Button
                                        className={styles.resendbutton}
                                        disableElevation={true}
                                        color="warning"
                                        variant="text"
                                        onClick={() => {
                                            setOtpError("");
                                            getOtpFromMobile(reportDetails?.mobile);
                                            setSeconds(30);
                                        }}
                                    >
                                        Resend
                                    </Button>
                                </p>
                            ) : (
                                ""
                            )}
                            {seconds ? (
                                <p style={{ margin: "0", width: "100%", textAlign: "end" }}>
                                    Resend in {seconds}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
                <button
                    className={styles.drawersubmitbutton}
                    disabled={otp ? false : true}
                    onClick={() => {
                        verifyOtp();
                    }}
                >
                    <p className={styles.submit}>Submit</p>
                </button>

            </div>
            <LoadingComponent loading={loading} />
            <Toaster richColors closeButton position="top-right" />
        </Drawer>
    );
};

export default OtpDrawerVerification;
