import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { AlertTitle, TableContainer, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";

import "../scss/ResultDetails.scss";
import BaseLayout from "../../layouts/BaseLayout";
import { useDispatch, useSelector } from "react-redux";

function ResultDetails() {
  const AuthStateData = useSelector((state) => state.Signin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [barcode, setBarCode] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [loadingResult, setLoadingResult] = useState(false);
  const [filename, setFileName] = useState();
  const [name, setName] = useState("");
  const [finalize, setFinalize] = useState("");
  const [successMessage, setSuccessMesssage] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  let token = localStorage.getItem("code");
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category");

  const { _id, report_id } = useParams();

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
    getDetails();
    handelProcessFile();
  }, []);

  const getDetails = async () => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-file/${_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();

      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        if (resJson.data) {
          setFileName(resJson.data.file_name);
        }
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
      setLoadingResult(false);
    } finally {
      setLoadingResult(false);
    }
  };
  const handelProcessFile = async () => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-file/${_id}/report/${report_id}`;
      if (category === "Nutrigenomics") {
        url = `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/result-file/${_id}/report/${report_id}`;
      }
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();

      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        if (resJson.data) {
          setBarCode(resJson.data.patient_info["barcode_no"]);
          setName(resJson.data.patient_info["name"]);
        }
        if (resJson.data.is_finalized == true) {
          setFinalize(resJson.data.is_finalized);
        }
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoadingResult(false);
      console.error(err);
    } finally {
      setLoadingResult(false);
    }
  };

  const handelFinalize = async () => {
    setLoadingResult(true);
    setShowLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/result-file/${_id}/report/${report_id}/finalize`;
      if (category === "Nutrigenomics") {
        url = `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/result-file/${_id}/report/${report_id}/finalize`;
      }
      let options = {
        method: "PATCH",
        headers: new Headers({
          content_Type: "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      setSuccessMesssage(resJson.message);
      if (resJson.success === true) {
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 1500);
      }
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        setFinalize(resJson.success);
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setShowLoading(false);
      setLoadingResult(false);
      console.error(err);
    } finally {
      setShowLoading(false);
      setLoadingResult(false);
    }
  };

  const handleDownload = async () => {
    setLoadingResult(true);
    setShowLoading(true);
    try {
      let url =
        `${process.env.REACT_APP_API_BASE_URL}/medicines/download/` + report_id;
      if (category === "Nutrigenomics") {
        url =
          `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/download/` +
          report_id;
      }
      let options = {
        method: "POST",
        headers: new Headers({
          content_Type: "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      setSuccessMesssage(resJson.message);
      if (resJson.success === true) {
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 1500);
      }
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);

        window.open(resJson.data);
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setShowLoading(false);
      setLoadingResult(false);
      console.error(err);
    } finally {
      setShowLoading(false);
      setLoadingResult(false);
    }
  };

  return (
    <div id="resultDetails" className="resultFilesContainer">
      <Typography variant="body1" className="navbarNavigation">
        <Link to={`/result-file`}>All Result Files </Link> / {filename}
      </Typography>
      <div>
        <div className="customTable">
          <TableContainer>

          <Table>
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="custm-th">Barcode</TableCell>
                <TableCell className="custm-th" style={{ width: "130px" }}>
                  Status
                </TableCell>
                <TableCell className="custm-th" style={{ width: "250px" }}>
                  Message
                </TableCell>
                {/* <TableCell className="custm-th">Ready To Approve</TableCell> */}
                <TableCell
                  className="custm-th"
                  style={{ width: "180px", textAlign: "center" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table-group">
              <TableRow className="table-item">
                <TableCell className="data">
                  {barcode}
                  <span style={{ color: "#eb5757", display: "block" }}>
                    {name}
                  </span>
                </TableCell>
                <TableCell className="data">
                  <Button
                    className="statusActionButton"
                    variant="contained"
                    size="small"
                  >
                    Completed
                  </Button>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={showLoading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <Snackbar
                    message="Process File is Completed"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Alert severity="success">
                      <AlertTitle>Success</AlertTitle>
                      Result File Processed Successfully
                    </Alert>
                  </Snackbar>
                </TableCell>
                <TableCell className="data">--</TableCell>
                <TableCell className="data action-data">
                  <ul className="actionList">
                    <li>
                      <div
                        onClick={() => {
                          navigate(
                            `/result-file/${_id}/preview-report/${report_id}/preview?category=${category}`
                          );
                        }}
                      >
                        <img
                          src="/preview.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={() => {
                          const editUrl = category
                            ? `/result-file/${_id}/preview-report/${report_id}/edit?category=${category}`
                            : `/result-file/${_id}/preview-report/${report_id}/edit`;
                          navigate(editUrl);
                        }}
                      >
                        <img
                          src="/EditIcon.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                    </li>
                    <li>
                      {finalize ? (
                        <Button
                          className="finalize-btn"
                          variant="contained"
                          size="small"
                          disabled
                        >
                          Finalized
                        </Button>
                      ) : (
                        <Button
                          className="finalize-btn"
                          variant="contained"
                          size="small"
                          onClick={handelFinalize}
                        >
                          Finalize
                        </Button>
                      )}
                    </li>
                    <li>
                      <div
                        onClick={(file) => {
                          handleDownload();
                        }}
                      >
                        <img
                          src="/downloadd.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                    </li>
                  </ul>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          </TableContainer>
        </div>
      </div>
      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingResult}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default ResultDetails;
