import { Edit } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert, AlertTitle, Button, CircularProgress, Snackbar, styled } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./../index.module.css";
import { useSelector } from "react-redux";
import { useState } from "react";

const HeaderComponent = ({ resultFile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userType = useSelector((state) => state.Signin.user_details?.user_type);
  const AuthStateData = useSelector((state) => state.Signin);
  const { report_id, result_file_id, edit } = useParams();

  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMesssage] = useState();

  const handleDownload = async (result_file_id, _id) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${_id}/download`;
      let options = {
        method: "POST",
        headers: new Headers({
          content_Type: "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
          window.open(resJson.data);
          setSuccessMesssage(resJson.message);
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 1500);
        
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  const getEditLink = () => {
    const { pathname } = location;
    return `${pathname}/edit`;
  };

  const backButton = () => {
    if(location.pathname?.includes("edit")){
      navigate(-1)
    }else {
    navigate("/generated-reports")
    }
  }

  return (
    <header className={styles.header}>
      {location.pathname?.includes("edit") || resultFile?.category == "RISK DISORDERS" || resultFile?.category == "NUTRIGENOMICS" ? (
        <div className={styles.back}>
          <Button variant="contained" onClick={backButton}>
            <ArrowBackIcon sx={{ color: "#000", fontSize: "1.2rem" }} />
          </Button>
          <span className={styles.text}>Back</span>
        </div>
      ) : (
        <div></div>
      )}
<div className={styles.actions}>
      {!location.pathname?.includes("edit") ? (
        <>
          {!resultFile?.is_finalized === true || userType == "DOCTOR" ? (
            <>
            {!(resultFile?.category == "NUTRIGENOMICS" || resultFile?.category == "RISK DISORDERS") ? (
            <Button
              className="editBtn"
              variant="outlined"
              startIcon={
                <img src="/edit-pink-icon.svg" alt="" width={12} height={12} />
              }
              onClick={() => navigate(getEditLink())}
            >
              Edit
            </Button>
            ) : (
              ""
            )}
            {resultFile?.category == "RISK DISORDERS" || resultFile?.category == "NUTRIGENOMICS" ? (
                <Button
                className="editBtn"
                variant="outlined"
                startIcon={
                  <img
                    src="/download.svg"
                    alt=""
                    width={12}
                    height={12}
                  />
                }
                onClick={() =>{
                  handleDownload(
                    result_file_id,
                    report_id
                  );
                }}
              >
                 {loading ? (
                <CircularProgress size={"1.5rem"} sx={{ color: "#ae3a7a" }} />
              ) : (
                "Download"
              )}
              </Button>
              ) : (
                ""
              )}
            </>
          ) : (
            <div className={styles.back}>
              <Button
                disableElevation={true}
                color="primary"
                variant="contained"
                onClick={() => navigate("/generated-reports")}
              >
                <ArrowBackIcon sx={{ color: "#000", fontSize: "1.2rem" }} />{" "}
              </Button>
              <p className={styles.text}>Back</p>
            </div>
          )}
        </>
      ) : (
        ""
      )}
      <Snackbar
          open={showSuccessAlert}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        </Snackbar>
      </div>
    </header>
  );
};

export default HeaderComponent;

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#05A155",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#05A155",
  }),
}));
