import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./../index.module.css";
import HeaderComponent from "./HeaderComponent";
import PatientInfoComponent from "./PatientInfoComponent";
import ReportTypeNavigateButtons from "./ReportTypeNavigateButtons";

const PrimaryReportLayout = ({ children }) => {
  const AuthStateData = useSelector((state) => state.Signin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { report_id, result_file_id } = useParams();

  const [resultFile, setResultFile] = useState({});
  

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };
  const getPrimaryReportById = async () => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        setResultFile(resJson.data ? resJson.data : {});
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!AuthStateData?.access_token) {
      navigate("/");
    }
    getPrimaryReportById();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={styles.addsumarypage}>
        <div className={styles.bodycontainer}>
          <HeaderComponent resultFile={resultFile} />
          <div className={styles.group}>
            <ReportTypeNavigateButtons 
            resultFile={resultFile}
            />
            {children}
            <PatientInfoComponent 
            resultFile={resultFile}
            />
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default PrimaryReportLayout;
