

export const signInAPI = async (username, password) => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/users/signin`;
    let options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            // Accepts: "application/json",
        },
        body: JSON.stringify({
            user_name: username,
            password: password,
        }),
    }
    return await fetch(url, options);
}