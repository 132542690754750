import moment from "moment"

const timePipe = (timeString, format) => {

    if (timeString) {
        if (timeString == 'Invalid date' || timeString == '-') {
            return '-';
        } else {
            let date = moment.utc(timeString).format(format)

            return date;
        }
    }
    return '';

}

export default timePipe;