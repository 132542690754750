import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

const EvidenceSelectAutoComplete = ({ defaultValue, mainRowIndex, classesIndex, medicationIndex, onChangeEvidenceValue }) => {

    const onEvidenceValueChange = (_, newValue) => {
        if (newValue) {
            onChangeEvidenceValue(newValue, mainRowIndex, classesIndex, medicationIndex);
        } else {
            onChangeEvidenceValue("", mainRowIndex, classesIndex, medicationIndex);
        }
    };

    let options = [
        { title: "Actionable", label: "Actionable" },
        { title: "Level 3", label: "Level 3" },
        { title: "Informative", label: "Informative" },
        { title: "Level 1A", label: "Level 1A" },
        { title: "Level 2A", label: "Level 2A" },
        { title: "Level 4", label: "Level 4" },
    ]

    return (
        <Autocomplete
            className="defaultAutoComplete"
            sx={{
                width: "100%",
            }}
            value={defaultValue ? options?.find((item) => item.title == defaultValue) : null}
            disablePortal
            options={options?.length ? options : []}
            getOptionLabel={(option) => option.title}
            onChange={onEvidenceValueChange}
            renderInput={(params) => (
                <TextField {...params} placeholder="Select" size="small" />
            )}
        />
    );
};
export default EvidenceSelectAutoComplete;
