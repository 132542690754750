import React from "react";
import ReactDOM from "react-dom/client";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import "../src/app.scss";
import SignIn from "./Auth/SignIn.js";
import Store from "./Store";
import CreateForm from "./components/CreateForm";
import EditProfile from "./components/EditProfile";
import EditReport from "./components/EditReport";
import GenerateReport from "./components/GenerateReport";
import GenerateReport2 from "./components/GenerateReport2";
import GeneratedReports from "./components/GeneratedReports/GeneratedReportsList.js";
import GenotoPhenotype from "./components/GenotypetoPhenotype";
import GrandReportsList from "./components/GrandReports/GrandReportsList.js";
import PrimaryReportLayout from "./components/GrandReports/PrimaryReports/Layout/PrimaryReportLayout.js";
import RiskforGenetikDisorders from "./components/GrandReports/PrimaryReports/RiskforGenetikDisorders/RiskforGenetikDisorders.js";
import PrimaryReport from "./components/GrandReports/PrimaryReports/SummaryDetails/index.js";
import NotFound from "./components/NotFound.js";
import MainPage from "./components/PatientReports/MainPage.js";
import Preview from "./components/Preview";
import EditFiles from "./components/Result/EditDetails";
import ResultTemplatePreview from "./components/Result/Result-preview.js";
import ResultDetails from "./components/Result/ResultDetails.js";
import ResultFiles from "./components/Result/ResultFiles";
import UploadFile from "./components/Result/UploadFile";
import UploadNutriGenomicsFile from "./components/Result/UploadNurtiGenomicsFile.js";
import AllRiskDisordersDetails from "./components/RiskDisorders/AllRiskDisordersDetails.js";
import EditRiskDisorderDetails from "./components/RiskDisorders/EditRiskDisorderDetails.js";
import PreviewRiskDisorderResultFile from "./components/RiskDisorders/PreviewRiskDisorderResultFile.js";
import SingleRiskDisoderResultFile from "./components/RiskDisorders/SingleRiskDisorderResultFile.js";
import AllSummariesComponent from "./components/Summaries/AllSummaries.js";
import DownloadFinalizedReport from "./components/Summaries/DownloadFinalizedReport.js";
import MainContainer from "./components/SummaryReport/MainContainer";
import UpdatePassword from "./components/UpdatePassword";
import ViewProfile from "./components/ViewProfile";
import "./index.css";
import BaseLayout from "./layouts/BaseLayout";
import reportWebVitals from "./reportWebVitals";

import DietaryGuidelines from "./components/GrandReports/PrimaryReports/DietaryGuidelines/DietaryGuidelines.js";
import OrganSpecific from "./components/GrandReports/PrimaryReports/OrganSpecific/OrganSpecific.js";
import PrimaryReportPreview from "./components/GrandReports/PrimaryReports/Preview/PrimaryReportPreview.js";
import WeeklyDietPlanner from "./components/GrandReports/PrimaryReports/WeeklyDietPlanner/WeeklyDietPlanner.js";
import EditRiskChronicDisorderDetails from "./components/GrandReports/RiskChronicDisorders/EditRiskDisorder/EditRiskChronicDisorderDetails.js";
import RiskChronicDisordersLayout from "./components/GrandReports/RiskChronicDisorders/Layout/RiskChronicDisordersLayout.js";
import PreviewRiskChronicDisorder from "./components/GrandReports/RiskChronicDisorders/PreviewRiskChronicDisorder.js";
import NutriGenomics from "./components/GrandReports/RiskChronicDisorders/NutriGenomics/NutriGenomics.js";
import NutriGenomicsView from "./components/GrandReports/RiskChronicDisorders/NutriGenomics/NutriGenomicsView.js";
import PreviewOfPharmacoGenomics from "./components/GrandReports/PharmacoGenomics/PreviewOfPharmacoGenomics.js";
import EditPharmacoGenomicsDetails from "./components/GrandReports/PharmacoGenomics/EditPharmacoGenomics/EditPharmacoGenomicsDetails.js";
import PharmacoGenomicsLayout from "./components/GrandReports/PharmacoGenomics/Layout/PharmacoGemomicsLayout.js";
import PrivewOfPhysicalEndurance from "./components/GrandReports/PhysicalEndurance/PreviewOfPhysicalEndurance.js";
import PeriodicTests from "./components/GrandReports/PrimaryReports/PeriodicTests/PeriodicTests";
import DrugsInteractions from "./components/GrandReports/PrimaryReports/DrugInteractions/DrugsInteractions.js";
import FoodInteractions from "./components/GrandReports/PrimaryReports/FoodInteractions/FoodInteractions.js";

let persistor = persistStore(Store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route
            path="/summary-reports/public/:report_track_id"
            element={<DownloadFinalizedReport />}
          />

          <Route
            path="/"
            element={
              <RequireAuth redirectTo="/">
                <BaseLayout />
              </RequireAuth>
            }
          >
            <Route path="/createform" element={<CreateForm />} />
          </Route>
          <Route path="*" element={<NotFound />} />
          <Route
            path="/grand-reports/:result_file_id/update-report-template/:report_id/preview"
            element={<PrimaryReportPreview />}
          />
          <Route path="/" element={<BaseLayout />}>
            <Route path="/GenerateReport" element={<GenerateReport />} />
            <Route path="/add-summary" element={<MainContainer />} />
            <Route
              path="/edit-summary/:report_id"
              element={<MainContainer />}
            />

            <Route
              path="/sumamry-reports"
              element={<AllSummariesComponent />}
            />
            <Route
              path="/risk-disorders"
              element={<AllRiskDisordersDetails />}
            />
            <Route
              path="/risk-disorders/:_id/report/:report_id/edit"
              element={<EditRiskDisorderDetails />}
            />

            <Route path="/patient-reports" element={<MainPage />} />
            <Route
              path="/risk-disorder/:_id/preview-report/:report_id"
              element={<SingleRiskDisoderResultFile />}
            ></Route>
            <Route
              path="/risk-disorder/:_id/preview-report/:report_id/preview"
              element={<PreviewRiskDisorderResultFile />}
            />
            <Route path="/grand-reports" element={<GrandReportsList />} />
            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id"
              element={
                <PrimaryReportLayout>
                  <PrimaryReport />
                </PrimaryReportLayout>
              }
            />

            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/organ-specific"
              element={
                <PrimaryReportLayout>
                  <OrganSpecific />
                </PrimaryReportLayout>
              }
            />
            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/dietary-guidelines"
              element={
                <PrimaryReportLayout>
                  <DietaryGuidelines />
                </PrimaryReportLayout>
              }
            />
            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/weekly-diet-planner"
              element={
                <PrimaryReportLayout>
                  <WeeklyDietPlanner />
                </PrimaryReportLayout>
              }
            />
            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/risk-for-genetic-disorders"
              element={
                <PrimaryReportLayout>
                  <RiskforGenetikDisorders />
                </PrimaryReportLayout>
              }
            />
            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/physical-endurance"
              element={
                <PrimaryReportLayout>
                  <PrivewOfPhysicalEndurance />
                </PrimaryReportLayout>
              }
            />
            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/periodic-tests"
              element={
                <PrimaryReportLayout>
                  <PeriodicTests />
                </PrimaryReportLayout>
              }
            />

            <Route path="/generated-reports" element={<GeneratedReports />} />

            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/risk-chronic-disorders"
              element={
                <RiskChronicDisordersLayout>
                  <PreviewRiskChronicDisorder />
                </RiskChronicDisordersLayout>
              }
            />
            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/risk-chronic-disorders/edit"
              element={
                <RiskChronicDisordersLayout>
                  <EditRiskChronicDisorderDetails />
                </RiskChronicDisordersLayout>
              }
            />
            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/drug-interactions"
              element={
                <PrimaryReportLayout>
                  <DrugsInteractions />
                </PrimaryReportLayout>
              }
            />

            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/food-interactions"
              element={
                <PrimaryReportLayout>
                  <FoodInteractions />
                </PrimaryReportLayout>
              }
            />

            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/nutrigenomics/edit"
              element={
                <RiskChronicDisordersLayout>
                  <NutriGenomics />
                </RiskChronicDisordersLayout>
              }
            />
            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/nutrigenomics"
              element={
                <RiskChronicDisordersLayout>
                  <NutriGenomicsView />
                </RiskChronicDisordersLayout>
              }
            />
            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/pharmaco-genomics"
              element={
                <PharmacoGenomicsLayout>
                  <PreviewOfPharmacoGenomics />
                </PharmacoGenomicsLayout>
              }
            />

            <Route
              path="/grand-reports/:result_file_id/update-report-template/:report_id/pharmaco-genomics/edit"
              element={
                <PharmacoGenomicsLayout>
                  <EditPharmacoGenomicsDetails />
                </PharmacoGenomicsLayout>
              }
            />
            <Route path="/GenerateReport2" element={<GenerateReport2 />} />
            <Route path="/report-preview/:report_id" element={<Preview />} />
            <Route path="/ViewProfile" element={<ViewProfile />} />
            <Route path="/EditProfile" element={<EditProfile />} />
            <Route path="/UpdatePassword" element={<UpdatePassword />} />
            <Route
              path="/result-file/:_id/preview-report/:report_id/edit"
              element={<EditFiles />}
            />
            <Route
              path="/result-file/:_id/preview-report/:report_id/preview"
              element={<ResultTemplatePreview />}
            />
            <Route
              path="/report-preview/:report_id/EditReport"
              element={<EditReport />}
            />
            <Route path="/result-file" element={<ResultFiles />}></Route>
            <Route
              path="/result-file/:_id/preview-report/:report_id"
              element={<ResultDetails />}
            ></Route>

            <Route path="/upload-file" element={<UploadFile />}></Route>
            <Route
              path="/upload-nutrigenomics-file"
              element={<UploadNutriGenomicsFile />}
            ></Route>
            <Route
              path="/genotype-phenotype"
              element={<GenotoPhenotype />}
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

function RequireAuth({ children, redirectTo }) {
  const AuthStateData = useSelector((state) => state.Signin);
  let isAuthenticated = AuthStateData.user_details ? true : false;
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
