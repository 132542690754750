import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";

const DietaryGuidelinesDialog = ({
  handleClose,
  open,
  foodsToAvoid,
  setFoodsToAvoid,
  foodsToInclude,
  setFoodsToInclude,
  setDataLength,
  dataLength,
  setFoodsAvoid,
  foodsAvoid,
  setFoodsInclude,
  foodsInclude
}) => {
  const foodsToAvoidList = [
    "Trans fats including butter, cheese, vanaspati, ghee should be avoided as they will interfere with narrowing of the heart valves",
    "Extra sugar in the form of excess coffee, tea, sweets, glucose, confectionery items, jams, jellies, soft drinks, and other sweetened beverages.",
    "Deep fried foods and bakery products on a daily basis.",
    "Limit egg yolk, red meat (mutton), prawns, crabs, processed meats like bacon, sausages, and others.",
    "Nuts – Cashews, Peanuts, Coconut, Pistachios.",
    "Extra salt on the table, daily pickle consumption",
    "Packed and processed foods contain major preservatives, mono sodium glutamate.",
    "Try to reduce the quantity of high calorie fruits – Mangoes, Custard Apples, Bananas, Jack Fruits, Sapota.",
    "Try to reduce the root vegetable – Potato, Yam, Colocasia",
    "Refined flour – Maida and Maida related products",
    "Sauces / Ketchup (or) outside foods (junk foods)",
    "Alcohol consumption",
  ];

  const foodsToIncludeList = [
    "Healthy and high fiber fruits – Papaya, Kiwi, Oranges, Watermelon, Grapes, Avocado, Guava, Pomegranate, and others for better heart functioning and to regulate High blood sugars",
    "Greenleaf vegetables – especially Palak, Gongura, Drumstick Leaves and others",
    "Foods rich in monounsaturated fatty acids including nuts like almond / walnuts / hazelnuts and seeds like pumpkin seeds, melon seeds, flaxseed, fish oil capsules (or) fresh water fish",
    "Foods rich in omega3 fatty acids including walnuts and salmon fish, flaxseeds, avocado, olives and olive oil",
    "Can include omega 3 fatty acid supplements",
    "Lean meat including fresh chicken and fish and egg whites are suggested for vitamin B6 and vitamin B12",
    "Small and frequent meals are ideal to avoid gastritis and unnecessary weight gain.",
  ];

  const handleCheckboxChange = (e, type) => {
    const { checked, value } = e.target;
    if (type === "foods_to_avoid") {
      setFoodsAvoid((prev) => {
        if (checked) {
          return [...prev, value];
        } else {
          return prev.filter((item) => item !== value);
        }
      });
    }
    if (type === "foods_to_include") {
      setFoodsInclude((prev) => {
        if (checked) {
          return [...prev, value];
        } else {
          return prev.filter((item) => item !== value);
        }
      });
    }
  };

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setFoodsAvoid((prev) => [...new Set([...prev, ...foodsToAvoidList])]);
      setFoodsInclude((prev) => [...new Set([...prev, ...foodsToIncludeList])]);
    } else {
      setFoodsAvoid((prev) =>
        prev.filter((item) => !foodsToAvoidList.includes(item))
      );
      setFoodsInclude((prev) =>
        prev.filter((item) => !foodsToIncludeList.includes(item))
      );
    }
  };

  const isAllSelected =
    foodsToAvoidList.every((item) => foodsAvoid.includes(item)) &&
    foodsToIncludeList.every((item) => foodsInclude.includes(item));

  const onAddExtraRow = () => {
    let foodAvoidLength = foodsAvoid?.length;
    let foodIncludeLength = foodsInclude?.length;
    let length =
      foodIncludeLength >= foodAvoidLength
        ? foodIncludeLength
        : foodAvoidLength;
    setDataLength(length);
    let include = foodsInclude.filter(e => typeof e == "string")
    let avoid = foodsAvoid.filter(e => typeof e == "string")
    setFoodsToAvoid(avoid);
    setFoodsToInclude(include)
    handleClose();
  };


  return (
    <Dialog
      className="dietaryPopup"
      open={open}
      onClose={handleClose}
      style={{ width: "100%" }}
    >
      <DialogTitle className="popupHeader" id="dietary-guidelines-title">
        <h3 className="heading">Dietary Guidelines</h3>
      </DialogTitle>
      <DialogContent className="popupBody">
        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Checkbox
                checked={isAllSelected}
                onChange={handleSelectAllChange}
              />
            }
            label="Select All"
          />
        </FormControl>
        <Grid container>
          <Grid item xs={6}>
            <p className="subHeading left">Foods to Avoid</p>
            <div className="eachBlock">
              <FormControl component="fieldset">
                {foodsToAvoidList.map((food, index) => (
                  <div className="eachPoint" key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={foodsAvoid.includes(food)}
                          onChange={(e) =>
                            handleCheckboxChange(e, "foods_to_avoid")
                          }
                          value={food}
                        />
                      }
                      label={food}
                    />
                  </div>
                ))}
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={6}>
            <p className="subHeading right">Foods to Include</p>
            <div className="eachBlock">
              <FormControl component="fieldset">
                {foodsToIncludeList.map((food, index) => (
                  <div className="eachPoint" key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={foodsInclude.includes(food)}
                          onChange={(e) =>
                            handleCheckboxChange(e, "foods_to_include")
                          }
                          value={food}
                        />
                      }
                      label={food}
                    />
                  </div>
                ))}
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="popupFooter">
        <Button className="defaultButton cancelBtn" onClick={() => {
          handleClose();
        }}>
          CANCEL
        </Button>
        <Button className="defaultButton addButton" onClick={onAddExtraRow}>
          ADD
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DietaryGuidelinesDialog;