import {
  AlertTitle,
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../../Core/LoadingComponent";
import SummarySelectAutoComplete from "../../../Core/SummarySelectAutoComplete";
import "./EditPharmacoGenomicsDetails.css";
import EvidenceSelectAutoComplete from "../../../Core/EvidenceSelectAutoCompleted";
const EditPharmacoGenomicsDetails = () => {
  const AuthStateData = useSelector((state) => state.Signin);
  const navigate = useNavigate();

  const { report_id, result_file_id } = useParams();
  const [singleSummaryReportDetails, setSingleSummaryReportDetails] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMesssage] = useState();
  const [errorMessage, setErrorMesssage] = useState();
  const [genoPhenoType, setGenoPhenoType] = useState({});
  const [loadingResult, setLoadingResult] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const getDetailsOfSingleReport = async () => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;

      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resjson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        setSingleSummaryReportDetails(resjson?.data?.summary);
        setGenoPhenoType(resjson?.data?.form);
      }
    } catch (err) {
      setLoadingResult(false);
      console.error(err);
    } finally {
      setLoadingResult(false);
    }
  };

  const onChangeSummaryValue = (
    value,
    mainRowIndex,
    classesIndex,
    medicationIndex
  ) => {
    let details = [...singleSummaryReportDetails];
    details[mainRowIndex]["classes"][classesIndex]["medications"][
      medicationIndex
    ]["summary"] = value.title;
    setSingleSummaryReportDetails(details);
  };

  const onChangeEvidenceValue = (
    value,
    mainRowIndex,
    classesIndex,
    medicationIndex
  ) => {
    let details = [...singleSummaryReportDetails];
    details[mainRowIndex]["classes"][classesIndex]["medications"][
      medicationIndex
    ]["evidence"] = value.title;
    setSingleSummaryReportDetails(details);
  };

  const handleEditPharmacoGenomics = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/update-report-template/${report_id}`;
      let requestBody = {
        summary: singleSummaryReportDetails,
      };
      let options = {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
        body: JSON.stringify(requestBody),
      };

      let response = await fetch(url, options);
      let responseData = await response.json();
      setSuccessMesssage(responseData.message);
      setErrorMesssage(responseData.message);
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        setLoading(false);
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate(-1);
        }, 1500);
      } else {
        setShowErrorAlert(true);
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 1500);
      }
    } catch (err) {
      setLoading(false);
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
      setLoading(false);
    }
  };

  const handleEditGenoPhenoTypes = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/update-report-template/${report_id}`;
      let requestBody = {
        form: genoPhenoType,
      };
      let options = {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
        body: JSON.stringify(requestBody),
      };

      let response = await fetch(url, options);
      let responseData = await response.json();
      setSuccessMesssage(responseData.message);
      setErrorMesssage(responseData.message);
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        setLoading(false);
        setShowSuccessAlert(true);
        getDetailsOfSingleReport();
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 1500);
      } else {
        setShowErrorAlert(true);
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 1500);
      }
    } catch (err) {
      setLoading(false);
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
      setLoading(false);
    }
  };

  const handleInputChange = (geno, value) => {
    let option = { ...genoPhenoType };
    option[geno] = value;
    setGenoPhenoType(option);
  };

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
    getDetailsOfSingleReport();
  }, []);

  return (
    <div id="editPharmacoGenomicsBlock">
      <div className="edit-container">
        <Box className="edit-block">
          <Grid
            container
            columns={{ xs: 6, md: 8, xl: 10 }}
            className="edit-card-block"
            sx={{ height: "35vh", overflowY: "auto" }}
          >
            {Object.keys(genoPhenoType).length
              ? Object.keys(genoPhenoType).map((geno, genoIndex) => (
                <Grid item xs={2} key={genoIndex}>
                  <div
                    style={{
                      padding: "0.5rem",
                      display: "grid",
                      gridTemplateColumns: "auto 50px",
                      alignItems: "center",
                    }}
                  >
                    <Typography className="editGenoTypeLable">
                      {geno}:
                    </Typography>
                    <Select
                      className="inputField"
                      size="small"
                      value={genoPhenoType[geno]}
                      onChange={(e) =>
                        handleInputChange(geno, e.target.value)
                      }
                    >
                      <MenuItem className="menuItem" value="0">
                        0
                      </MenuItem>
                      <MenuItem className="menuItem" value="1">
                        1
                      </MenuItem>
                      <MenuItem className="menuItem" value="2">
                        2
                      </MenuItem>
                      <MenuItem className="menuItem" value="3">
                        3
                      </MenuItem>
                    </Select>
                  </div>
                </Grid>
              ))
              : ""}
          </Grid>
          <div className="updateBtnGrp">
            <Button onClick={() => handleEditGenoPhenoTypes()}>Update</Button>
          </div>
        </Box>
      </div>
      <div className="edit-container" style={{ marginTop: "2rem" }}>
        <Box className="edit-block ">
          <div className="gene-heading-block">
            <Typography variant="h5" className="gene-heading">
              Pharmacogenomics
            </Typography>
          </div>

          <Card
            className="edit-card-block"
            style={{ height: "60vh", overflowY: "auto" }}
          >
            {singleSummaryReportDetails?.length ? (
              <table className="styled-table">
                <thead>
                  <tr>
                    <th className="header" colSpan={5}>
                      Pharmacogenomic Recommendations
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {singleSummaryReportDetails?.length ? (
                    singleSummaryReportDetails?.map((mainRow, index) => (
                      <tr key={index}>
                        <td>{mainRow?.speciality}</td>
                        <td colSpan={4} style={{ border: "0", padding: "0" }}>
                          <table style={{ width: "100%" }}>
                            {mainRow?.classes?.map(
                              (classesRow, classesIndex) => {
                                return (
                                  <tr key={classesIndex}>
                                    <td
                                      style={{
                                        width: "150px",
                                        maxWidth: "150px",
                                      }}
                                    >
                                      {classesRow?.class}
                                    </td>
                                    <td colSpan={3} style={{ padding: "0" }}>
                                      <table style={{ width: "100%" }}>
                                        {classesRow?.medications?.map(
                                          (medication, medicationIndex) => {
                                            return (
                                              <tr key={medicationIndex}>
                                                {medication?.medicine?.length > 27 ? (
                                                  <Tooltip title={medication?.medicine} arrow>
                                                    <td
                                                      style={{
                                                        width: "150px",
                                                        maxWidth: "150px",
                                                      }}
                                                    >
                                                      {medication?.medicine?.length > 27
                                                        ? medication?.medicine.slice(0, 27) + '...'
                                                        : medication?.medicine}
                                                    </td>
                                                  </Tooltip>
                                                ) : (
                                                  <td
                                                    style={{
                                                      width: "150px",
                                                      maxWidth: "150px",
                                                    }}
                                                  >
                                                    {medication?.medicine}
                                                  </td>
                                                )}
                                                <td
                                                  style={{
                                                    width: "150px",
                                                    maxWidth: "190px",
                                                  }}
                                                >
                                                  <div className="tableSelectComponent">
                                                    <SummarySelectAutoComplete
                                                      defaultValue={
                                                        medication?.summary
                                                      }
                                                      mainRowIndex={index}
                                                      classesIndex={
                                                        classesIndex
                                                      }
                                                      medicationIndex={
                                                        medicationIndex
                                                      }
                                                      onChangeSummaryValue={
                                                        onChangeSummaryValue
                                                      }
                                                    />
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    width: "150px",
                                                    maxWidth: "190px",
                                                  }}
                                                >
                                                  <div className="tableSelectComponent">
                                                    <EvidenceSelectAutoComplete
                                                      defaultValue={
                                                        medication?.evidence
                                                      }
                                                      mainRowIndex={index}
                                                      classesIndex={
                                                        classesIndex
                                                      }
                                                      medicationIndex={
                                                        medicationIndex
                                                      }
                                                      onChangeEvidenceValue={
                                                        onChangeEvidenceValue
                                                      }
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </table>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </table>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              ""
            )}
          </Card>
        </Box>
        <div className="updateBtnGrp">
          <Button onClick={() => handleEditPharmacoGenomics()}>Update</Button>
        </div>
      </div>
      <LoadingComponent loading={loading || loadingResult} />

      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default EditPharmacoGenomicsDetails;
