import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
// import "./EditProfile.css";
import { MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import LoadingComponent from "./Core/LoadingComponent";
import SignatureCanvas from 'react-signature-canvas';
import { Toaster, toast } from "sonner";




function EditProfile() {
  const AuthStateData = useSelector((state) => state.Signin);
  const navigate = useNavigate();
  const [userdata, setUserdata] = useState({});
  const [upAlertShow, setUpAlertShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSigning, setIsSigning] = useState(true);
  const sigCanvasRef = useRef(null);
  const [message, setMessage] = useState("");
  const [errmessages, setErrmessages] = useState({});

  let token = localStorage.getItem("code");
  const updateData = {
    address: {
      city: userdata?.address?.city,
      pincode: userdata?.address?.pincode,
      state: userdata?.address?.state,
    },

    desgination: userdata?.desgination,
    digital_signature: userdata?.digital_signature,
    email: userdata?.email,
    first_name: userdata?.first_name,
    gender: userdata?.gender,
    last_name: userdata?.last_name,
    phone_number: userdata?.phone_number,
    regd_no: userdata?.regd_no,
    signature_name: userdata?.signature_name,
    user_name: userdata?.user_name,
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/users/profile`;
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      setUserdata(resJson.data);
    } catch (err) {
      console.error(err);
      toast.error(err.message || "something went wrong")
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/users/update-user`;
      let options = {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
        body: JSON.stringify(updateData),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      console.log(resJson);
      if (resJson.success) {
        setUpAlertShow(true);
        toast.success("Profile updated successfully")
        setTimeout(() => {
          navigate("/ViewProfile");
        }, 1500);
      } else if (response.status === 422) {
        toast.error(resJson.message);
        setErrmessages(resJson.errors);
        // setMessage();
        navigate("/ViewProfile");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.error(err);
      toast.error(err.message || "Something went wrong")
    } finally {
      setLoading(false);
    }
  };

  const getResults = () => {
    navigate("/ViewProfile");
  };

  const handleSaveSignature = () => {
    const trimmedDataURL = sigCanvasRef.current.toDataURL();
    handleChange({
      target: {
        name: "digital_signature",
        value: trimmedDataURL,
      },
    });
    setIsSigning(false);
  };

  const handleClearSignature = () => {
    sigCanvasRef.current.clear();
  };

  const handleChange = (event) => {
    setErrmessages({});
    const { name, value } = event.target;
    if (name === "city" || name === "state" || name === "pincode") {
      setUserdata((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [name]: value,
        },
      }));
    } else {
      setUserdata((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (userdata?.digital_signature) {
      const img = new Image();
      img.src = userdata.digital_signature;
      img.onload = () => {
        const canvas = sigCanvasRef.current.getCanvas();
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    }
  }, [userdata?.digital_signature]);

  const getFieldError = (fieldName) => {
    return errmessages ? errmessages[fieldName] : '';
  };

  return (
    <div id="editProfilePage">
      <div className="editSection">
        <div className="editHeaderPart">
          <p className="header">Edit Profile</p>
        </div>
        <div className="editBodyBlock">
          <form onSubmit={handleUpdate}>
            <Grid container spacing={2} rowGap={1} columns={16}>
              <Grid item xs={5}>
                <div className="eachFiedDetails">
                  <label>
                    First Name<strong style={{ color: "#C70A0D" }}>*</strong>
                  </label>
                  <TextField
                    type="text"
                    size="small"
                    name="first_name"
                    value={userdata?.first_name}
                    onChange={handleChange}
                    error={!!getFieldError('first_name')}
                    helperText={getFieldError('first_name')}
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="eachFiedDetails">
                  <label>
                    Last Name<strong style={{ color: "#b71c1c" }}>*</strong>
                  </label>
                  <TextField
                    name="last_name"
                    type="text"
                    size="small"
                    value={userdata?.last_name}
                    onChange={handleChange}
                    error={!!getFieldError('last_name')}
                    helperText={getFieldError('last_name')}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="eachFiedDetails">
                  <label>
                    Gender
                  </label>
                  <Select
                    id="outlined-select-currency"
                    select
                    name="gender"
                    type="text"
                    size="small"
                    value={userdata?.gender || ""}
                    onChange={handleChange}
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="eachFiedDetails">
                  <label>
                    Email<strong style={{ color: "#b71c1c" }}>*</strong>
                  </label>
                  <TextField
                    name="email"
                    type="text"
                    size="small"
                    value={userdata?.email}
                    onChange={handleChange}
                    error={!!getFieldError('email')}
                    helperText={getFieldError('email')}
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="eachFiedDetails">
                  <label>
                    Mobile Number<strong style={{ color: "#b71c1c" }}>*</strong>
                  </label>
                  <TextField
                    name="phone_number"
                    type="text"
                    size="small"
                    value={userdata?.phone_number}
                    onChange={handleChange}
                    error={!!getFieldError('phone_number')}
                    helperText={getFieldError('phone_number')}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="eachFiedDetails">
                  <label>
                    City
                  </label>
                  <TextField
                    name="city"
                    type="text"
                    size="small"
                    value={userdata?.address?.city || ""}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="eachFiedDetails">
                  <label>
                    State
                  </label>
                  <Select
                    id="outlined-select-currency"
                    select
                    name="state"
                    type="text"
                    size="small"
                    value={userdata?.address?.state || ""}
                    onChange={handleChange}
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Telangana"}>Telangana</MenuItem>
                    <MenuItem value={"Andhra Pradesh"}>Andhra Pradesh</MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="eachFiedDetails">
                  <label>
                    Zip
                  </label>
                  <TextField
                    name="pincode"
                    type="text"
                    size="small"
                    value={userdata?.address?.pincode || ""}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="eachFiedDetails">
                  <label>
                    Signature
                  </label>
                  <TextField
                    name="signature_name"
                    type="text"
                    size="small"
                    value={userdata?.signature_name}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="eachFiedDetails">
                  <label>
                    Designation
                  </label>
                  <TextField
                    name="desgination"
                    type="text"
                    size="small"
                    value={userdata?.desgination}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="eachFiedDetails">
                  <label name="digital_signature">
                    Digital Signature

                  </label>
                  <div className="signature-container">
                    <SignatureCanvas
                      ref={sigCanvasRef}
                      penColor="black"
                      canvasProps={{
                        width: 250,
                        height: 100,
                        style: {
                          border: "1px solid rgba(0, 67, 93, 0.2)",
                          borderRadius: "4px",
                        },
                        className: "sigCanvas",
                      }}
                      // onBegin={() => setIsSigning(true)}
                      minWidth={0.8}
                      maxWidth={1.8}
                    />
                  </div>
                  {isSigning && (
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Button
                        className="defaultButton clearBtn"
                        variant="outlined"
                        onClick={handleClearSignature}
                      >
                        Clear
                      </Button>
                      <Button
                        className="defaultButton saveBtn"
                        variant="outlined"
                        onClick={handleSaveSignature}
                      >
                        Save
                      </Button>
                    </Box>
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="eachFiedDetails">
                  <label>
                    Regd No

                  </label>
                  <TextField
                    name="regd_no"
                    type="text"
                    size="small"
                    value={userdata?.regd_no}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              {/* <Grid xs ={6}>
               
                  <SignatureCanvas penColor='green'
                    canvasProps={{width: 300, height: 50,marginLeft:'30px', className: 'sigCanvas'}} />

                </Grid> */}
            </Grid>
            {/* <div className="message">{message ? <p>{message}</p> : null}</div> */}
          </form>
          {loading ? <div className="spinner"></div> : <div></div>}
        </div>
        <div className="bottomButtonBlock">
          <Button
            className="defaultButton cancelButton"
            variant="contained"
            onClick={getResults}
          >
            Cancel
          </Button>
          <Button
            className="defaultButton updateButton"
            type="submit"
            variant="contained"
            onClick={handleUpdate}
          >
            Update Profile
          </Button>
        </div>
      </div>
      {upAlertShow && (
        <Alert className="custm-alert-update">
          <p>Profile Updated successfully</p>
        </Alert>
      )}
      <LoadingComponent loading={loading} />
      <Toaster closeButton richColors position="top-right" />
    </div>
  );
}
export default EditProfile;
