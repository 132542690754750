import {
  AlertTitle,
  Button,
  Card,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { RemoveCircle } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../../Core/LoadingComponent";
import "./EditRiskOrderDetails.css";

function EditRiskChronicDisorderDetails() {
  const AuthStateData = useSelector((state) => state.Signin);

  const [patientInfo, setPatientInfo] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMesssage] = useState();
  const [errorMessage, setErrorMesssage] = useState();
  const [loadingResult, setLoadingResult] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [abbreviations, setAbbreviations] = useState([]);
  const [singleReportDetails, setSingleReportDetails] = useState([]);
  const [ovarianCancerId, setOvarianCancerId] = useState('');
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { report_id, result_file_id } = useParams();

  const getDetailsOfSingleReport = async () => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resjson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        setPatientInfo(resjson.data["patient_info"]);
        setSingleReportDetails(resjson?.data?.disorder_list);
        setAbbreviations(resjson?.data["disorder_abbreviations"])
      }
    } catch (err) {
      setLoadingResult(false);
      console.error(err);
    } finally {
      setLoadingResult(false);
    }
  };

  const handleEdit = async () => {
    setLoadingResult(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/update-report-template/${report_id}`;
      let requestBody = {
        disorder_list: singleReportDetails,
        disorder_abbreviations: abbreviations,
      };
      let options = {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
        body: JSON.stringify(requestBody),
      };
      let response = await fetch(url, options);
      let responseData = await response.json();
      setSuccessMesssage(responseData.message);
      setErrorMesssage(responseData.message);
      if (response.status === 200 || response.status === 201) {
        setLoadingResult(false);
        setLoading(false);
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate(-1);
        }, 1500);
      } else {
        setShowErrorAlert(true);
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 1500);
      }
    } catch (err) {
      setLoadingResult(false);
      setLoading(false);
      console.error(err);
    } finally {
      setLoadingResult(false);
      setLoading(false);
    }
  };

  // const handleRegistration = (data) => {
  //   if (data) {
  //     let temp = { ...data };
  //     // temp["visit_id"] = patientInfo["visit_id"];
  //     handleEdit(temp);
  //     setLoading(true);
  //   }
  // };

  //handle the edit values and store the same set state
  const editScoreValues = (editedObj, value) => {
    let afterEditedValues = [];
    afterEditedValues = singleReportDetails?.map((item, index) => {
      if (item.disorder === editedObj.disorder) {
        return {
          ...item,
          "total_score": +value,
        };
      }
      return { ...item };
    });
    setSingleReportDetails(afterEditedValues);
  };

  const editDisorderValue = (editedObj, value) => {
    let afterEditedValues = [];
    afterEditedValues = singleReportDetails?.map((item, index) => {
      if (item.disorder === editedObj.disorder) {
        return {
          ...item,
          "disorder": value,
        };
      }
      return { ...item };
    });
    setSingleReportDetails(afterEditedValues);
  };

  const handleAbbreviationChange = (index, field, value) => {
    const updatedAbbreviations = abbreviations.map((abb, i) =>
      i === index ? { ...abb, [field]: value } : abb
    );
    setAbbreviations(updatedAbbreviations);
  };

  const addAbbreviation = () => {
    setAbbreviations((prev) => [...prev, { disorder_name: "", abbreviation: "" }]);
  };

  const removeAbbreviation = (index) => {
    setAbbreviations((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
    getDetailsOfSingleReport();
  }, []);

  const checkNumberOrNot = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    event.target.value = value.slice(0, 10);
  };

  const groupByPanel = (data) => {
    return data.reduce((acc, current) => {
      const { panel } = current;
      if (!acc[panel]) {
        acc[panel] = [];
      }
      acc[panel].push(current);

      let data = acc?.['Cancer Panel'];

      for (let i = 0; i < data?.length; i++) {
        if (data[i].disorder == 'Ovarian cancer' || data[i].disorder == 'Prostate cancer') {
          setOvarianCancerId(data[i]._id)
          break
        }
      }
      return acc;
    }, {});
  };
  const [groupedData, setGroupedData] = useState([])
  useEffect(() => {

    let data = groupByPanel(singleReportDetails);
    setGroupedData(data)
  }, [singleReportDetails])


  return (
    <div className="edit-container">
      <Box className="edit-block">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <h3 className="reportEditTitle">Update Patient Results</h3>
          <Typography varient="h1"> </Typography>
        </div>

        <Card className="edit-card" sx={{ height: "65vh", overflowY: "auto" }}>
          <Card
            className="small-editcard"
            style={{ backgroundColor: "#f05b43" }}
          >
            <Typography style={{ backgroundColor: "#f05b43" }} variant="h2">
              RISK FOR CHRONIC DISORDERS
            </Typography>
          </Card>
          {patientInfo ? (
            <>
           {/* <form onSubmit={handleSubmit(handleRegistration)}> */}
              <div className="genes-block">
                <Box sx={{ flexGrow: 1 }}>
                  {singleReportDetails?.length ? (
                    <div>
                      {Object.keys(groupedData).map((panel, panelIndex) => (
                        <div key={panelIndex}>
                          <h4 style={{ marginTop: "10px" }}>{panel}</h4>
                          <table className="styled-table">
                            <thead>
                              <tr>
                                <th className="header"></th>
                                <th className="header">Disorders</th>
                                <th className="header" width="560px">
                                  <img
                                    src="/Process.svg"
                                    alt=""
                                    style={{ width: "280px" }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "5px",
                                      textAlign: "center",
                                      fontSize: "12px",
                                      padding: "5px 0",
                                      fontWeight: "700",
                                    }}
                                  >
                                    <div
                                      style={{ width: "70px", textAlign: "center" }}
                                    >
                                      Nill
                                    </div>
                                    <div
                                      style={{ width: "70px", textAlign: "center" }}
                                    >
                                      Mild
                                    </div>
                                    <div
                                      style={{ width: "70px", textAlign: "center" }}
                                    >
                                      Moderate
                                    </div>
                                    <div
                                      style={{ width: "70px", textAlign: "center" }}
                                    >
                                      High
                                    </div>
                                    <div
                                      style={{ width: "70px", textAlign: "center" }}
                                    >
                                      Score
                                    </div>
                                    <div
                                      style={{ width: "70px", textAlign: "center" }}
                                    >
                                      Normal TH
                                    </div>
                                    <div
                                      style={{ width: "70px", textAlign: "center" }}
                                    >
                                      Moderate TH
                                    </div>
                                    <div
                                      style={{ width: "70px", textAlign: "center" }}
                                    >
                                      High TH
                                    </div>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {groupedData[panel]?.map((row, index) => (
                                <tr key={index}>
                                  <td></td>
                                  {row?._id == ovarianCancerId ? (
                                    <Select
                                      className="tableSelectField"
                                      sx={{ width: "100%", background: "#fff", border: "0" }}
                                      variant="outlined"
                                      value={row?.disorder}
                                      onChange={(e) => {
                                        editDisorderValue(row, e.target.value);
                                      }}
                                    >
                                      <MenuItem
                                        className="menuItem"
                                        value="Ovarian cancer"
                                      >
                                        Ovarian cancer
                                      </MenuItem>
                                      <MenuItem
                                        className="menuItem"
                                        value="Prostate cancer"
                                      >
                                        Prostate cancer
                                      </MenuItem>
                                    </Select>
                                  ) : (
                                    <td>{row?.disorder}</td>
                                  )}

                                  <td>
                                    <div
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(8,1fr)",
                                        gap: "5px",
                                        alignItems: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div>
                                        {row?.risk_type === "Nill" ? (
                                          <img src="/Nill.svg" alt="" />
                                        ) : (
                                          " "
                                        )}
                                      </div>
                                      <div>
                                        {row?.risk_type === "Normal_risk" ? (
                                          <img src="/Mid.svg" alt="" />
                                        ) : (
                                          " "
                                        )}
                                      </div>
                                      <div>
                                        {row?.risk_type === "Moderate_risk" ? (
                                          <img src="/Moderate.svg" alt="" />
                                        ) : (
                                          " "
                                        )}
                                      </div>
                                      <div>
                                        {row?.risk_type === "High_risk" ? (
                                          <img src="/High.svg" alt="" />
                                        ) : (
                                          " "
                                        )}
                                      </div>
                                      <div>
                                        <TextField
                                          sx={{
                                            "& .MuiInputBase-root": {
                                              height: "26px",
                                              width: "50px",
                                              fontSize: "13px",
                                              margin: "0 auto",
                                            },
                                            "& .MuiOutlinedInput-input": {
                                              textAlign: "center",
                                            },
                                          }}
                                          onInput={checkNumberOrNot}
                                          value={row?.total_score}
                                          onChange={(e) => {
                                            editScoreValues(row, e.target.value);
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <p className="tdvalue">
                                          {"<" + row?.moderate_risk_threshold}
                                        </p>
                                      </div>
                                      <div>
                                        <p className="tdvalue">
                                          {">" +
                                            row?.moderate_risk_threshold +
                                            " " +
                                            "<" +
                                            row?.high_risk_threshold}
                                        </p>
                                      </div>
                                      <div>
                                        <p className="tdvalue">
                                          {">=" + row?.high_risk_threshold}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
              </div>
             {/* </form> */}
            </>
          ) : (
            ""
          )}
        </Card>
        <div className="abbreviations">
                <Typography variant="h5" className="gene-heading">
                  Abbreviations
                </Typography>
                {abbreviations?.length ? (
                  abbreviations?.map((abb, index) => (
                    <div key={index} style={{ display: 'flex', gap: "1rem", margin: "10px" }}>
                      <TextField
                        className="inputField"
                        value={abb?.disorder_name}
                        name={"disorder_name"}
                        onChange={(e) =>
                          handleAbbreviationChange(index, "disorder_name", e.target.value)
                        }
                      />
                      <TextField
                        className="inputField"
                        value={abb?.abbreviation}
                        name={"abbreviation"}
                        onChange={(e) =>
                          handleAbbreviationChange(index, "abbreviation", e.target.value)
                        }
                      />
                      <Button
                        onClick={() => removeAbbreviation(index)}
                      >
                        <RemoveCircle sx={{ color: "#f35a3e" }} />
                      </Button>
                    </div>
                  ))
                ) : (
                  ""
                )}
                <div className="actionButton">
                  <Button
                    className="add-more-button"
                    variant="contained"
                    onClick={addAbbreviation}
                    sx={{ marginTop: "1rem" }}
                  >
                    Add More
                  </Button>
                </div>

              </div>
              <div className="sticky-bottom">
                <div style={{ width: "100%", textAlign: "right" }}>
                  <Button
                    className="update-btn"
                    variant="contained"
                    type="submit"
                    sx={{ marginRight: "1rem" }}
                    onClick={() => {
                      handleEdit();
                    }}
                  >
                    Update
                  </Button>
                </div>
              </div>
      </Box>
      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
      <LoadingComponent loading={loading || loadingResult} />
    </div>
  );
}
export default EditRiskChronicDisorderDetails;
