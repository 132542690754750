import {
  Alert,
  AlertTitle,
  Button,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "sonner";
import LoadingComponent from "../../../Core/LoadingComponent";
import styles from "../index.module.css";
import AddDrugInteraction from "./AddDrugInteraction";

const DrugsInteractions = () => {
  const userType = useSelector((state) => state.Signin.user_details?.user_type);
  const AuthStateData = useSelector((state) => state.Signin);
  const { report_id, result_file_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isEditOpenOrNot, setIsEditOpenOrNot] = useState(false);
  const [message, setMessage] = useState("");
  const [drugsData, setDrugsData] = useState([]);
  const [processSuccess, setProcessSuccess] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [data, setData] = useState([]);

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const getDrugsInteractions = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/drugs/interactions/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        setDrugsData(resJson.data ? resJson.data : []);
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/drugs/interactions/${report_id}`;
      const payload = { drug: data };
      let options = {
        method: "POST",
        body: JSON.stringify(payload),
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
          "content-type": "application/json",
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setProcessSuccess(resJson?.message);
        setShowSuccessAlert(true);
        setIsEditOpenOrNot(false);
        setTimeout(() => {
          setProcessSuccess("");
          setShowSuccessAlert(false);
        }, 1500);
        await getDrugsInteractions();
      } else if (response.status === 400) {
        toast.error(resJson.message);
        setMessage(resJson.message);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onEditClick = () => {
    setIsEditOpenOrNot(true);
  };

  const handleCancel = () => {
    setIsEditOpenOrNot(false);
  };

  useEffect(() => {
    if (!AuthStateData?.access_token) {
      navigate("/");
    }
    getDrugsInteractions();
  }, []);

  return (
    <div className={styles.detailscontainer} id="drugInteractions">
      <div className="editBtnGrp">
        <h6 className="headingText">Drugs Interactions</h6>
        {!isEditOpenOrNot ? (
          <div>
            {userType == "DOCTOR" ? (
              <Button
                disableElevation={true}
                className="editBtn"
                variant="outlined"
                startIcon={
                  <img
                    src="/edit-pink-icon.svg"
                    alt=""
                    width={12}
                    height={12}
                  />
                }
                onClick={onEditClick}
              >
                Edit
              </Button>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {isEditOpenOrNot ? (
        <div className="processSummaryBlock">
          <AddDrugInteraction
            setData={setData}
            data={data}
            drugsData={drugsData}
          />

          <div className="summaryEditBtnGrp">
            <Button className="cancelBtn" variant="text" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="saveBtn"
              variant="contained"
              onClick={onContinue}
            >
              Update
            </Button>
          </div>
        </div>
      ) : (
        <div className="previewBlock">
          <div
            className="htmlPreview"
            style={{ width: "95%", margin: "0 auto" }}
          >
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, borderCollapse: "collapse" }}
                aria-label="medication interaction table"
              >
                <TableHead>
                  <TableRow sx={{ borderBottom: "1.5px solid black" }}>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        color: "brown",
                        borderRight: "1px solid black",
                      }}
                    >
                      Primary Medication
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        borderRight: "1px solid black",
                      }}
                    >
                      Secondary Medication
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Interaction
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {drugsData.map((row) => (
                    <TableRow
                      key={row._id}
                      sx={{ borderBottom: "1px solid black" }}
                    >
                      <TableCell
                        sx={{ color: "brown", borderRight: "1px solid black" }}
                      >
                        {row.drug1_name}
                      </TableCell>
                      <TableCell sx={{ borderRight: "1px solid black" }}>
                        {row.drug2_name}
                      </TableCell>
                      <TableCell>{row.interaction_type}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
      <Toaster closeButton richColors position="top-right"></Toaster>
      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {processSuccess}
        </Alert>
      </Snackbar>
      <LoadingComponent loading={loading} />
    </div>
  );
};
export default DrugsInteractions;
