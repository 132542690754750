import {
  Button,
  Card,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { RemoveCircle } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../../Core/LoadingComponent";

const NutriGenomics = () => {
  const AuthStateData = useSelector((state) => state.Signin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [groupedNutrients, setGroupedNutrients] = useState({});
  const [nutrients, setNutrients] = useState([]);
  const [supplementsRequired, setSupplementsRequired] = useState([]);
  const [avoidFood, setAvoidFood] = useState([]);
  const [adequate, setAdequate] = useState({});
  const { report_id, result_file_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const setDataForSupplementsRequires = (data) => {
    const { supplements_required } = data;
    const required = supplements_required ? supplements_required : [];
    setSupplementsRequired(required);
  };

  const setDataForAvoidFood = (data) => {
    const { avoid } = data;
    const foodAvoid = avoid ? avoid : [];
    setAvoidFood(foodAvoid);
  };

  const groupByNutrient = (nutrientsSummary) => {
    return nutrientsSummary.reduce((acc, nutrientItem) => {
      nutrientItem.summary.forEach((summaryItem) => {
        const index = nutrientsSummary.indexOf(nutrientItem);
        if (!acc[summaryItem.nutrient]) {
          acc[summaryItem.nutrient] = [];
        }
        if (summaryItem.impact) {
          acc[summaryItem.nutrient].unshift({
            ...summaryItem,
            nutritionIndex: index
          });
        } else {
          acc[summaryItem.nutrient].push({
            ...summaryItem,
            nutritionIndex: index
          });
        }
      });
      return acc;
    }, {});
  };

  const ungroupNutrientData = (groupData) => {
    let summaryArray = Object.values(groupData);
    let indexBasedGroup = summaryArray.reduce((acc, value) => {
      value.forEach((item) => {
        const { nutritionIndex, ...rest } = item;
        if (!acc[nutritionIndex]) {
          acc[nutritionIndex] = [];
        }
        acc[nutritionIndex].push({ ...rest });
      });
      return acc;
    }, {});
    let ungroupData = nutrients.map((item, index) => ({
      ...item,
      summary: indexBasedGroup[index] || []
    }));
    return ungroupData;
  };

  const getPrimaryReportById = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        const resultData = resJson.data ? resJson.data : {};
        const nutrientsSummary = resultData["nutrients_summary"];
        setNutrients(nutrientsSummary);
        const groupedNutrients = groupByNutrient(nutrientsSummary);
        setGroupedNutrients(groupedNutrients);
        setAdequate(resultData["nutrient_diet"]);
        setDataForSupplementsRequires(resultData["nutrient_diet"]);
        setDataForAvoidFood(resultData["nutrient_diet"]);
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401 || response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onChangeValue = (e, nutrient, summaryIndex) => {
    const { value, name } = e.target;
    let tempGroupedNutrients = { ...groupedNutrients };
    tempGroupedNutrients[nutrient][summaryIndex][name] = value;
    setGroupedNutrients(tempGroupedNutrients);

    if (name === "recommendation") {
      if (value === "Supplements required") {
        if (!supplementsRequired.includes(nutrient)) {
          setSupplementsRequired([...supplementsRequired, nutrient]);
        }
      } else {
        if (supplementsRequired.includes(nutrient)) {
          setSupplementsRequired(supplementsRequired.filter((item) => item !== nutrient));
        }
      }

      if (value === "Foods to avoid") {
        if (!avoidFood.includes(nutrient)) {
          setAvoidFood([...avoidFood, nutrient]);
        }
      } else {
        if (avoidFood.includes(nutrient)) {
          setAvoidFood(avoidFood.filter((item) => item !== nutrient));
        }
      }
    }
  };


  const onChangeSupplement = (index, value) => {
    let tempSupplements = [...supplementsRequired];
    tempSupplements[index] = value;
    setSupplementsRequired(tempSupplements);
  };

  const onAddSupplement = () => {
    setSupplementsRequired([...supplementsRequired, ""]);
  };

  const onRemoveSupplement = (index) => {
    let tempSupplements = supplementsRequired.filter((_, i) => i !== index);
    setSupplementsRequired(tempSupplements);
  };

  const onChangeAvoidFood = (index, value) => {
    let tempSupplements = [...avoidFood];
    tempSupplements[index] = value;
    setAvoidFood(tempSupplements);
  };

  const onAddAvoidFood = () => {
    setAvoidFood([...avoidFood, ""]);
  };

  const onRemoveAvoidFood = (index) => {
    let tempSupplements = avoidFood.filter((_, i) => i !== index);
    setAvoidFood(tempSupplements);
  };

  const onSaveNutridenomics = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/update-report-template/${report_id}`;
      const convertNoneToEmptyString = (data) => {
        return data.map(item => {
          if (item.recommendation === "None") {
            return {
              ...item,
              recommendation: ""
            };
          }
          return item;
        });
      };
      const payload = {
        nutrients_summary: ungroupNutrientData(groupedNutrients).map(nutrient => ({
          ...nutrient,
          summary: convertNoneToEmptyString(nutrient.summary)
        })),
        nutrient_diet: {
          adequate: adequate?.adequate,
          avoid: avoidFood,
          none: adequate?.none,
          supplements_required: supplementsRequired,
          _id: adequate?._id
        }
      };
      let options = {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
          "content-type": "application/json",
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        navigate(-1);
      } else if (response.status === 422) {
        setMessage(resJson.data["message"]);
      } else if (response.status === 401 || response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!AuthStateData?.access_token) {
      navigate("/");
    }
    getPrimaryReportById();
  }, []);

  return (
    <div id="nutriGenomicsView">
      <div className="editNutriGenomicsBlock">
        <div className="pageHeading">
          <Typography variant="h6">Nutrigenomics</Typography>
        </div>
        <div style={{ height: "60vh", overflowY: "auto" }}>
          <Card className="macronutrient-block">
            <table>
              <thead>
                <tr>
                  <th style={{ width: "250px" }}>Nutrient</th>
                  <th style={{ width: "300px" }}>Impact of Genotype</th>
                  <th style={{ width: "350px" }}>Recommendation</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(groupedNutrients).length
                  ? Object.keys(groupedNutrients).map((nutrient, nutrientIndex) => (
                    <tr colSpan={groupedNutrients[nutrient]?.length} key={nutrientIndex}>
                      <td style={{ verticalAlign: "middle" }}>{nutrient}</td>
                      <td>
                        <table>
                          {groupedNutrients[nutrient]?.length
                            ? groupedNutrients[nutrient].map(
                              (summaryItem, summaryIndex) => (
                                <tr key={summaryIndex} style={{ width: "100%", maxWidth: "100%" }}>
                                  <td width={"100%"} style={{ border: "none" }}>
                                    <TextField
                                      fullWidth
                                      sx={{ background: "#fff", borderRadius: "6px" }}
                                      className="tableTextField"
                                      name={"impact"}
                                      value={summaryItem.impact}
                                      onChange={(e) =>
                                        onChangeValue(
                                          e,
                                          nutrient,
                                          summaryIndex
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              )) : ""}
                        </table>
                      </td>
                      <td>
                        <table>
                          {groupedNutrients[nutrient]?.length
                            ? groupedNutrients[nutrient].map(
                              (summaryItem, summaryIndex) => (
                                <tr key={summaryIndex} style={{ width: "100%", maxWidth: "100%" }}>
                                  <td style={{ border: "none" }}>
                                    <Select
                                      className="tableSelectField"
                                      sx={{ width: "100%", background: "#fff" }}
                                      variant="outlined"
                                      name={"recommendation"}
                                      value={summaryItem.recommendation || "None"}
                                      onChange={(e) =>
                                        onChangeValue(
                                          e,
                                          nutrient,
                                          summaryIndex
                                        )
                                      }
                                    >
                                      <MenuItem
                                        className="menuItem"
                                        value="Adequate"
                                      >
                                        Adequate
                                      </MenuItem>
                                      <MenuItem
                                        className="menuItem"
                                        value="Supplements required"
                                      >
                                        Supplements Required
                                      </MenuItem>
                                      <MenuItem
                                        className="menuItem"
                                        value="Foods to avoid"
                                      >
                                        Foods To Avoid
                                      </MenuItem>
                                      <MenuItem className="menuItem" value="None">
                                        None
                                      </MenuItem>
                                    </Select>
                                  </td>
                                </tr>
                              )) : ""}
                        </table>
                      </td>
                    </tr>
                  )
                  )
                  : ""}
              </tbody>
            </table>
          </Card>
        </div>
        <div className="pageHeading abbreviations">
          <Typography variant="h5" className="gene-heading">
            Supplementations Required
          </Typography>
          {supplementsRequired.map((supplement, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
              <TextField
                fullWidth
                value={supplement}
                onChange={(e) => onChangeSupplement(index, e.target.value)}
              />
              <Button onClick={() => onRemoveSupplement(index)}><RemoveCircle sx={{ color: "#f35a3e" }} /></Button>
            </div>
          ))}
          <div className="actionButton">
            <Button
              className="add-more-button"
              variant="contained"
              onClick={onAddSupplement}
              sx={{ marginTop: "1rem" }}
            >
              Add More
            </Button>
          </div>
        </div>
        <div className="pageHeading abbreviations">
          <Typography variant="h5" className="gene-heading">
            Food To Avoid / limit The Intake
          </Typography>
          {avoidFood.map((food, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
              <TextField
                fullWidth
                value={food}
                onChange={(e) => onChangeAvoidFood(index, e.target.value)}
              />
              <Button onClick={() => onRemoveAvoidFood(index)}><RemoveCircle sx={{ color: "#f35a3e" }} /></Button>
            </div>
          ))}
          <div className="actionButton">
            <Button
              className="add-more-button"
              variant="contained"
              onClick={onAddAvoidFood}
              sx={{ marginTop: "1rem" }}
            >
              Add More
            </Button>
          </div>
        </div>
        <div className="UpdateBtnGroup">
          <Button
            className="update-btn"
            variant="contained"
            type="submit"
            onClick={onSaveNutridenomics}
          >
            Update
          </Button>
        </div>
      </div>
      <LoadingComponent loading={loading} />
    </div >
  );
};

export default NutriGenomics;
