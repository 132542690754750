import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import styles from "./PersonalDetails.module.css";
import { useParams } from "react-router-dom";

const PersonalDetails = ({
  setIsData,
  isData,
  addDisabled,
  data,
  setData,
  getPatientDetails,
  errorMessages,
  errorMessage,
  barCode,
  setBarCode,
  setErrorMessage,
  setErrorMessages,
  patientDetails,
  setPatientDetails,
  setFileData,
  setFileInput,
  setSummaryReportPreview
}) => {
  const { report_id } = useParams();
  const updateFields = useCallback(() => {
    setData(data);
  }, [data]);
  const [changeBarcode, setChangeBarcode] = useState(false);
  //phone number textfeild
  const handleInput = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    event.target.value = value.slice(0, 10);
  };


  useEffect(() => {
    if (Object.keys(data)?.length) {
      updateFields();
    }
  }, [data]);

  return (
    <div className={styles.personaldetails}>
      <div className={styles.visitid}>
        <div className={styles.lablecontainer}>
          <label className={styles.lable}>
            Barcode<strong style={{ color: "red" }}>*</strong>
          </label>
        </div>
        <div style={{ display: "flex", gap: "2rem", width: "100%" }}>
          <TextField
            className={styles.inputField}
            color="primary"
            placeholder="Enter Barcode"
            variant="outlined"
            sx={{
              "& .MuiInputBase-root": {
                height: "30px",
                fontSize: "clamp(14px,0.72vw,16px)",
                fontFamily: "'IBM Plex Sans', Sans-serif",
                fontWeight: "500",
              },
              "& .MuiOutlinedInput-input": {
                padding: "5px 14px !important",
              },
            }}
            value={barCode}
            disabled={changeBarcode == true || patientDetails?.length || report_id}
            onChange={(e) => {
              setBarCode(e.target.value);
              setData((prev) => ({
                ...prev,
                barcode_no: e.target.value,
              }));
              setErrorMessage('');
            }}
          />

          <Button
            className={styles.getdetailsButton}
            disabled={!barCode || report_id}
            variant="outlined"
            onClick={() => {
              getPatientDetails(barCode)
              setErrorMessage('')
            }}
          >
            Get Details
          </Button>
          {patientDetails?.length ? (
            <Button
              className={styles.getdetailsButton}
              variant="outlined"
              onClick={() => {
                setIsData(false);
                setChangeBarcode(false);
                setData({})
                setSummaryReportPreview(null)
                setPatientDetails(null)
                setFileData(null);
                setFileInput(true);
                setTimeout(() => {
                  setFileInput(false);
                }, 10);
              }}
            >
              Change Barcode
            </Button>
          ) : (
            ''
          )}
        </div>
        {errorMessage ? (
          <p style={{ color: "red", fontSize: "16px" }}>
            {errorMessage}
          </p>
        ) : (
          <p style={{ color: "red", fontSize: "16px" }}>
            {errorMessages["barcode_no"]}
          </p>
        )}
      </div>
      <div className={styles.headinglable}>
        <div className={styles.iconcontainer}>
          <img className={styles.icon} alt="" src="/icon.svg" />
        </div>
        <h2 className={styles.personalDetails}>Personal Details</h2>
      </div>
      <div className={styles.inputgroup}>
        <div className={styles.visitanduhid}>
          <div className={styles.visitid}>
            <div className={styles.lablecontainer}>
              <label className={styles.lable}>Visit Id<strong style={{ color: "red" }}>*</strong></label>
            </div>
            <TextField
              className={styles.inputField}
              disabled={isData == false}
              color="primary"
              placeholder="Visit Id"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": {
                  height: "30px",
                  fontSize: "clamp(12px,0.72vw,16px)",
                  fontFamily: "'IBM Plex Sans', Sans-serif",
                  fontWeight: "500",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "5px 14px !important",
                },
              }}
              value={data?.visit_id ? data?.visit_id : ""}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  visit_id: e.target.value,
                }));

              }}
            />
            <p style={{ color: "red", fontSize: "16px" }}>
              {errorMessages["visit_id"]}
            </p>
          </div>
          <div className={styles.visitid}>
            <div className={styles.lablecontainer}>
              <label className={styles.lable}>UHID Number</label>
            </div>
            <TextField
              className={styles.inputField}
              disabled={isData == false}
              color="primary"
              placeholder="Enter UHID/MR NO"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": {
                  height: "30px",
                  fontSize: "clamp(12px,0.72vw,16px)",
                  fontFamily: "'IBM Plex Sans', Sans-serif",
                  fontWeight: "500",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "5px 14px !important",
                },
              }}
              value={data["uhid_number"] ? data["uhid_number"] : ""}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  uhid_number: e.target.value,
                }));
              }}
            />
          </div>

          <div className={styles.visitid}>
            <div className={styles.lablecontainer}>
              <label className={styles.lable}>Mobile Number<strong style={{ color: "red" }}>*</strong></label>
            </div>
            <TextField
              className={styles.inputField}
              disabled={isData == false}
              color="primary"
              placeholder="Enter Mobile Number"
              variant="outlined"
              onInput={handleInput}
              sx={{
                "& .MuiInputBase-root": {
                  height: "30px",
                  fontSize: "clamp(12px,0.72vw,16px)",
                  fontFamily: "'IBM Plex Sans', Sans-serif",
                  fontWeight: "500",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "5px 14px !important",
                },
              }}
              value={data["mobile"] ? data["mobile"] : ""}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  mobile: e.target.value,
                }));

              }}
            />
            <p style={{ color: "red", fontSize: "16px" }}>
              {errorMessages["mobile"]}
            </p>
          </div>
        </div>
        <div className={styles.visitanduhid}>
          <div className={styles.name}>
            <div className={styles.lablecontainer}>
              <label className={styles.lable}>
                Patient Name<strong style={{ color: "red" }}>*</strong>
              </label>
            </div>
            <TextField
              className={styles.inputField}
              disabled={isData == false}
              color="primary"
              placeholder="Enter Patient Name"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": {
                  height: "30px",
                  fontSize: "clamp(12px,0.72vw,16px)",
                  fontFamily: "'IBM Plex Sans', Sans-serif",
                  fontWeight: "500",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "5px 14px !important",
                },
              }}
              value={data["name"] ? data["name"] : ""}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));

              }}
            />
            <p style={{ color: "red", fontSize: "16px" }}>
              {errorMessages["name"]}
            </p>
          </div>
          <div className={styles.name}>
            <div className={styles.lablecontainer}>
              <label className={styles.lable}>
                Age<strong style={{ color: "red" }}>*</strong>
              </label>
            </div>
            <TextField
              className={styles.inputField}
              disabled={isData == false}
              color="primary"
              placeholder="Enter Age"
              variant="outlined"
              sx={{
                "& .MuiInputBase-root": {
                  height: "30px",
                  fontSize: "clamp(12px,0.72vw,16px)",
                  fontFamily: "'IBM Plex Sans', Sans-serif",
                  fontWeight: "500",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "5px 14px !important",
                },
              }}
              value={data["age"] ? data["age"] : ""}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  age: e.target.value,
                }));

              }}
            />
            <p style={{ color: "red", fontSize: "16px" }}>
              {errorMessages["age"]}
            </p>
          </div>
          <div className={styles.name}>
            <div className={styles.lablecontainer}>
              <label className={styles.lable}>
                Gender<strong style={{ color: "red" }}>*</strong>
              </label>
            </div>
            <FormControl
              className={styles.dropdown}
              variant="outlined"
              sx={{
                borderRadius: "0px 0px 0px 0px",
                width: "250px",
                height: "30px",
                m: 0,
                p: 0,
                "& .MuiInputBase-root": {
                  m: 0,
                  p: 0,
                  minHeight: "30px",
                  justifyContent: "center",
                  display: "inline-flex",
                  fontSize: "13px",
                  fontWeight: "500",
                  fontFamily: "'IBM Plex Sans', Sans-serif",
                  paddingLeft: "1rem",
                },
                "& .MuiInputLabel-root": {
                  m: 0,
                  p: 0,
                  minHeight: "30px",
                  display: "inline-flex",
                },
                "& .MuiMenuItem-root": {
                  m: 0,
                  p: 0,
                  height: "30px",
                  display: "inline-flex",
                },
                "& .MuiSelect-select": {
                  m: 0,
                  p: 0,
                  height: "30px",
                  alignItems: "center",
                  display: "inline-flex",
                },
                "& .MuiInput-input": { m: 0, p: 0 },
                "& .MuiInputBase-input": {
                  textAlign: "left",
                  p: "0 !important",
                },
              }}
            >
              <InputLabel color="primary" />
              <Select
                disabled={isData == false}
                color="primary"
                displayEmpty
                value={data["gender"] || "" ? data["gender"] || "" : ""}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    gender: e.target.value,
                  }));

                }}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>
            <p style={{ color: "red", fontSize: "16px" }}>
              {errorMessages["gender"]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
