import { TextField } from "@mui/material";

const EditPhysicalEndurance = ({ physicalNote, setPhysicalNote, physicalValue, setPhysicalValue }) => {

    return (
        <div style={{ display: "flex", gap: '2rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Physical Endurance Value</label>
                <TextField
                    placeholder="Physical Endurance Value"
                    type='number'
                    value={physicalValue}
                    onChange={(e) => {
                        setPhysicalValue(e.target.value)
                    }}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Physical Endurance Notes</label>
                <TextField
                    placeholder="Physical Endurance Notes"
                    type='text'
                    value={physicalNote}
                    onChange={(e) => {
                        setPhysicalNote(e.target.value)
                    }}
                />
            </div>

        </div>
    );
}
export default EditPhysicalEndurance;