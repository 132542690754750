import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

const LoadingComponent = ({ loading }) => {
  return (
    <Backdrop
      sx={{
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        color: "green",
        backgroundColor: "rgba(256, 256, 256, 0.3)",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={Boolean(loading)}
    >
      <CircularProgress />
    </Backdrop>
  );
};
export default LoadingComponent;