import React, { useState, useEffect, useRef } from "react";
import { ReactDOM } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AgGridReact } from "ag-grid-react";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const GenerateReport = (props) => {
  const items = [
    {
      speciality: "Diabetes Panel",

      rows: [
        {
          class: ["Meglitinide"],
          standard: ["Nateglinide"],
          usewithaction: ["Repaglinide"],
          avoid: [],
        },
        {
          class: ["Sulphonyl urea"],
          standard: ["Chloropropamide", "Gliclazide", "Glimeparide"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["Thiazolidine"],
          standard: ["Nateglinide"],
          usewithaction: ["Rosiglitazone"],
          avoid: ["Mexelitine"],
        },
        {
          class: ["Dipeptidyl"],
          standard: ["Sitagliptin", "Vildagliptin", "Teneligliptin"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["SGL2 inhibitor"],
          standard: ["Depagliflozin"],
          usewithaction: ["Metformin"],
          avoid: [],
        },
        {
          class: ["others"],
          standard: [],
          usewithaction: [],
          avoid: [],
        },
      ],
    },

    {
      speciality: "Cardiovascular",

      rows: [
        {
          class: ["Meglitinide"],
          standard: ["Nateglinide"],
          usewithaction: ["Repaglinide"],
          avoid: [],
        },
        {
          class: ["Sulphonyl urea"],
          standard: ["Chloropropamide", "Gliclazide", "Glimeparide"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["Thiazolidine"],
          standard: ["Nateglinide"],
          usewithaction: ["Rosiglitazone"],
          avoid: ["Mexelitine"],
        },
        {
          class: ["Dipeptidyl"],
          standard: ["Sitagliptin", "Vildagliptin", "Teneligliptin"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["SGL2 inhibitor"],
          standard: ["Depagliflozin"],
          usewithaction: ["Metformin"],
          avoid: [],
        },
        {
          class: ["others"],
          standard: [],
          usewithaction: [],
          avoid: [],
        },
      ],
    },
    {
      speciality: "Gastroenterology",
      rows: [
        {
          class: ["Meglitinide"],
          standard: ["Nateglinide"],
          usewithaction: ["Repaglinide"],
          avoid: [],
        },
        {
          class: ["Sulphonyl urea"],
          standard: ["Chloropropamide", "Gliclazide", "Glimeparide"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["Thiazolidine"],
          standard: ["Nateglinide"],
          usewithaction: ["Rosiglitazone"],
          avoid: ["Mexelitine"],
        },
        {
          class: ["Dipeptidyl"],
          standard: ["Sitagliptin", "Vildagliptin", "Teneligliptin"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["SGL2 inhibitor"],
          standard: ["Depagliflozin"],
          usewithaction: ["Metformin"],
          avoid: [],
        },
        {
          class: ["others"],
          standard: [],
          usewithaction: [],
          avoid: [],
        },
      ],
    },
    {
      speciality: "Neurology",

      rows: [
        {
          class: ["Meglitinide"],
          standard: ["Nateglinide"],
          usewithaction: ["Repaglinide"],
          avoid: [],
        },
        {
          class: ["Sulphonyl urea"],
          standard: ["Chloropropamide", "Gliclazide", "Glimeparide"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["Thiazolidine"],
          standard: ["Nateglinide"],
          usewithaction: ["Rosiglitazone"],
          avoid: ["Mexelitine"],
        },
        {
          class: ["Dipeptidyl"],
          standard: ["Sitagliptin", "Vildagliptin", "Teneligliptin"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["SGL2 inhibitor"],
          standard: ["Depagliflozin"],
          usewithaction: ["Metformin"],
          avoid: [],
        },
        {
          class: ["others"],
          standard: [],
          usewithaction: [],
          avoid: [],
        },
      ],
    },
    {
      speciality: "Psychiatry",
      rows: [
        {
          class: ["Meglitinide"],
          standard: ["Nateglinide"],
          usewithaction: ["Repaglinide"],
          avoid: [],
        },
        {
          class: ["Sulphonyl urea"],
          standard: ["Chloropropamide", "Gliclazide", "Glimeparide"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["Thiazolidine"],
          standard: ["Nateglinide"],
          usewithaction: ["Rosiglitazone"],
          avoid: ["Mexelitine"],
        },
        {
          class: ["Dipeptidyl"],
          standard: ["Sitagliptin", "Vildagliptin", "Teneligliptin"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["SGL2 inhibitor"],
          standard: ["Depagliflozin"],
          usewithaction: ["Metformin"],
          avoid: [],
        },
        {
          class: ["others"],
          standard: [],
          usewithaction: [],
          avoid: [],
        },
      ],
    },
    {
      speciality: "Pain Management",
      rows: [
        {
          class: ["Meglitinide"],
          standard: ["kkkkk"],
          usewithaction: ["Repaglinide"],
          avoid: [],
        },
        {
          class: ["Sulphonyl urea"],
          standard: ["Chloropropamide", "Gliclazide", "Glimeparide"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["Thiazolidine"],
          standard: ["Nateglinide"],
          usewithaction: ["Rosiglitazone"],
          avoid: ["Mexelitine"],
        },
        {
          class: ["Dipeptidyl"],
          standard: ["Sitagliptin", "Vildagliptin", "Teneligliptin"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["SGL2 inhibitor"],
          standard: ["Depagliflozin"],
          usewithaction: ["Metformin"],
          avoid: [],
        },
        {
          class: ["others"],
          standard: [],
          usewithaction: [],
          avoid: [],
        },
      ],
    },
    {
      speciality: "Ruematology",
      rows: [
        {
          class: ["Meglitinide"],
          standard: ["Nateglinide"],
          usewithaction: ["Repaglinide"],
          avoid: [],
        },
        {
          class: ["Sulphonyl urea"],
          standard: ["Chloropropamide", "Gliclazide", "Glimeparide"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["Thiazolidine"],
          standard: ["Nateglinide"],
          usewithaction: ["Rosiglitazone"],
          avoid: ["Mexelitine"],
        },
        {
          class: ["Dipeptidyl"],
          standard: ["Sitagliptin", "Vildagliptin", "Teneligliptin"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["SGL2 inhibitor"],
          standard: ["Depagliflozin"],
          usewithaction: ["Metformin"],
          avoid: [],
        },
        {
          class: ["others"],
          standard: [],
          usewithaction: [],
          avoid: [],
        },
      ],
    },
    {
      speciality: "Immunosuppressants",
      rows: [
        {
          class: ["Meglitinide"],
          standard: ["Nateglinide"],
          usewithaction: ["Repaglinide"],
          avoid: [],
        },
        {
          class: ["Sulphonyl urea"],
          standard: ["Chloropropamide", "Gliclazide", "Glimeparide"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["Thiazolidine"],
          standard: ["Nateglinide"],
          usewithaction: ["Rosiglitazone"],
          avoid: ["Mexelitine"],
        },
        {
          class: ["Dipeptidyl"],
          standard: ["Sitagliptin", "Vildagliptin", "Teneligliptin"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["SGL2 inhibitor"],
          standard: ["Depagliflozin"],
          usewithaction: ["Metformin"],
          avoid: [],
        },
        {
          class: ["others"],
          standard: [],
          usewithaction: [],
          avoid: [],
        },
      ],
    },
    {
      speciality: "Anti-Cancer",
      rows: [
        {
          class: ["Meglitinide"],
          standard: ["Nateglinide"],
          usewithaction: ["Repaglinide"],
          avoid: [],
        },
        {
          class: ["Sulphonyl urea"],
          standard: ["Chloropropamide", "Gliclazide", "Glimeparide"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["Thiazolidine"],
          standard: ["Nateglinide"],
          usewithaction: ["Rosiglitazone"],
          avoid: ["Mexelitine"],
        },
        {
          class: ["Dipeptidyl"],
          standard: ["Sitagliptin", "Vildagliptin", "Teneligliptin"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["SGL2 inhibitor"],
          standard: ["Depagliflozin"],
          usewithaction: ["Metformin"],
          avoid: [],
        },
        {
          class: ["others"],
          standard: [],
          usewithaction: [],
          avoid: [],
        },
      ],
    },
    {
      speciality: "Anti-Microbials",
      rows: [
        {
          class: ["Meglitinide"],
          standard: ["Nateglinide"],
          usewithaction: ["Repaglinide"],
          avoid: [],
        },
        {
          class: ["Sulphonyl urea"],
          standard: ["Chloropropamide", "Gliclazide", "Glimeparide"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["Thiazolidine"],
          standard: ["Nateglinide"],
          usewithaction: ["Rosiglitazone"],
          avoid: ["Mexelitine"],
        },
        {
          class: ["Dipeptidyl"],
          standard: ["Sitagliptin", "Vildagliptin", "Teneligliptin"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["SGL2 inhibitor"],
          standard: ["Depagliflozin"],
          usewithaction: ["Metformin"],
          avoid: [],
        },
        {
          class: ["others"],
          standard: [],
          usewithaction: [],
          avoid: [],
        },
      ],
    },
    {
      speciality: "Hematological",
      rows: [
        {
          class: ["Meglitinide"],
          standard: ["Nateglinide"],
          usewithaction: ["Repaglinide"],
          avoid: [],
        },
        {
          class: ["Sulphonyl urea"],
          standard: ["Chloropropamide", "Gliclazide", "Glimeparide"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["Thiazolidine"],
          standard: ["Nateglinide"],
          usewithaction: ["Rosiglitazone"],
          avoid: ["Mexelitine"],
        },
        {
          class: ["Dipeptidyl"],
          standard: ["Sitagliptin", "Vildagliptin", "Teneligliptin"],
          usewithaction: [],
          avoid: [],
        },
        {
          class: ["SGL2 inhibitor"],
          standard: ["Depagliflozin"],
          usewithaction: ["Metformin"],
          avoid: [],
        },
        {
          class: ["others"],
          standard: [],
          usewithaction: [],
          avoid: [],
        },
      ],
    },
  ];

  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([
    { field: "Class" },
    { field: "Standard" },
    { field: "Use with Action" },
    { field: "Avoid" },
  ]);

  const card = (
    <React.Fragment>
      <CardContent>
        <Typography className="pb-1" pt={3}>
          Name :
        </Typography>
        <Typography className="pb-1" pt={3}>
          Age/Gender :
        </Typography>
        <Typography className="pb-1" pt={3}>
          Ref Doctor :
        </Typography>
        <Typography className="pb-1" pt={3}>
          Test : DNA Decoder Panel
        </Typography>
      </CardContent>
    </React.Fragment>
  );

  const defaultColDef = {
    sortable: true,
    //floatingFilter:true,
    // filter:true
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/summary`;
    let options = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
    let result = await fetch(url, options)
      .then((result) => result.json())
      .then((rowData) => {
        setRowData(rowData.records);
      });
  };
  const rowlistItems = (rows) => {
    return rows.map((row) => (
      <TableRow key={row.toString()}>
        <TableCell>
          {row.class.map((list) => (
            <li key={list.toString()}>{list}</li>
          ))}
        </TableCell>
        <TableCell>
          {row.standard.map((item) => (
            <li key={item.toString()}>{item}</li>
          ))}
        </TableCell>
        <TableCell>
          {row.usewithaction.map((items) => (
            <li key={items.toString()}>{items}</li>
          ))}
        </TableCell>
        <TableCell>
          {" "}
          {row.avoid.map((lists) => (
            <li key={lists.toString()}>{lists}</li>
          ))}
        </TableCell>
      </TableRow>
    ));
  };

  const listItems = items.map((item) => (
    <ListItem disablePadding key={item.toString()}>
      <ListItemButton width="1000px">
        <ListItemIcon>
          <ContentPasteIcon />
        </ListItemIcon>
        <ListItemText>{item.speciality}</ListItemText>
      </ListItemButton>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Class</TableCell>
            <TableCell>Standard</TableCell>
            <TableCell>Use with caution</TableCell>
            <TableCell>Avoid</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{rowlistItems(item.rows)}</TableBody>
      </Table>
    </ListItem>
  ));

  return (
    <div style={{ height: 400, margin: "0 auto", width: "100%" }}>
      <br></br>
      <Box
        sx={{
          minWidth: 275,
          display: "inline-grid",
          justifyContent: "center",
          variant: "outlined",
        }}
      >
        <Card variant="outlined">{card}</Card>
      </Box>
      <Box
        sx={{
          minWidth: 300,
          display: "inline-grid",
          justifyContent: "center",
        }}
      >
        <Card variant="outlined">{card}</Card>
      </Box>

      <li>{listItems}</li>
    </div>
  );
};

export default GenerateReport;
