import { Alert, AlertTitle, Snackbar, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { prepareURLEncodedParams } from "../../../utils/requestUtils";
import LoadingComponent from "../../Core/LoadingComponent";
import EditPatientDetails from "./EditPatientDetails";
import ViewPatientDetails from "./ViewPatientDetails";

const PreviewOfPharmacoGenomics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AuthStateData = useSelector((state) => state.Signin);
  const { report_id, result_file_id } = useParams();

  const [previewdata, setPreviewData] = useState();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMesssage] = useState();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [resultFile, setResultFile] = useState({});
  const [editResultFile, setEditResultFile] = useState({});
  const [message, setMessage] = useState("");
  const [processSuccess, setProcessSuccess] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const handlePreview = async () => {
    setLoading(true);
    try {
      let queryParams = { category: "PHARMACOGENOMIC" };
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/preview-report/${report_id}`;
      let queryParamsURL = prepareURLEncodedParams(url, queryParams);

      let options = {
        method: "GET",
        headers: new Headers({
          content_Type: "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(queryParamsURL, options);
      var template = await response.text();
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        setPreviewData(template);
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getPrimaryReportById = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        setResultFile(resJson.data ? resJson.data : {});
        setEditResultFile(resJson.data ? resJson.data : {});
        handlePreview();
      } else if (response.status == 422) {
        setMessage(resJson.data);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onChangePatientDetails = async (payload) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}/update-patient-demographics`;
      let options = {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: new Headers({
          authorization: `${AuthStateData?.access_token}`,
          "content-type": "application/json",
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status == 200 || response.status == 201) {
        setProcessSuccess(resJson?.message);
        setShowSuccessAlert(true);
        setTimeout(() => {
          setProcessSuccess("");
          setShowSuccessAlert(false);
        }, 1500);
        handlePreview();
        setIsEditOpen(false);
        getPrimaryReportById();
        window.location.reload();
        return resJson;
      } else if (response.status == 422) {
        setMessage(resJson.errors);
      } else if (response.status === 401) {
        handleLogout();
      } else if (response.status === 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
    handlePreview();
    getPrimaryReportById();
  }, []);

  return (
    <div className="IframePreviewBlock" id="summaryDetails">
      {resultFile?.category == "PHARMACOGENOMICS" ? (
        <>
        <div>
          {isEditOpen ? (
            <EditPatientDetails
              isEditOpen={isEditOpen}
              resultFile={editResultFile}
              setResultFile={setEditResultFile}
              onSaveUserDetails={onChangePatientDetails}
              setIsEditOpen={setIsEditOpen}
              message={message}
              setMessage={setMessage}
            />
          ) : (
            <ViewPatientDetails
              setIsEditOpen={setIsEditOpen}
              resultFile={resultFile}
            />
          )}
        </div>
        <div className="editBtnGrp">
        <h6 className="headingText">Pharmacogenomics</h6>
        <Button
          disableElevation={true}
          className="editBtn"
          variant="outlined"
          startIcon={
            <img src="/edit-pink-icon.svg" alt="" width={12} height={12} />
          }
          onClick={() =>
            navigate(
              `/grand-reports/${result_file_id}/update-report-template/${report_id}/pharmaco-genomics/edit`
            )
          }
        >
          Edit
        </Button>
      </div>
        </>
      ) : (
        ""
      )}
      
      <div className="rishDisorder_pdf">
        <iframe
          width="100%"
          id="report"
          frameBorder="0"
          srcDoc={previewdata}
          title="pharmaco genomics"
        ></iframe>
      </div>
      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
      <LoadingComponent loading={loading} />
    </div>
  );
};
export default PreviewOfPharmacoGenomics;
