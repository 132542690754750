
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import timePipe from "../../Pipes/timePipe";
import styles from "./MainContainer.module.css";
import PreviewContainer from "./PreviewContaier/PreviewContainer";
import AddSummaryReportForm from "./SummaryReportForm/AddSummaryReportForm";
import LoadingComponent from "../Core/LoadingComponent";
const MainContainer = () => {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { report_id } = useParams();
  const [summaryData, setSummaryData] = useState();

  const AuthStateData = useSelector((state) => state.Signin);
  const [summaryReportPreview, setSummaryReportPreview] = useState();
  const [data, setData] = useState({ date_of_receiving: null, date_of_collection: null, date_of_regestration: null });
  const [errorMessage, setErrorMessage] = useState("");
  const [barCode, setBarCode] = useState("");
  const [summaryReportId, setSummaryReportId] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [successMessage, setSuccessMesssage] = useState("");
  const [saveSuccessDialog, setSaveSuccessDialog] = useState(false);
  const [addDisabled, setAddDisabled] = useState(false);
  const [summaryDataId, setSummaryDataId] = useState("");
  const [downloadErrorMessage, setDownloadErrorMessage] = useState("");
  const [downloadSuccessMessage, setDownloadSuccessMessage] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const [isData, setIsData] = useState(false);

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    } else {
      if (report_id) {
        getSummaryReportDetails();
        getSummaryReportPreview();
      }
    }
  }, []);
  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const updateData = (data) => {
    return {
      name: data?.PatientName,
      visit_id: data?.VisitiID,
      barcode_no: data?.BarcodeNo,
      ref_doctor: data?.RefDoctor,
      client_code: data?.ClientCode,
      uhid_number: data["UHID/MR NO"],
      age: data?.Age,
      gender: data?.Gender,
      hospital_name: data?.HospitalName,
      client_add: data?.ClientAdd,
      client_name: data?.ClientName,
      date_of_regestration: timePipe(
        data?.RegisterationDate,
        "YYYY-MM-DD hh:mm:ss"
      ),
      date_of_collection: timePipe(data?.CollectionDate, "YYYY-MM-DD hh:mm:ss"),
      date_of_reporting: timePipe(data?.ReceivedDate, "YYYY-MM-DD hh:mm:ss"),
      date_of_receiving: timePipe(data?.ReceivedDate, "YYYY-MM-DD hh:mm:ss"),
      mobile: data?.Mobile,
      email: data?.Email,
      test: "DNA Decoder",
      report_path: data?.report_path || summaryData?.report_path,
    };
  };

  const getPatientDetails = async (barcode = "") => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports/patient?barcode=${barcode}`;
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      if (barcode) {
        let res = await fetch(url, options);
        let resJson = await res.json();

        if (res.status === 200 || res.status === 201) {
          setIsData(true)
          setPatientDetails(resJson?.data);
          setData(updateData(resJson?.data[0]));
          setBarCode(resJson?.data[0]?.BarcodeNo);
        } else if (res.status === 401) {
          handleLogout();
        } else if (res.status == 400) {
          setErrorMessage(resJson.message);
          setData({});

        } else {
          setErrorMessage(resJson.message || "Something went wrong");
        }
      }
    } catch (err) {
      console.error(err);
      setErrorMessage(err.message || "Something went wrong");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const getSummaryReportDetails = async (id) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports/${id || report_id
        }`;
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let res = await fetch(url, options);
      let resJson = await res.json();

      if (res.status === 200 || res.status === 201) {
        setIsData(true)
        setBarCode(resJson?.data?.barcode_no);
        setSummaryData(resJson?.data);
        setData(resJson?.data);
      } else if (res.status === 401) {
        handleLogout();
      }

    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getSummaryReportPreview = async (id = "") => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports/report/${id || report_id
        }/preview-report`;
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let res = await fetch(url, options);
      let resJson = await res.text();
      if (res.status === 200 || res.status === 201) {
        setSummaryReportPreview(resJson);
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    } else {
      if (report_id) {
        // getPatientDetails();
      }
    }
  }, []);

  const generateQRCode = async (id) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports/generate-qrcode/${id}`;
      const options = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      const response = await fetch(url, options);
      const responseData = await response.json();

      if (response.status == 201 || response.status == 200) {
        await getSummaryReportPreview(id);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const addSummaryReport = async () => {
    setLoading(true);
    setErrorMessages({});
    setErrorMessage("");
    let body = { ...data, barcode_no: barCode };
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports`;
      let options = {
        method: "POST",
        body: JSON.stringify(body),
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let res = await fetch(url, options);
      let resJson = await res.json();
      if (res.status === 200 || res.status === 201) {
        await generateQRCode(resJson?.data?._id);
        setSaveSuccessDialog(true);
        setSummaryDataId(resJson?.data?._id);
      } else if (res.status === 401 || res.status == 403) {
        handleLogout();
      } else if (res.status == 422) {
        setErrorMessages(resJson?.errors);
      } else {
        setErrorMessage(resJson.message || "Failed! creating summary report");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (err) {
      console.error(err);
      setErrorMessage(err.message || "Failed! creating summary report");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const updateSummaryReport = async () => {
    setLoading(true);
    setErrorMessages({});
    setErrorMessage("");
    let body = { ...data, barcode_no: barCode, _id: report_id };
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports/${report_id}`;
      let options = {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let res = await fetch(url, options);
      let resJson = await res.json();

      if (res.status === 200 || res.status === 201) {
        getSummaryReportPreview(report_id);
        setSuccessMesssage(
          resJson.message || "Summary report updated successfully!"
        );
        setTimeout(() => {
          setSuccessMesssage("");
          navigate(-1);
        }, 1000);
      } else if (res.status === 401 || res.status == 403) {
        handleLogout();
      } else if (res.status == 422) {
        setErrorMessages(resJson?.errors);
      } else {
        setErrorMessage(resJson.message || "Failed! updating summary report");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (err) {
      console.error(err);
      setErrorMessage(err.message || "Failed! updating summary report");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const previewDummySummaryReport = async () => {
    setErrorMessages({});
    setErrorMessage("");

    try {
      setLoading(true);
      let url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports/report/preview-report/dummy`;
      let body = { ...data, barcode_no: barCode };
      let options = {
        method: "POST",
        body: JSON.stringify(body),
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      const response = await fetch(url, options);

      if (response.status == 200 || response.status === 201) {
        const responseData = await response.text();
        setSummaryReportPreview(responseData);
      } else {
        const responseData = await response.json();
        if (response.status === 401 || response.status == 403) {
          handleLogout();
        } else if (response.status == 422) {
          setErrorMessages(responseData?.errors);
        } else {
          setErrorMessage(
            responseData.message || "Failed! creating summary report"
          );
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (id) => {
    setDownloadLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports/report/${id}/download/summary-report`;
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let res = await fetch(url, options);
      let resJson = await res.json();
      if (res.status === 200 || res.status === 201) {
        await downloadFile(resJson.data?.file_name, resJson.data?.url);
      } else {
        setDownloadErrorMessage(resJson?.message || "Report download failed!");
        setTimeout(() => {
          setDownloadErrorMessage("");
        }, 3000);
      }
    } catch (err) {
      setDownloadErrorMessage(err?.message || "Report download failed!");
      setTimeout(() => {
        setDownloadErrorMessage("");
      }, 3000);
      console.error(err);
    } finally {
      setDownloadLoading(false);
    }
  };

  const downloadFile = async (name, url) => {
    let urlImage = `${url}`;

    try {
      if (url) {
        fetch(urlImage, {
          cache: "no-store", // Ensure that the request doesn't use the cache
        })
          .then((response) => {
            // Get the filename from the response headers
            const contentDisposition = response.headers.get(
              "content-disposition"
            );
            let filename = name || "report.pdf"; // Default filename if not found in headers

            if (contentDisposition) {
              const filenameMatch = contentDisposition.match(/filename="(.+)"/);
              if (filenameMatch && filenameMatch.length > 1) {
                filename = filenameMatch[1];
              }
            }

            // Create a URL for the blob
            return response.blob().then((blob) => ({ blob, filename }));
          })
          .then(({ blob, filename }) => {
            const blobUrl = window.URL.createObjectURL(blob);

            const downloadLink = document.createElement("a");
            downloadLink.href = blobUrl;
            downloadLink.download = filename; // Use the obtained filename
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            // Clean up the blob URL
            window.URL.revokeObjectURL(blobUrl);
            setDownloadSuccessMessage("Report downloaded Successful!");
            setTimeout(() => {
              setDownloadSuccessMessage("");
            }, 3000);
          })
          .catch((error) => {
            console.error("Error downloading file:", error);
            setDownloadErrorMessage("Report download failed!");
            setTimeout(() => {
              setDownloadErrorMessage("");
            }, 3000);
          });
      }
    } catch (err) {
      console.error(err);
      setDownloadErrorMessage("Report download failed!");
      setTimeout(() => {
        setDownloadErrorMessage("");
      }, 3000);
    }
  };
  return (
    <main className={styles.maincontainer}>
      <AddSummaryReportForm
        setIsData={setIsData}
        isData={isData}
        data={data}
        setData={setData}
        patientDetails={patientDetails}
        getPatientDetails={getPatientDetails}
        summaryData={summaryData}
        setSummaryData={setSummaryData}
        setBarCode={setBarCode}
        barCode={barCode}
        addDisabled={addDisabled}
        errorMessages={errorMessages}
        report_id={report_id}
        successMessage={successMessage}
        errorMessage={errorMessage}
        loading={loading}
        previewDummySummaryReport={previewDummySummaryReport}
        setErrorMessage={setErrorMessage}
        setErrorMessages={setErrorMessages}
        setPatientDetails={setPatientDetails}
        setSummaryReportPreview={setSummaryReportPreview}
      />
      <PreviewContainer
        loading={downloadLoading}
        summaryReportPreview={summaryReportPreview}
        summaryReportId={summaryReportId}
        addSummaryReport={addSummaryReport}
        updateSummaryReport={updateSummaryReport}
        handleDownload={handleDownload}
      />
      <LoadingComponent loading={loading} />

      {/* <Snackbar
        open={!!errorMessage}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar> */}
      <Dialog open={saveSuccessDialog} PaperProps={{
        sx: {
          borderRadius: "0.7rem"
        }
      }}>
        <Card>
          <div className={styles.summarySuccessCard}

          >
            <img src={"/saved-success.svg"} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                className={styles.successText}
              >
                Success
              </p>
              <span style={{ color: "#898989", fontFamily: "'IBM Plex Sans', Sans-serif" }}>
                The report has been generated successfully
              </span>
            </div>

            <DialogActions
              sx={{
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
                padding: "1.5rem 0 0 0 ",
                width: "100%",
              }}
            >
              <Button className={styles.successbackButton}
                onClick={() => navigate("/sumamry-reports")}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  "& .MuiButton-root": {
                    color: "#ff583e",
                    borderColor: "#ff583e",
                  },
                }}
              >
                Back
              </Button>
              <Button className={styles.successDownloadButton}
                onClick={() => handleDownload(summaryDataId)}
                variant="contained"
                sx={{
                  textTransform: "none",
                  "& .MuiButton-root": {
                    color: "#fff",
                    background: "#006699",
                  },
                }}
              >
                {downloadLoading ? (
                  <CircularProgress size={"1.5rem"} sx={{ color: "#fff" }} />
                ) : (
                  "Download Card"
                )}
              </Button>
            </DialogActions>
          </div>
        </Card>
      </Dialog>

      <Snackbar
        open={!!downloadSuccessMessage}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {downloadSuccessMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!downloadErrorMessage}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {downloadErrorMessage}
        </Alert>
      </Snackbar>
    </main>
  );
};

export default MainContainer;
