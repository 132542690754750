import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

const NotFound = () => {

    const navigate = useNavigate();

    const handleClose = () => {
        //localStorage.clear();
       // window.location.reload();
        navigate("/");
      };

  return (
    <div style={{textAlign:"center"}}>
        <Link  to="/createform" style={{ textDecoration: 'none' }}><Button  variant="contained" size="small">Back To Page</Button></Link>
        <h1>404 Error</h1>
        <h3>Page Not Found</h3>
    </div>
  )
}

export default NotFound