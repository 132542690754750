import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { AlertTitle } from "@mui/material";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "../components/UpdatePassword.css";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "./Core/LoadingComponent";

const UpdatePassword = () => {
  const AuthStateData = useSelector((state) => state.Signin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [success, setSuccess] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState();
  const [allValidationErrors, setAllValidationErrors] = useState("");
  const [usernameValidationErrors, setUserNameValidationErrors] = useState("");
  const [passwordValidationErrors, setPasswordValidationErrors] = useState("");
  let token = localStorage.getItem("code");

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
    handleUpdate();
  }, []);

  let handleUpdate = async (e) => {
    e.preventDefault();
    setShowLoading(true);
    try {
      setErrorMessage();
      setUserNameValidationErrors("");
      setPasswordValidationErrors("");
      setAllValidationErrors("");
      setValidationErrors("");
      setSuccess("");
      let url = `${process.env.REACT_APP_API_BASE_URL}/users/update-password`;
      let options = {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
        body: JSON.stringify({
          user_name: username,
          password: password,
          confirm_password: confirmpassword,
        }),
      };
      let res = await fetch(url, options);
      let responseData = await res.json();
      if (res.status === 200 || res.status === 201) {
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          dispatch({
            type: "SIGN_OUT",
            payload: {},
          });
          navigate("/");
        }, 1500);
        setSuccess(responseData.message);
        setUserName("");
        setPassword("");
        setConfirmPassword("");
      } else if (res.status === 401) {
        setValidationErrors(responseData.message);
        setErrorMessage("");
      } else if (res.status === 400) {
        setErrorMessage(responseData.message);
        setValidationErrors("");
      } else if (res.status === 422) {
        let allError = responseData.errors;
        setAllValidationErrors(allError);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoading(false);
    }
  };

  const getParameter = () => {
    navigate("/grand-reports");
  };

  return (
    <>
      <div id="signIn">
        <div className="loginCard">
          <div className="headingPart">
            <h3>Update Password</h3>
          </div>
          <form onSubmit={handleUpdate}>
            <div className="formControl">
              <TextField
                size="small"
                label="UserName"
                variant="outlined"
                type={"username"}
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <p style={{ color: "red" }}>{allValidationErrors?.user_name}</p>
            </div>
            <div className="formControl">
              <TextField
                size="small"
                label="Password"
                variant="outlined"
                type={"password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 2 }}
              />
              <p style={{ color: "red" }}>{allValidationErrors?.password}</p>
            </div>
            <div className="formControl">
              <TextField
                size="small"
                label="Confirmpassword"
                variant="outlined"
                type={"confirm_password"}
                value={confirmpassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <p style={{ color: "red" }}>{allValidationErrors?.confirm_password}</p>
            </div>
            <Typography
              variant="h6"
              color="error"
              style={{ fontSize: "12px", paddingLeft: "15px" }}
            >
              {validationErrors}
            </Typography>
            {showLoading === true ? <p> Updating Password ... </p> : ""}

            <div className="loginButtonGroup">
              <Button
                className="loginButton mr-10"
                variant="contained"
                onClick={getParameter}
              >
                Cancel
              </Button>
              <Button
                className="loginButton"
                type="submit"
                variant="contained"
                disabled={showLoading === true ? true : false}
              >
                Update
              </Button>
            </div>
            <LoadingComponent loading={showLoading} />
          </form>

          <Snackbar
            open={showSuccessAlert}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Password updated successfully
            </Alert>
          </Snackbar>

          <Typography
            variant="h6"
            color="success"
            style={{
              fontSize: "14px",
              textAlign: "center",
              color: "green",
            }}
          >
            {success}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
