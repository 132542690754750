import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "rsuite/dist/rsuite.css";
import { Toaster, toast } from "sonner";
import timePipe from "../../Pipes/timePipe";
import styles from "./summery.module.css";

const SummariesTable = ({ data, getAllSummaries, Summaryloading, loading }) => {
  const navigate = useNavigate();
  const AuthStateData = useSelector((state) => state.Signin);
  const dispatch = useDispatch();

  const [deleteLoadingId, setDeleteLoadingId] = useState("");
  const [previewLoadingId, setPreviewLoadingId] = useState("");
  const [downloadingId, setDownloadingId] = useState("");
  const [downloadTrackingId, setDownloadTrackingId] = useState("");
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [openId, setOpenId] = useState("");

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const handleDelete = (id) => {
    setOpenId(id);
  };

  const confirmDelete = async (id) => {
    if (!id) {
      toast.error("Download failed!");
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return;
    }
    setDeleteLoadingId(id);
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports/${id}`;
      const options = {
        method: "DELETE",
        headers: new Headers({
          Authorization: AuthStateData.access_token,
        }),
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      if (response.status == 200 || response.status == 201) {
        setOpenId("");
        getAllSummaries({});
        toast.success(responseData.message || "Deleted Successfully");
        setTimeout(() => {
          toast.dismiss();
        }, 3000);
      } else if (response.status == 401 || response.status == 403) {
        handleLogout();
      } else {
        toast.error(responseData.message || "Download failed!");
        setTimeout(() => {
          toast.dismiss();
        }, 3000);
      }
    } catch (err) {
      console.error(err);
      toast.error(err.message || "Download failed!");
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
    } finally {
      setDeleteLoadingId("");
    }
  };

  const columns = [
    {
      field: "serial",
      headerName: "S No.",
      flex: 1,
      minWidth: 60,
      maxWidth: 60,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <p>{params.row?.serial ? params.row?.serial : ""}</p>
          </div>
        );
      },
    },
    {
      field: "barcode_no",
      headerName: "Bar Code",
      minWidth: 150,
      flex: 1,

      renderCell: (params) => {
        return (
          <div className="cellAction">
            <p>{params.row?.barcode_no ? params.row?.barcode_no : "---"}</p>
          </div>
        );
      },
    },
    {
      field: "visit_id",
      headerName: "Visit ID",
      minWidth: 150,

      flex: 1,

      renderCell: (params) => {
        return (
          <div className="cellAction">
            <p>{params.row?.visit_id ? params.row?.visit_id : "---"}</p>
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 300,
      maxWidth: 440,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <p>{params.row?.name ? params.row?.name : "---"}</p>
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Generated On",
      minWidth: 200,

      flex: 1,
      renderCell: (params) => {
        const formatDateAndTime = (dateString) => {
          if (!dateString) return "";

          let inputDate = new Date(dateString);
          inputDate.setHours(inputDate.getHours() + 5);
          inputDate.setMinutes(inputDate.getMinutes() + 30);
          return timePipe(inputDate, "DD/MM/YYYY hh:mm A");
        };

        return (
          <div className="cellAction">
            <p
              style={{
                background: "#E1E7EB",
                justifyContent: "center",
                padding: "4px 8px",
                borderRadius: "4px",
              }}
            >
              {params.row?.created_at
                ? formatDateAndTime(params.row?.created_at)
                : ""}
            </p>
          </div>
        );
      },
    },
    {
      field: "test",
      headerName: "Test Type",
      minWidth: 150,
      flex: 1,

      renderCell: (params) => {
        return (
          <div className="cellAction">
            <p>{params.row?.test ? params.row?.test : ""}</p>
          </div>
        );
      },
    },
    {
      field: "hospital_name",
      headerName: "Hospital Name",
      minWidth: 210,
      flex: 1,

      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title={params.row?.hospital_name}>
              <p>
                {params.row?.hospital_name?.length >= 24
                  ? params.row?.hospital_name?.slice(0, 24) + "..."
                  : params.row?.hospital_name}
              </p>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "actions",
      width: 180,
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title="Preview Summary Report">
              <IconButton
                onClick={() => {
                  setPreviewLoadingId(params?.row?._id);
                  if (!previewLoadingId)
                    handleDownload(params?.row?._id, false);
                }}
              >
                {previewLoadingId == params?.row?._id ? (
                  <CircularProgress size={"1rem"} />
                ) : (
                  <img
                    src="actionIcon/PreviewIcon.svg"
                    alt="privew"
                    className={styles.addSummeryIcon}
                  />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                color="success"
                size="small"
                onClick={() => {
                  navigate(`/edit-summary/${params?.row?._id}`);
                }}
              >
                <img
                  src="actionIcon/EditIcon.svg"
                  alt="edit"
                  className={styles.addSummeryIcon}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Summary Report">
              <IconButton
                onClick={(e) => {
                  if (downloadLoading) {
                    return;
                  }
                  setDownloadAnchorEl(e.currentTarget);
                  setDownloadingId(params?.row?._id);
                  setDownloadTrackingId(params?.row?.report_tracking_id);
                }}
              >
                {downloadingId == params?.row?._id && downloadLoading ? (
                  <CircularProgress size={"1rem"} />
                ) : (
                  <img
                    src="actionIcon/downloadIcon.svg"
                    alt="download"
                    className={styles.addSummeryIcon}
                  />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={() =>
                  deleteLoadingId ? "" : handleDelete(params?.row?._id)
                }
              >
                <img
                  src="actionIcon/DeleteIcon.svg"
                  alt="delete"
                  className={styles.addSummeryIcon}
                />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleReportDownload = async (trackId) => {
    setDownloadLoading(true);
    if (!trackId) return;
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports/public/${trackId}`;
      let options = {
        method: "GET",
        headers: new Headers({
          Authorization: AuthStateData.access_token,
        }),
      };
      let response = await fetch(url, options);
      let responseData = await response.json();

      if (response.status == 200 || response.status == 201) {
        await downloadFile(
          responseData?.data?.visit_id,
          responseData?.data?.download_url,
          responseData?.message,
          "Report Downolad Successfully"
        );
      } else {
        toast.error(response.message || "Download failed!");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDownloadLoading(false);
    }
  };

  const downloadFile = async (name, url, message, summary) => {
    let urlImage = `${url}`;
    try {
      if (url) {
        fetch(urlImage, {
          cache: "no-store", // Ensure that the request doesn't use the cache
        })
          .then((response) => {
            // Get the filename from the response headers
            const contentDisposition = response.headers.get(
              "content-disposition"
            );
            let filename = name || "report.pdf"; // Default filename if not found in headers
            if (contentDisposition) {
              const filenameMatch = contentDisposition.match(/filename="(.+)"/);
              if (filenameMatch && filenameMatch.length > 1) {
                filename = filenameMatch[1];
              }
            }
            // Create a URL for the blob
            return response.blob().then((blob) => ({ blob, filename }));
          })
          .then(({ blob, filename }) => {
            const blobUrl = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement("a");
            downloadLink.href = blobUrl;
            downloadLink.download = filename; // Use the obtained filename
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            // Clean up the blob URL
            window.URL.revokeObjectURL(blobUrl);
            if (summary) {
              toast.success(summary);
            } else {
              toast.success(message);
            }
            setTimeout(() => {
              toast.dismiss();
            }, 3000);
          })
          .catch((error) => {
            console.error("Error downloading file:", error);
            toast.error("Report download failed!");
            setTimeout(() => {
              toast.dismiss();
            }, 3000);
          });
      }
    } catch (err) {
      console.error(err);
      toast.error("Report download failed!");
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
    }
  };

  const handleViewSummaryReport = async (data) => {
    if (!data.url) return;
    try {
      let response = await fetch(data?.url);
      const blob = await response.blob();
      let url = window.URL.createObjectURL(
        new Blob([blob], { type: "application/pdf" })
      );
      const printWindow = window.open(url, "_blank");
      printWindow.document.close();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownload = async (id, downloadFalse = true) => {
    setDownloadLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/summary-reports/report/${id}/download/summary-report`;
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let res = await fetch(url, options);
      let resJson = await res.json();
      if (res.status === 200 || res.status === 201) {
        if (downloadFalse) {
          await downloadFile(
            resJson.data?.file_name,
            resJson.data?.url,
            resJson?.message
          );
        } else {
          await handleViewSummaryReport(resJson?.data);
        }
      } else {
        toast.error(resJson?.message || "Report download failed!");
        setTimeout(() => {
          toast.dismiss();
        }, 3000);
      }
    } catch (err) {
      toast.error(err?.message || "Report download failed!");
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
      console.error(err);
    } finally {
      setPreviewLoadingId("");
      setDownloadingId("");
      setDownloadLoading(false);
    }
  };

  //scroll to top
  const gridApiRef = useRef();

  const scrollToRow = (rowIndex) => {
    gridApiRef.current.scrollToIndexes({
      rowIndex: rowIndex,
      columnIndex: 0, // Scroll to the first column
    });
  };

  const handleSortChange = (e) => {
    if (e.length) {
      let sort_by = e[0].field;
      let sort_type = e[0].sort;
      if (sort_by == "actions" || sort_by == "serial") {
        return;
      }
      getAllSummaries({ sortBy: sort_by, sortType: sort_type, page: 1 });

      scrollToRow(0);
    } else {
      scrollToRow(0);
      getAllSummaries({ sortBy: "created_at", sortType: "desc", page: 1 });
    }
  };

  return (
    <div id="users-table">
      <DataGrid
        className="summaryPageTable"
        style={{ height: "calc(100vh - 200px)" }}
        components={{
          NoRowsOverlay: () => (
            <div className="summaryNoDataBlock">
              {!data?.length && !Summaryloading ? (
                <div className="noSummaryImgBlock">
                  <img
                    src="/no-data.svg"
                    height={350}
                    width={350}
                    alt="nodata"
                  />
                  <p className="noDataText" >
                    No Data
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          ),
        }}
        onSortModelChange={handleSortChange}
        //sortModel={sortModel}
        apiRef={gridApiRef}
        rows={data && data?.length ? data : []}
        rowCount={data?.length || 0}
        columns={columns}
        getRowId={(row) => row.serial}
        pagination
        paginationMode="server"
        autoPageSize
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        getRowHeight={() => {
          return 40;
        }}
        sortingMode="server"
      />

      <Dialog
        open={!!openId}
        PaperProps={{
          sx: {
            borderRadius: "1rem",
            width: "80%",
            margin: "0",
            maxWidth: "350px",
          },
        }}
      >
        <Card className={styles.canceldeletePopup}>
          <DialogTitle className={styles.confirmDelete}>
            Confirm Delete!
          </DialogTitle>
          <DialogContent className={styles.sureyouwanttoDelete}>
            Are you sure you want to delete this summary?
          </DialogContent>
          <DialogActions
            sx={{
              "& .MuiDialogActions-root": {
                padding: "2rem",
                border: "1px solid",
              },
            }}
          >
            <Button
              className={styles.cancelButton}
              variant="outlined"
              onClick={() => setOpenId("")}
            >
              Cancel
            </Button>
            <Button
              className={styles.deleteButton}
              variant="contained"
              onClick={() => confirmDelete(openId)}
            >
              {deleteLoadingId ? (
                <CircularProgress size={"1.5rem"} sx={{ color: "#fff" }} />
              ) : (
                "Delete!"
              )}
            </Button>
          </DialogActions>
        </Card>
      </Dialog>

      <Menu
        open={!!downloadAnchorEl}
        anchorEl={downloadAnchorEl}
        onClose={() => {
          setDownloadAnchorEl(null);
          setDownloadingId("");
        }}
      >
        <MenuItem
          onClick={() => {
            if (downloadTrackingId) {
              handleReportDownload(downloadTrackingId);
              setDownloadAnchorEl(null);
            }
          }}
        >
          Download Report
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (downloadingId) handleDownload(downloadingId, true);
            setDownloadAnchorEl(null);
          }}
        >
          Download Card
        </MenuItem>
      </Menu>
      <Toaster richColors closeButton position="top-right" />
    </div>
  );
};

export default SummariesTable;
