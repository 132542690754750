import { useSelector } from "react-redux";
import styles from "./Reports.module.css";
import { useCallback, useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from "@mui/material";
import { Toaster, toast } from "sonner";
import LoadingComponent from "../../Core/LoadingComponent";

const Reports = ({
  data,
  setData,
  summaryData,
  setSummaryData,
  errorMessages,
  setErrorMessages,
  setFileData,
  setFileInput,
  fileInput,
  fileData
}) => {
  const updateFields = useCallback(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    if (Object.keys(data)?.length) {
      updateFields();
    }
  }, [data, updateFields]);

  const AuthStateData = useSelector((state) => state.Signin);
  const [loading, setLoading] = useState(false);
  const [fileDetails, setFileDetails] = useState();

  const [fileInputRender, setFileInputRender] = useState(false);
  const handleChange = (e) => {
    if (!(e.target?.files && e.target?.files[0])) {
      return;
    }
    uploadReport(e.target.files[0]);
    setFileDetails(e.target.files[0]);
    setFileData(e.target.files[0]);
    setErrorMessages('');
  };

  const uploadReport = async (file) => {
    setLoading(true);
    let options = {
      method: "POST",
      headers: new Headers({
        "content-type": "application/json",
        authorization: `${AuthStateData.access_token}`,
      }),
      body: JSON.stringify({
        original_name: file?.name,
        type: file.type,
        size: file.size,
      }),
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/summary-reports/presigned-url`,
        options
      );
      const responseData = await response.json();
      if (responseData.success) {
        setData((prev) => ({
          ...prev,
          report_path: responseData?.data?.path,
        }));

        let preSignedResponse = await fetch(responseData.data.target_url, {
          method: "PUT",
          body: file,
        });
        if (preSignedResponse?.status == 200) {
          toast.success("File Uploaded successfully");
        } else {
          toast.error("Upload report failed!");
        }
      } else {
        toast.error(responseData.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.reports}>
      <div className={styles.headinglable}>
        <div className={styles.iconcontainer}>
          <img className={styles.icon} alt="" src="/icon3.svg" />
        </div>
        <h2 className={styles.personalDetails}>
          Reports<strong style={{ color: "red" }}>*</strong>
        </h2>
      </div>
      {summaryData?.report_path ? (
        <div className={styles.uploadedFile}>
          <div className={styles.uploadedFileDetails}>

            <img src={"/file-pdf.svg"} />
            <Tooltip title={summaryData?.report_path}>
              <p >{summaryData?.report_path?.length >= 34 ? summaryData?.report_path.slice(34,) : summaryData?.report_path}</p>
            </Tooltip>
          </div>
          <Tooltip title="Delete" arrow >

            <IconButton
              onClick={() => {
                setData((prev) => ({
                  ...prev,
                  report_path: "",
                }));
                setSummaryData((prev) => ({
                  ...prev,
                  report_path: "",
                }));
                setFileDetails(null);
                setFileData(null);
                setFileInput(true);
                setFileInputRender(true);
                setTimeout(() => {
                  setFileInputRender(false);
                  setFileInput(false);
                }, 10);
              }}
            >
              <img src="/delete.svg" />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <div className={styles.inputElement}>
          {fileDetails && fileData ? (
            <div className={styles.uploadedFile}>
              <div className={styles.uploadedFileDetails}>
                {" "}
                <img src={"/file-pdf.svg"} />
                <Tooltip title={fileDetails && fileData?.name}>
                  <p>
                    {fileDetails && fileData?.name
                      ? fileDetails && fileData?.name?.length > 25
                        ? fileDetails && fileData?.name?.slice(0, 23) + "..."
                        : fileDetails && fileData?.name
                      : "Report.pdf"}
                  </p>
                </Tooltip>
              </div>
              <IconButton
                onClick={() => {
                  setSummaryData((prev) => ({
                    ...prev,
                    report_path: "",
                  }));
                  setData((prev) => ({
                    ...prev,
                    report_path: "",
                  }));
                  setFileDetails(null);
                  setFileData(null);
                  setFileInput(true);
                  setFileInputRender(true);
                  setTimeout(() => {
                    setFileInputRender(false);
                    setFileInput(false);
                  }, 10);
                }}
              >
                <img src="/delete.svg" />
              </IconButton>
            </div>
          ) : (
            <div>
              {!fileInputRender && !fileInput ? (
                <div>
                  <input
                    type="file"
                    hidden
                    onChange={handleChange}
                    accept="application/pdf"
                    id="myinput"
                  />
                  <label htmlFor="myinput" className={styles.upload}>
                    <div>
                      <img src={"/upload-icon.svg"} />
                      Upload here
                    </div>
                  </label>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      )}
      <p style={{ color: "red" }}>{errorMessages["report_path"]}</p>
      <Toaster richColors closeButton position="top-right" />
      <LoadingComponent loading={loading} />
    </div>
  );
};

export default Reports;
