import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import LoadingComponent from "./Core/LoadingComponent";
const Preview = () => {
  const AuthStateData = useSelector((state) => state.Signin);
  const { report_id } = useParams();
  const [showLoading, setShowLoading] = useState(false);
  const [reportData, setReportData] = useState("");

  useEffect(() => {
    fetchReport();
  }, []);

  const printReport = () => {
    window.frames["report"].focus();
    document.getElementById("report").contentWindow.print();
  };
  const fetchReport = async () => {
    setShowLoading(true);
    let url = `${process.env.REACT_APP_API_BASE_URL}/medicines/data/` +
      report_id
    let options = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        authorization: `${AuthStateData.access_token}`
      }),
    }
    let response = await fetch(url, options);
    var template = await response.text();
    setShowLoading(false);
    setReportData(template);
  };
  const DownloadFile = async (file) => {
    try {
      setShowLoading(true);
      let url = `${process.env.REACT_APP_API_BASE_URL}/medicines/download/` +
        report_id
      let options = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.access_token}`
        }),
      }
      let response = await fetch(url, options);
      let resJson = await response.json();
      window.open(resJson.data);
      setShowLoading(false);
    }
    catch (err) {
      console.error(err);
      setShowLoading(false);
    }
  }
  return (
    <div>
      <Button style={{ float: 'right' }} onClick={(file) => { DownloadFile() }} variant="contained" startIcon={<FileDownloadOutlinedIcon />}>Download
      </Button>
      <LoadingComponent loading={showLoading} />
      {/* <div style={{ float: "right", width: "8%", paddingBottom: "10px" }}>
        <Link to={`/report-preview/${report_id}/EditReport`}>
          <Button variant="outlined" color="error" size="small">
            <IconButton color="error" size="small">
              <EditIcon />
            </IconButton>
          </Button>
        </Link>
      </div> */}
      <iframe width="100%" id="report" height="1100" srcDoc={reportData}>
        {" "}
      </iframe>
    </div>
  );
};

export default Preview;
