import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import {
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { AlertTitle } from "@mui/material";
import BaseLayout from "../../layouts/BaseLayout";
// import "../Result/UploadFile.css";
import "../scss/UploadFile.scss";
import { useDispatch, useSelector } from "react-redux";

function UploadNutriGenomicsFile() {
  const AuthStateData = useSelector((state) => state.Signin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [successMessage, setSuccessMesssage] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setErrorAlert] = useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  };

  useEffect(() => {
    if (!AuthStateData.access_token) {
      navigate("/");
    }
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      var formdata = new FormData();
      formdata.append("file", file);
      formdata.append("category", "Nutrigenomics");
      var requestOptions = {
        method: "POST",
        headers: new Headers({
          authorization: `${AuthStateData.access_token}`,
        }),
        body: formdata,
      };
      let response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/nutrigenomics/result-files`,
        requestOptions
      );
      let responseData = await response.json();
      setSuccessMesssage(responseData.message);
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        // navigate("/result-file");
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate("/result-file");
        }, 1500);
      } else if (response.status === 422) {
        setErrorAlert(true);
        setLoading(false);
        setTimeout(() => {
          setErrorAlert(false);
        }, 2000);
      } else if (response.status === 400) {
        setErrorAlert(true);
        setLoading(false);
        setTimeout(() => {
          setErrorAlert(false);
        }, 2000);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handelChange = (e) => {
    setFile(e.target.files[0]);
    let filename = e.target.value.slice(12);
    setName(filename);
  };

  return (
    <>
      <div id="uploadFile">
        <div className="backButton">
          <IconButton disableElevation={true} onClick={() => {
            navigate("/result-file");
          }}>
            <ArrowBack /> Back
          </IconButton>
        </div>
        <Box className="upload-block">
          <Card className="upload-card">
            <h5>Upload Nutrigenomics File</h5>
            <div>
              <form
                className="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  className="input-file"
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  multiple={true}
                  onChange={handelChange}
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? "drag-active" : ""}
                >
                  <div className="uploadText">
                    <img src="/upload-file.png" alt="" />
                    <p>
                      <strong>Drag and drop</strong>
                    </p>
                    <span>your file here, or browse</span>
                  </div>
                </label>
                {dragActive && (
                  <div
                    className="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </form>
            </div>
            <Typography className="title">{name}</Typography>
            <div className="buttons">
              <Button
                className="cancel-btn"
                type="submit"
                variant="contained"
                style={{ marginTop: "2em" }}
                onClick={() => {
                  navigate("/result-file");
                }}
              >
                Cancel
              </Button>
              <Button
                className="submit-btn"
                onClick={handleUpdate}
                type="submit"
                variant="contained"
                style={{ marginTop: "2em" }}
              >
                Submit
              </Button>
            </div>
            <Snackbar
              open={showSuccessAlert}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                {successMessage}
              </Alert>
            </Snackbar>
            <Snackbar
              open={showErrorAlert}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {successMessage}
              </Alert>
            </Snackbar>
          </Card>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      </div>
    </>
  );
}
export default UploadNutriGenomicsFile;
