import { Backdrop } from "@mui/material";

const LoadingComponent = ({ loading }) => {
    return (
        <Backdrop
            style={{
                zIndex: 9999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(256,256,256,0.8)",
                flexDirection: "column",
            }}
            open={loading}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <object
                    type="image/svg+xml"
                    data={"/DNA Decoder Loading Animation.svg"}
                    width={150}
                    height={150}
                />
            </div>
        </Backdrop>
    );
}
export default LoadingComponent;